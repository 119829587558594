import React, {FC, MouseEvent, useCallback, useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import TripOriginIcon from '@mui/icons-material/TripOrigin';

import PinSvgIcon from 'assets/icons/buttons/PinSvgIcon';
import ProgressSvgIcon from 'assets/icons/buttons/ProgressSvgIcon';
import WarningSvgIcon from 'assets/icons/buttons/WarningSvgIcon';
import TasksCompletedSvgIcon from 'assets/icons/buttons/TasksCompletedSvgIcon';

import {FORM_INFORMATION} from 'appRedux/actions/forms';
import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {RequesterCaseTagTypes} from 'appRedux/actions/requestCase/types';
import {GET_REQUESTER_CASE_COMMENTS} from 'appRedux/actions/comments';
import {RootReducer} from 'appRedux/reducers';
import {GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';

import {AdminContext} from 'contexts/admin/context';
import CaseKeyContextWrapper from 'contexts/caseKey';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import CommentsBlock from 'components/AgentScreenComponents/_caseBlock/RequesterCaseDrawer/CommentsBlock';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import {getAvatarTitle} from 'components/TopBarMenu/helper';
import TagChip from 'components/TagChip/TagChip';
import HasNotApprovedStatusChangeRequestsButton from 'components/HasNotApprovedStatusChangeRequestsButton/HasNotApprovedStatusChangeRequestsButton';

import FormInformationRowWrapper from 'pages/agent/requesterPage/wrappers/FormInformationRowWrapper';
import {getRequesterCaseUserName, getAgentName} from 'pages/agent/listView/helper';

import {getFormKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {theme, ERROR_TEXT_COLOR} from 'config/theme';
import {routes, DATE_TIME_LABEL_FORMAT} from 'config/index';

interface RequesterCaseDrawerType {
    requesterCase: RequesterCaseResponseTypes;
}

const RequesterCaseDrawer: FC<RequesterCaseDrawerType> = ({requesterCase}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {
        isPinned,
        caseId,
        avatarType,
        avatarLink,
        formId,
        firstName,
        createdAt,
        updatedAt,
        deletedAt,
        formTitle,
        lastName,
        email,
        tags,
        statusTitle,
        statusUuid,
        hasNotApprovedStatusChangingRequest,
        hasRejectedStatusChangingRequest,
        hasNotCompletedTasks,
    } = requesterCase;

    const {setActiveRow} = useContext(AdminContext);

    const [showComments, setShowComments] = useState<boolean>(false);

    const toggleShowComments = () => {
        setShowComments(previous => !previous);
    };

    const {
        admin: {
            formInfo: {translations, statuses},
        },
        requestCase: {
            currentCase: {comments, statusColor, isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const commentsBySection = comments.filter(item => item.section === null);

    const commentsNumber = commentsBySection.length;

    const getRequesterCaseInfo = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormInformation = useCallback(
        data => dispatch({type: FORM_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseComments = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_COMMENTS.REQUEST, payload: data}),
        [dispatch],
    );

    const onDrawerClose = (e: MouseEvent) => {
        e.stopPropagation();
        setActiveRow(null);
    };

    const formTitleKeyword = getFormKeyword(Number(formId), 'title');
    const userName = getRequesterCaseUserName(t, requesterCase);

    const currentStatus = statuses ? statuses.find(item => item.uuid === statusUuid) : null;

    useEffect(() => {
        getFormInformation({id: Number(formId)});
        getRequesterCaseInfo({id: caseId});
        getRequesterCaseComments({id: caseId});
    }, [caseId]);

    return (
        <Box
            sx={{
                position: 'fixed',
                overflow: 'auto',
                top: 0,
                right: 0,
                width: showComments ? '50vw' : '22vw',
                height: '100vh',
                zIndex: 99999,
                backgroundColor: theme.palette.background.default,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                p: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pr: 2,
                }}
            >
                <IconButton onClick={onDrawerClose}>
                    <CloseIcon />
                </IconButton>
                {isPinned && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            mt: 1,
                        }}
                    >
                        <PinSvgIcon />
                        <Typography
                            sx={{
                                fontSize: 12,
                                ml: 1,
                                mb: 1,
                            }}
                        >
                            {t('orguser.requesterCase.pinned')}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Grid container>
                <Grid item sm={showComments ? 6 : 12}>
                    <Box sx={{p: 2}}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                mb: deletedAt ? 1 : 3,
                            }}
                        >
                            <AvatarImage
                                size={40}
                                avatarLink={avatarLink}
                                avatarType={avatarType}
                                avatarTitle={getAvatarTitle(firstName, lastName, email)}
                            />
                            <Tooltip
                                title={userName}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            fontSize: 16,
                                            backgroundColor: 'rgb(97, 97, 97)',
                                        },
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        ml: 2,
                                        fontSize: 18,
                                        fontWeight: 600,
                                        display: 'block',
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {userName}
                                </Typography>
                            </Tooltip>
                        </Box>
                        {deletedAt && (
                            <Typography sx={{mb: 3, color: ERROR_TEXT_COLOR}}>{`(${t(
                                'orguser.requesterCase.deleted',
                            )} - ${moment(deletedAt).format(DATE_TIME_LABEL_FORMAT)})`}</Typography>
                        )}
                        <Box sx={{mb: 3}}>
                            {tags.map((tagItem: RequesterCaseTagTypes, index: number) => {
                                const {id, tag, color} = tagItem;
                                return (
                                    <TagChip
                                        key={`tag-chip-${index}`}
                                        id={String(id)}
                                        title={tag}
                                        color={color}
                                        hasOpacity={true}
                                    />
                                );
                            })}
                        </Box>
                        <Box sx={{mb: 4}}>
                            <FormInformationRowWrapper title={t('orguser.requesterCase.agent')} isSelector>
                                <Typography sx={{fontWeight: 600}}>{getAgentName(t, requesterCase)}</Typography>
                            </FormInformationRowWrapper>
                            <FormInformationRowWrapper title={t('orguser.requesterCase.status')} isSelector alignTop>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                    }}
                                >
                                    <TripOriginIcon sx={{color: currentStatus ? currentStatus.color : statusColor}} />
                                    <Typography sx={{fontWeight: 600, ml: 1, mr: 1}}>
                                        {currentStatus ? currentStatus.title : statusTitle}
                                    </Typography>
                                    {(hasNotApprovedStatusChangingRequest || hasRejectedStatusChangingRequest) && (
                                        <HasNotApprovedStatusChangeRequestsButton
                                            title={t(
                                                hasRejectedStatusChangingRequest
                                                    ? 'orguser.requesterCase.hasRejectedRequests'
                                                    : 'orguser.requesterCase.hasNotApprovedStatusChangingRequest',
                                            )}
                                            icon={hasRejectedStatusChangingRequest ? WarningSvgIcon : ProgressSvgIcon}
                                        />
                                    )}
                                    {hasNotCompletedTasks && (
                                        <HasNotApprovedStatusChangeRequestsButton
                                            title={t('orguser.requesterCase.hasNotCompletedTasks')}
                                            icon={TasksCompletedSvgIcon}
                                        />
                                    )}
                                </Box>
                            </FormInformationRowWrapper>
                            <FormInformationRowWrapper title={t('orguser.requesterCase.form')} isSelector>
                                <Typography sx={{fontWeight: 600}}>
                                    {getFormTranslatedLabel(translations, formTitleKeyword, formTitle)}
                                </Typography>
                            </FormInformationRowWrapper>
                            <FormInformationRowWrapper title={t('orguser.requesterCase.created')} isSelector>
                                <Typography sx={{fontWeight: 600}}>
                                    {moment(createdAt).format(DATE_TIME_LABEL_FORMAT)}
                                </Typography>
                            </FormInformationRowWrapper>
                            <FormInformationRowWrapper title={t('orguser.requesterCase.lastUpdate')} isSelector>
                                <Typography sx={{fontWeight: 600}}>
                                    {moment(updatedAt).format(DATE_TIME_LABEL_FORMAT)}
                                </Typography>
                            </FormInformationRowWrapper>
                        </Box>
                        <Box>
                            <Link
                                to={`${routes.REQUESTER_CASE}/${caseId}/form/${formId}`}
                                style={{textDecoration: 'none'}}
                            >
                                <AgentSaveButton title={t('orguser.requesterCase.showCase')} isSubmit={false} />
                            </Link>
                            {commentsNumber > 0 && (
                                <AgentInverseButton
                                    title={t(
                                        showComments
                                            ? 'orguser.requesterCase.comments.hideComments'
                                            : 'orguser.requesterCase.comments.showComments',
                                    )}
                                    onClick={toggleShowComments}
                                />
                            )}
                        </Box>
                    </Box>
                </Grid>
                {isEncryptInfo ? (
                    <CaseKeyContextWrapper caseId={Number(caseId)} isRequesterCaseDrawer>
                        <CommentsBlock showComments={showComments} />
                    </CaseKeyContextWrapper>
                ) : (
                    <CommentsBlock showComments={showComments} />
                )}
            </Grid>
        </Box>
    );
};

export default RequesterCaseDrawer;
