import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';
import {ORGANIZATION_LIST} from 'appRedux/actions/organization';

import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';

import OrganizationTeam from 'components/OrganizationAdminsList/OrganizationTeam';

import {checkSubdomainCorrect} from 'helpers/security';

const OrgUsers: FC = () => {
    const dispatch = useDispatch();

    const {setPageTitle, setTopBarTitle, setBackLink, setBackTab, setBackLinkLabel} = useContext(RouteContext);
    const {isUserViewEnabled} = useContext(PermissionContext);

    const {
        admin: {organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getOrganizationList = useCallback(() => dispatch({type: ORGANIZATION_LIST.REQUEST}), [dispatch]);

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;
    const isSubdomainCorrect = checkSubdomainCorrect(myOrganization);

    useEffect(() => {
        setPageTitle('');
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, []);

    useEffect(() => {
        getOrganizationList();
    }, []);

    if (myOrganization && isSubdomainCorrect && isUserViewEnabled) {
        return <OrganizationTeam organizationId={myOrganization.id} />;
    }
    return null;
};

export default OrgUsers;
