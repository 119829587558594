import * as yup from 'yup';

export const initialDashboardValues = {
    title: '',
    description: '',
    dashboardHasPanels: [],
};

export const initialValues = {
    workflow: 0,
    version: null,
    title: '',
    description: '',
    type: 0,
    datefilterType: 0,
    displayType: 0,
    isIncludeCurrentStatus: false,
    dashboardPanelHasStatuses: [],
};

export const validationPanelSchemaPanelCreate = (t: (trans: string) => string) =>
    yup.object({
        title: yup.string().required(t('messages.validation.isRequired')),
    });

export const validationSchemaDashboardCreate = (t: (trans: string) => string) =>
    yup.object({
        title: yup.string().required(t('messages.validation.isRequired')),
    });
