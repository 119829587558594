import React, {FC, ReactNode} from 'react';

import Box from '@mui/material/Box';
import {SxProps} from '@mui/material';

interface HorizontalRightFlexboxType {
    children: ReactNode;
    sx?: SxProps;
}

const HorizontalRightFlexbox: FC<HorizontalRightFlexboxType> = ({children, sx}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export default HorizontalRightFlexbox;
