import React, {FC, useState, MouseEvent} from 'react';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

import InfoSolidSvgIcon from 'assets/icons/buttons/InfoSolidSvgIcon';

interface DescriptionPopoverType {
    text: string;
}

const DescriptionPopover: FC<DescriptionPopoverType> = ({text}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton onClick={handleClick}>
                <InfoSolidSvgIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box
                    sx={{
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        borderRadius: 2,
                        maxWidth: 600,
                        '& p': {
                            mt: 0,
                            mb: 1,
                            fontSize: 12,
                        },
                    }}
                >
                    <Markdown>{text}</Markdown>
                </Box>
            </Popover>
        </>
    );
};

export default DescriptionPopover;
