import React, {FC, ChangeEvent, useContext, useRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import {TEXTAREA_DEFAULT_LENGTH} from 'pages/admin/updateForm/partials/FormStructure/helper';
import TextAreaProgressCircle from 'pages/client/form/partials/TextAreaProgressCircle';
import FormFieldWrapper from 'pages/client/form/wrappers/FormFieldWrapper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getInitialFieldValue, isSaveUnavailable, getErrorMessage} from 'pages/client/form/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

const FormTextField: FC<FormFieldPropsType> = ({
    formId,
    pageId,
    sectionId,
    field,
    clientInformation,
    onPopupFieldChange,
    handleChangeInformation,
    isTextArea,
    isPopup,
    popupContent,
    previewMode,
    disabled,
    onSaveClicked,
}) => {
    const [t] = useTranslation();

    const {id, isRequired, label, tooltip, minLength} = field;

    const ref = useRef<HTMLDivElement | undefined>();

    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [isError, setIsError] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [leftChars, setLeftChars] = useState<number>(minLength ?? TEXTAREA_DEFAULT_LENGTH);

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleChange = (e: ChangeEvent<{value: unknown}>) => {
        const value = String(e.target.value);
        setIsError(false);
        setErrorField && setErrorField(null);
        if (handleChangeInformation) {
            handleChangeInformation(pageId, sectionId, id, value);
        }
        if (isTextArea && minLength && minLength > 0) {
            setProgress((100 * value.length) / minLength);
            setLeftChars(minLength - value.length);
        }
    };

    const handlePopupFieldChange = (e: ChangeEvent<{value: unknown}>) => {
        const value = String(e.target.value);
        setIsError(false);
        setErrorField && setErrorField(null);
        if (onPopupFieldChange) {
            onPopupFieldChange(id, value);
        }
        if (isTextArea && minLength && minLength > 0) {
            setProgress((100 * value.length) / minLength);
            setLeftChars(minLength - value.length);
        }
    };

    const validateField = (e: ChangeEvent<{value: unknown}>) => {
        const value = String(e.target.value);
        if (isSaveUnavailable(field, value)) {
            setIsError(true);
        } else {
            onSaveClicked();
        }
    };

    useEffect(() => {
        if (id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    const keywordLabel = getFieldKeyword(formId, id, 'label');
    const keywordTooltip = getFieldKeyword(formId, id, 'tooltip');

    const value = getInitialFieldValue(pageId, sectionId, id, clientInformation, isPopup, popupContent);

    return (
        <FormFieldWrapper
            data-id={`input#form-textfield-${formId}-${pageId}-${sectionId}-${id}`}
            wrapperRef={ref}
            formId={formId}
            field={field}
            isPopup={isPopup}
            previewMode={previewMode}
        >
            <Box sx={{position: 'relative'}}>
                <TextField
                    fullWidth
                    variant="standard"
                    disabled={disabled}
                    error={isError || id === errorField}
                    helperText={isError || id === errorField ? getErrorMessage(field, t, value) : null}
                    label={previewMode ? label : getFormTranslatedLabel(translations, keywordLabel, label)}
                    placeholder={previewMode ? tooltip : getFormTranslatedLabel(translations, keywordTooltip, tooltip)}
                    required={isRequired}
                    value={value}
                    onChange={isPopup ? handlePopupFieldChange : handleChange}
                    multiline={isTextArea}
                    rows={isTextArea ? 5 : 1}
                    onBlur={validateField}
                />
                {isTextArea && minLength && leftChars > 0 && (
                    <TextAreaProgressCircle leftChars={leftChars} progress={progress} />
                )}
            </Box>
        </FormFieldWrapper>
    );
};

export default FormTextField;
