import {fork, put, take, call} from 'redux-saga/effects';
import get from 'lodash/get';
import {FormikErrors} from 'formik';

import {http} from 'services/http';
import {setToken, setRefreshToken} from 'services/localStorage';

import {
    HELP_START,
    helpStart,
    CREATE_ANONYMOUS_REQUESTER_CASE,
    createAnonymousRequesterCase,
    LOGIN,
    login,
    FORGET_NICKNAME,
    forgetNickname,
    RESET_PASSWORD,
    resetPassword,
    LOGOUT,
    logoutAction,
    GET_USER_SESSIONS,
    getUserSessions,
    REMOVE_USER_SESSION,
    removeUserSession,
    GET_USER_LOGIN_ATTEMPTS,
    getUserLoginAttempts,
    FORM_BASE_INFORMATION,
    getFormBaseInformation,
    FORM_BASE_INVITE_INFORMATION,
    getFormBaseInviteInformation,
    ORGANIZATION_BASE_INFORMATION_LANGUAGE,
    getOrganizationBaseInformationByLanguage,
    ORGANIZATION_BASE_INFORMATION,
    getOrganizationBaseInformation,
    TWO_FACTOR_CONFIRMATION,
    twoFactorConfirmation,
    TWO_FACTOR_ACTIVATION,
    twoFactorActivation,
    TWO_FACTOR_DEACTIVATE,
    twoFactorDeactivation,
    TWO_FACTOR_CANCEL,
    twoFactorCancel,
    TWO_FACTOR_REGENERATION,
    twoFactorRegeneration,
    TWO_FACTOR_CODES_DOWNLOAD,
    twoFactorCodesDownload,
    twoFactorFirstStepPassed,
    REFRESH_USER_TOKEN,
    refreshUserToken,
    RECOVERY_PASSWORD,
    recoveryPassword,
    LOGIN_REDIRECT,
    loginRedirect,
    GET_REDIRECT_URL,
    getRedirectUrl,
    OPEN_ID_LOGIN,
    openIdLogin,
    OPEN_ID_REGISTRATION,
    openIdRegistration,
    OPEN_ID_AUTH,
    openIdAuth,
} from 'appRedux/actions/auth';
import {getUserInfo} from 'appRedux/actions/profile';
import {
    HelpStartRequestFormTypes,
    LoginRequestTypes,
    LoginResponseTypes,
    LoginRedirectRequestTypes,
    LogoutRequestTypes,
    LogoutResponseTypes,
    RegistrationResponseTypes,
    ForgetNicknameRequestTypes,
    ResetPasswordRequestTypes,
    FormBaseInformationRequestTypes,
    OrganizationBaseInformationRequestTypes,
    OrganizationBaseInformationTypes,
    TwoFactorConfirmationTypes,
    RefreshTokenRequestTypes,
    RefreshTokenResponseTypes,
    TwoFactorCodesRegenerationTypes,
    TwoFactorActivationResponseTypes,
    TwoFactorCodeDownloadRequestTypes,
    TwoFactorCancelRequestTypes,
    RecoveryPasswordRequestTypes,
    RecoveryPasswordResponseType,
    UserSessionResponseTypes,
    FormBaseInformationResponseTypes,
    FormBaseInformationInviteRequestTypes,
    UserLoginAttemptsResponseTypes,
    UserSessionsListRequestTypes,
    UserSessionRemoveRequestTypes,
    GetRedirectRequestTypes,
    GetRedirectResponseTypes,
    OpenIdAuthTypes,
    LoginSsoRequestTypes,
    LoginSsoResponseTypes,
    RequesterCaseAnonymousCreateRequestTypes,
    RegistrationSsoRequestTypes,
    RegistrationSsoResponseTypes,
} from 'appRedux/actions/auth/types';
import {MainProfileResponseTypes} from 'appRedux/actions/profile/types';

import {
    ALERT_TYPE_ERROR,
    ALERT_TYPE_SUCCESS,
    ALERT_SUCCESS_MESSAGE_2FA_REGENERATED,
    ALERT_SUCCESS_MESSAGE_2FA_ACTIVATED,
    ALERT_SUCCESS_MESSAGE_2FA_DEACTIVATED,
    TWO_FACTOR_CONFIRMATION_URL,
} from 'config/index';

function* watchStartHelpRequest() {
    while (true) {
        const {
            payload: {nickname, email, form, invite, lang, password, callbackRegistration, setErrors, showAlert},
        }: IActionType<HelpStartRequestFormTypes> = yield take(HELP_START.REQUEST);
        try {
            const {token, refreshToken, ...restData}: RegistrationResponseTypes = yield call(http, 'user/email', {
                method: 'POST',
                body: JSON.stringify({nickname, email, form, invite, lang, password}),
            });

            if (token) {
                yield setToken(token);
                yield setRefreshToken(refreshToken);
                yield put(helpStart.success({token, refreshToken}));
                yield put(getUserInfo.success(restData));
                const userId = get(restData, 'id', null);
                const redirectToCase = get(restData, 'redirectToCase', null);
                const redirectToForm = get(restData, 'redirectToForm', null);
                if (callbackRegistration && userId && redirectToForm && redirectToCase) {
                    callbackRegistration(userId, redirectToCase, redirectToForm);
                }
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (restData.errors) {
                yield put(helpStart.error(restData.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, restData.errors);
                setErrors && setErrors(restData.errors);
            }
        } catch (e) {
            yield put(helpStart.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCreateAnonymousRequestCase() {
    while (true) {
        const {
            payload: {formId, callbackRegistration, showAlert},
        }: IActionType<RequesterCaseAnonymousCreateRequestTypes> = yield take(CREATE_ANONYMOUS_REQUESTER_CASE.REQUEST);
        try {
            const {token, refreshToken, ...restData}: RegistrationResponseTypes = yield call(http, `case/anonymous`, {
                method: 'POST',
                body: JSON.stringify({
                    form: formId,
                }),
            });
            if (token) {
                yield setToken(token);
                yield setRefreshToken(refreshToken);
                yield put(createAnonymousRequesterCase.success({token, refreshToken}));
                yield put(getUserInfo.success(restData));
                const userId = get(restData, 'id', null);
                const redirectToCase = get(restData, 'redirectToCase', null);
                const redirectToForm = get(restData, 'redirectToForm', null);
                if (callbackRegistration && userId && redirectToForm && redirectToCase) {
                    callbackRegistration(userId, redirectToCase, redirectToForm);
                }
            } else {
                yield put(createAnonymousRequesterCase.error({message: 'messages.error.somethingWentWrong'}));
                showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createAnonymousRequesterCase.error({message: String(e)}));
            showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchLoginRequest() {
    while (true) {
        const {
            payload: {
                username,
                form,
                invite,
                password,
                showAlert,
                callback,
                callbackTwoFactor,
                callbackRedirect,
                callbackSetRedirect,
            },
        }: IActionType<LoginRequestTypes> = yield take(LOGIN.REQUEST);
        try {
            const {token, refreshToken, ...restData}: LoginResponseTypes = yield call(http, 'login_check', {
                method: 'POST',
                body: JSON.stringify({username, form, invite, password}),
            });
            const caseId = get(restData, 'redirectToCase', null);
            const formId = get(restData, 'redirectToForm', null);
            const useHasDraft = get(restData, 'useHasDraft', false);
            const formUrl = get(restData, 'formUrl', null);

            if (((caseId && formId) || (useHasDraft && formUrl)) && callbackSetRedirect)
                callbackSetRedirect(caseId, formId, useHasDraft, formUrl);

            if (restData.redirectTo) {
                callbackRedirect && callbackRedirect(restData.redirectTo);
            } else if (restData.isTwoFactorActivated && token) {
                yield setToken(token);
                yield put(twoFactorFirstStepPassed.success());
                callbackTwoFactor && callbackTwoFactor();
            } else if (token) {
                yield setToken(token);
                yield setRefreshToken(refreshToken);
                yield put(login.success({token, refreshToken}));
                callback && callback();
            } else {
                yield put(login.error({message: restData.message || 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, restData.message);
            }
        } catch (e) {
            yield put(login.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchLoginRedirectRequest() {
    while (true) {
        const {
            payload: {showAlert, callback, callbackTwoFactor, ...values},
        }: IActionType<LoginRedirectRequestTypes> = yield take(LOGIN_REDIRECT.REQUEST);
        try {
            const {token, refreshToken, ...restData}: LoginResponseTypes = yield call(http, 'redirect/auth', {
                method: 'POST',
                body: JSON.stringify({...values}),
            });
            if (restData.isTwoFactorActivated && token) {
                yield setToken(token);
                yield put(twoFactorFirstStepPassed.success());
                callbackTwoFactor && callbackTwoFactor(restData.email);
            } else if (token) {
                yield setToken(token);
                yield setRefreshToken(refreshToken);
                yield put(loginRedirect.success({token, refreshToken}));
                callback && callback();
            } else {
                yield put(loginRedirect.error({message: restData.message || 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, restData.message);
            }
        } catch (e) {
            yield put(loginRedirect.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCorrectRedirectRequest() {
    while (true) {
        const {
            payload: {showAlert, id, callback},
        }: IActionType<GetRedirectRequestTypes> = yield take(GET_REDIRECT_URL.REQUEST);
        try {
            const data: GetRedirectResponseTypes = yield call(http, `organization/${id}/redirect`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getRedirectUrl.success());
                callback && callback(data.redirectTo);
            } else {
                yield put(getRedirectUrl.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getRedirectUrl.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchLogoutRequest() {
    while (true) {
        const {
            payload: {refreshToken, callback},
        }: IActionType<LogoutRequestTypes> = yield take(LOGOUT.REQUEST);
        try {
            const data: LogoutResponseTypes = yield call(http, 'logout', {
                method: 'POST',
                body: JSON.stringify({
                    refresh_token: refreshToken,
                }),
            });
            if (data.code === 200) {
                yield put(logoutAction.success());
            } else {
                yield put(logoutAction.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(logoutAction.error({message: String(e)}));
        }
        callback && callback();
    }
}

function* watchUserSessionsGetting() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<UserSessionsListRequestTypes> = yield take(GET_USER_SESSIONS.REQUEST);
        try {
            const data: UserSessionResponseTypes = yield call(http, 'user/sessions', {
                method: 'GET',
            });
            if (data.success) {
                yield put(getUserSessions.success(data.results));
            } else {
                showAlert && showAlert(ALERT_TYPE_ERROR);
                yield put(getUserSessions.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getUserSessions.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserLoginAttemptsGetting() {
    while (true) {
        yield take(GET_USER_LOGIN_ATTEMPTS.REQUEST);
        try {
            const data: UserLoginAttemptsResponseTypes = yield call(http, 'attempts/mylogin', {
                method: 'GET',
            });
            if (data.success) {
                yield put(getUserLoginAttempts.success(data.results));
            } else {
                yield put(getUserLoginAttempts.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getUserLoginAttempts.error({message: String(e)}));
        }
    }
}

function* watchUserSessionRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<UserSessionRemoveRequestTypes> = yield take(REMOVE_USER_SESSION.REQUEST);
        try {
            const data: UserSessionResponseTypes = yield call(http, `session/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(removeUserSession.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                showAlert && showAlert(ALERT_TYPE_ERROR);
                yield put(removeUserSession.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(removeUserSession.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchTwoFactorConfirmationRequest() {
    while (true) {
        const {
            payload: {callback, showAlert, ...values},
        }: IActionType<TwoFactorConfirmationTypes> = yield take(TWO_FACTOR_CONFIRMATION.REQUEST);
        try {
            const {token, refreshToken, errors}: LoginResponseTypes = yield call(http, TWO_FACTOR_CONFIRMATION_URL, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (token) {
                yield setToken(token);
                yield setRefreshToken(refreshToken);
                yield put(
                    twoFactorConfirmation.success({
                        token,
                        refreshToken,
                        isTwoFactorCompleted: true,
                    }),
                );
                callback && callback();
            } else {
                showAlert && showAlert(ALERT_TYPE_ERROR, errors);
                yield put(twoFactorConfirmation.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(twoFactorConfirmation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchTwoFactorRegenerationRequest() {
    while (true) {
        const {
            payload: {callback, showAlert, ...values},
        }: IActionType<TwoFactorCodesRegenerationTypes> = yield take(TWO_FACTOR_REGENERATION.REQUEST);
        try {
            const data: TwoFactorActivationResponseTypes = yield call(http, 'user/two-factor-regenerate', {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(
                    twoFactorRegeneration.success({
                        isTwoFactorCompleted: true,
                    }),
                );
                yield put(
                    getUserInfo.activate({
                        isTwoFactorActivated: true,
                    }),
                );
                callback && callback(data.recoveryCodes);
                showAlert && showAlert(ALERT_TYPE_SUCCESS, ALERT_SUCCESS_MESSAGE_2FA_REGENERATED);
            } else if (data.errors) {
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
                yield put(twoFactorRegeneration.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(twoFactorRegeneration.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchTwoFactorActivationRequest() {
    while (true) {
        const {
            payload: {callback, showAlert, ...values},
        }: IActionType<TwoFactorConfirmationTypes> = yield take(TWO_FACTOR_ACTIVATION.REQUEST);
        try {
            const data: TwoFactorActivationResponseTypes = yield call(http, 'user/two-factor-activate', {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(
                    twoFactorActivation.success({
                        isTwoFactorCompleted: true,
                    }),
                );
                yield put(
                    getUserInfo.activate({
                        isTwoFactorActivated: true,
                    }),
                );
                callback && callback(data.recoveryCodes);
                showAlert && showAlert(ALERT_TYPE_SUCCESS, ALERT_SUCCESS_MESSAGE_2FA_ACTIVATED);
            } else if (data.errors) {
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
                yield put(twoFactorActivation.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(twoFactorActivation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchTwoFactorDeactivationRequest() {
    while (true) {
        const {
            payload: {callback, showAlert, ...values},
        }: IActionType<TwoFactorConfirmationTypes> = yield take(TWO_FACTOR_DEACTIVATE.REQUEST);
        try {
            const data: TwoFactorActivationResponseTypes = yield call(http, 'user/two-factor-deactivation', {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(
                    twoFactorDeactivation.success({
                        isTwoFactorCompleted: false,
                    }),
                );
                yield put(
                    getUserInfo.activate({
                        isTwoFactorActivated: false,
                        isGoogleAuthCodeGenerated: false,
                    }),
                );
                showAlert && showAlert(ALERT_TYPE_SUCCESS, ALERT_SUCCESS_MESSAGE_2FA_DEACTIVATED);
            } else if (data.errors) {
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
                yield put(twoFactorActivation.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(twoFactorActivation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchTwoFactorCancellingRequest() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<TwoFactorCancelRequestTypes> = yield take(TWO_FACTOR_CANCEL.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, 'user/two-factor-cancel', {
                method: 'POST',
            });
            if (data.id) {
                yield put(
                    twoFactorCancel.success({
                        isTwoFactorCompleted: false,
                    }),
                );
                yield put(
                    getUserInfo.activate({
                        isTwoFactorActivated: false,
                        isGoogleAuthCodeGenerated: false,
                        secretCodeExpiredTime: null,
                    }),
                );
                showAlert && showAlert(ALERT_TYPE_SUCCESS, 'messages.error.expiredQrCode');
            } else if (data.errors) {
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
                yield put(twoFactorCancel.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(twoFactorCancel.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchTwoFactorCodesDownload() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<TwoFactorCodeDownloadRequestTypes> = yield take(TWO_FACTOR_CODES_DOWNLOAD.REQUEST);
        try {
            const data: string | Blob = yield call(http, 'user/two-factor-download', {
                method: 'GET',
                responseType: 'blob',
            });
            if (data) {
                yield put(twoFactorCodesDownload.success(data));
                callback && callback(data);
            } else {
                yield put(twoFactorCodesDownload.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(twoFactorRegeneration.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchForgetNicknameRequest() {
    while (true) {
        const {
            payload: {email, showAlert},
        }: IActionType<ForgetNicknameRequestTypes> = yield take(FORGET_NICKNAME.REQUEST);
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const data = yield call(http, 'forget-nickname', {
                method: 'POST',
                body: JSON.stringify({email}),
            });
            if (data.success) {
                yield put(forgetNickname.success());
                showAlert && showAlert(ALERT_TYPE_SUCCESS, 'messages.success.nicknameForgetSuccessMessage');
            }
            if (data.errors) {
                yield put(forgetNickname.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(forgetNickname.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResetPasswordRequest() {
    while (true) {
        const {
            payload: {nickname, showAlert},
        }: IActionType<ResetPasswordRequestTypes> = yield take(RESET_PASSWORD.REQUEST);
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const data = yield call(http, 'reset-password', {
                method: 'POST',
                body: JSON.stringify({nickname}),
            });
            if (data.success) {
                yield put(resetPassword.success());
                showAlert && showAlert(ALERT_TYPE_SUCCESS, 'messages.success.passwordResetSuccessMessage');
            }
            if (data.errors) {
                yield put(resetPassword.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(resetPassword.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormBaseInformationRequest() {
    while (true) {
        const {
            payload: {url, lang},
        }: IActionType<FormBaseInformationRequestTypes> = yield take(FORM_BASE_INFORMATION.REQUEST);
        try {
            const data: FormBaseInformationResponseTypes = yield call(http, `form/base`, {
                method: 'POST',
                body: JSON.stringify({
                    url,
                    lang,
                }),
            });
            if (data.form) {
                yield put(getFormBaseInformation.success(data.form));
            } else {
                yield put(getFormBaseInformation.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getFormBaseInformation.error({message: String(e)}));
        }
    }
}

function* watchFormBaseInviteInformationRequest() {
    while (true) {
        const {
            payload: {invite, lang, showAlert},
        }: IActionType<FormBaseInformationInviteRequestTypes> = yield take(FORM_BASE_INVITE_INFORMATION.REQUEST);
        try {
            const data: FormBaseInformationResponseTypes = yield call(http, `form/invite`, {
                method: 'POST',
                body: JSON.stringify({
                    invite,
                    lang,
                }),
            });
            if (data.form) {
                yield put(getFormBaseInviteInformation.success(data.form));
            } else if (data.errors) {
                yield put(getFormBaseInviteInformation.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getFormBaseInviteInformation.error({message: String(e)}));
        }
    }
}

function* watchOrganizationBaseInformationByLanguageRequest() {
    while (true) {
        const {
            payload: {language, showAlert, callback},
        }: IActionType<OrganizationBaseInformationRequestTypes> = yield take(
            ORGANIZATION_BASE_INFORMATION_LANGUAGE.REQUEST,
        );
        try {
            const data: OrganizationBaseInformationTypes = yield call(http, `organization/info/${language}`, {
                method: 'GET',
            });
            if (data) {
                yield put(getOrganizationBaseInformationByLanguage.success(get(data, 'organization', null)));
                callback && callback();
            } else {
                yield put(
                    getOrganizationBaseInformationByLanguage.error({message: 'messages.error.somethingWentWrong'}),
                );
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getOrganizationBaseInformationByLanguage.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationBaseInformationRequest() {
    while (true) {
        yield take(ORGANIZATION_BASE_INFORMATION.REQUEST);
        try {
            const data: OrganizationBaseInformationTypes = yield call(http, `organization/domain`, {
                method: 'GET',
            });
            if (data) {
                yield put(getOrganizationBaseInformation.success(get(data, 'organization', null)));
            } else {
                yield put(getOrganizationBaseInformation.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getOrganizationBaseInformation.error({message: String(e)}));
        }
    }
}

function* watchTokenRefreshing() {
    while (true) {
        const {
            payload: {refreshToken, showAlert, callback},
        }: IActionType<RefreshTokenRequestTypes> = yield take(REFRESH_USER_TOKEN.REQUEST);
        try {
            const data: RefreshTokenResponseTypes = yield call(http, `token/refresh`, {
                method: 'POST',
                body: JSON.stringify({
                    refresh_token: refreshToken,
                }),
            });
            if (data.token && data.refresh_token) {
                yield setToken(data.token);
                yield setRefreshToken(data.refresh_token);
                yield put(refreshUserToken.success());
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS, 'messages.success.tokenRefreshSuccessMessage');
            } else {
                yield put(refreshUserToken.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(refreshUserToken.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchPasswordRecovering() {
    while (true) {
        const {
            payload: {showAlert, callback, setErrors, ...values},
        }: IActionType<RecoveryPasswordRequestTypes> = yield take(RECOVERY_PASSWORD.REQUEST);
        try {
            const data: RecoveryPasswordResponseType = yield call(http, `password-recovery`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(recoveryPassword.success());
                showAlert && showAlert(ALERT_TYPE_SUCCESS, 'messages.success.passwordUpdatedSuccessfully');
                callback && callback();
            } else if (data.errors) {
                yield put(recoveryPassword.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
                const errors: FormikErrors<RecoveryPasswordRequestTypes> = get(data, 'errors', {});
                setErrors && setErrors(errors);
            }
        } catch (e) {
            yield put(recoveryPassword.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOpenIdLogin() {
    while (true) {
        const {
            payload: {showAlert, callback, ...values},
        }: IActionType<LoginSsoRequestTypes> = yield take(OPEN_ID_LOGIN.REQUEST);
        try {
            const {isSuccess, redirectTo}: LoginSsoResponseTypes = yield call(http, 'openid/login', {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (isSuccess) {
                yield put(openIdLogin.success());
                callback && callback(redirectTo);
            } else {
                yield put(openIdLogin.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(openIdLogin.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOpenIdRegistration() {
    while (true) {
        const {
            payload: {showAlert, callbackRegistration, ...values},
        }: IActionType<RegistrationSsoRequestTypes> = yield take(OPEN_ID_REGISTRATION.REQUEST);
        try {
            const {token, refreshToken, ...restData}: RegistrationSsoResponseTypes = yield call(
                http,
                'openid/registration',
                {
                    method: 'POST',
                    body: JSON.stringify(values),
                },
            );
            if (token) {
                yield setToken(token);
                yield setRefreshToken(refreshToken);
                yield put(openIdRegistration.success({token, refreshToken}));
                const redirectToCase = get(restData, 'redirectToCase', null);
                const redirectToForm = get(restData, 'redirectToForm', null);
                if (callbackRegistration && redirectToForm && redirectToCase) {
                    callbackRegistration(redirectToCase, redirectToForm);
                }
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(openIdRegistration.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(openIdRegistration.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOpenIdAuthorization() {
    while (true) {
        const {
            payload: {showAlert, callback, callbackTwoFactor, ...values},
        }: IActionType<OpenIdAuthTypes> = yield take(OPEN_ID_AUTH.REQUEST);
        try {
            const {token, refreshToken, ...restData}: LoginResponseTypes = yield call(http, 'openid/auth', {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (restData.isTwoFactorActivated && token) {
                yield setToken(token);
                yield put(twoFactorFirstStepPassed.success());
                callbackTwoFactor && restData.email && callbackTwoFactor(restData.email);
            } else if (token) {
                yield setToken(token);
                yield setRefreshToken(refreshToken);
                yield put(openIdAuth.success({token, refreshToken}));
                callback && callback();
            } else {
                yield put(openIdAuth.error({message: restData.errors || 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, restData.errors);
            }
        } catch (e) {
            yield put(openIdAuth.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchStartHelpRequest),
    fork(watchCreateAnonymousRequestCase),
    fork(watchLoginRequest),
    fork(watchLoginRedirectRequest),
    fork(watchCorrectRedirectRequest),
    fork(watchLogoutRequest),
    fork(watchUserSessionsGetting),
    fork(watchUserSessionRemoving),
    fork(watchUserLoginAttemptsGetting),
    fork(watchTwoFactorConfirmationRequest),
    fork(watchTwoFactorRegenerationRequest),
    fork(watchTwoFactorActivationRequest),
    fork(watchTwoFactorDeactivationRequest),
    fork(watchTwoFactorCancellingRequest),
    fork(watchTwoFactorCodesDownload),
    fork(watchForgetNicknameRequest),
    fork(watchResetPasswordRequest),
    fork(watchFormBaseInformationRequest),
    fork(watchFormBaseInviteInformationRequest),
    fork(watchOrganizationBaseInformationByLanguageRequest),
    fork(watchOrganizationBaseInformationRequest),
    fork(watchTokenRefreshing),
    fork(watchPasswordRecovering),
    fork(watchOpenIdLogin),
    fork(watchOpenIdAuthorization),
    fork(watchOpenIdRegistration),
];
