import React, {FC, useContext, useRef, useEffect, useState, SyntheticEvent} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import {FormHelperText, Rating, Typography} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import FormFieldWrapper from 'pages/client/form/wrappers/FormFieldWrapper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getInitialFieldValue} from 'pages/client/form/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {ERROR_TEXT_COLOR, theme} from 'config/theme';

const FormRating: FC<FormFieldPropsType> = ({
    formId,
    pageId,
    sectionId,
    field,
    clientInformation,
    onPopupFieldChange,
    handleChangeInformation,
    isPopup,
    popupContent,
    previewMode,
    disabled,
    onSaveClicked,
}) => {
    const {id, label} = field;

    const ref = useRef<HTMLDivElement | undefined>();

    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [value, setValue] = useState<number>(
        getInitialFieldValue(pageId, sectionId, id, clientInformation, isPopup, popupContent),
    );

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleChange = (e: SyntheticEvent<Element, Event>, value: number | null) => {
        if (!value) return;

        setValue(value);
        setIsError(false);
        setErrorField && setErrorField(null);
        if (handleChangeInformation) {
            handleChangeInformation(pageId, sectionId, id, String(value));
            setIsInitialRender(false);
            setIsUpdated(previous => !previous);
        }
    };

    const handlePopupFieldChange = (e: SyntheticEvent<Element, Event>, value: number | null) => {
        if (!value) return;

        setValue(value);
        setIsError(false);
        setErrorField && setErrorField(null);
        if (onPopupFieldChange) {
            onPopupFieldChange(id, String(value));
            setIsInitialRender(false);
            setIsUpdated(previous => !previous);
        }
    };

    useEffect(() => {
        if (id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    const keywordLabel = getFieldKeyword(formId, id, 'label');

    useEffect(() => {
        if (!isInitialRender) {
            onSaveClicked();
        }
    }, [isUpdated]);

    return (
        <FormFieldWrapper
            data-id={`input#form-textfield-${formId}-${pageId}-${sectionId}-${id}`}
            wrapperRef={ref}
            formId={formId}
            field={field}
            isPopup={isPopup}
            previewMode={previewMode}
        >
            <Box sx={{position: 'relative'}}>
                <Typography
                    sx={{
                        color: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                        fontSize: 12,
                        mb: 1,
                    }}
                >
                    {`${previewMode ? label : getFormTranslatedLabel(translations, keywordLabel, label)} ${
                        field.isRequired ? '*' : ''
                    }`}
                </Typography>
                <Rating value={value} onChange={isPopup ? handlePopupFieldChange : handleChange} disabled={disabled} />
                {isError && <FormHelperText>{isError}</FormHelperText>}
            </Box>
        </FormFieldWrapper>
    );
};

export default FormRating;
