import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import AgentAddMultipleButton from 'components/AgentScreenComponents/_buttons/AgentAddMultipleButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {theme} from 'config/theme';
import {InviteFormsTypes} from 'appRedux/actions/invites/types';
import InviteForm from 'components/Forms/Invites/InviteForm/InviteForm';
import SendInviteForm from 'components/Forms/Invites/SendInviteForm/SendInviteForm';

interface AddInviteType {
    forms: InviteFormsTypes[];
}

const AddInvite: FC<AddInviteType> = ({forms}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const [showNewModal, setShowNewModal] = useState<boolean>(false);

    const toggleNewModal = () => {
        setShowNewModal(previous => !previous);
    };

    const [showEmailModal, setShowEmailModal] = useState<boolean>(false);

    const toggleEmailModal = () => {
        setShowEmailModal(previous => !previous);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentAddMultipleButton title={t('orguser.invites.addInvite')} onClick={handleClick} open={open} />
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        minWidth: 250,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <MenuItem onClick={toggleNewModal}>
                    <Typography>{t('orguser.invites.createInvite')}</Typography>
                </MenuItem>
                <MenuItem onClick={toggleEmailModal}>
                    <Typography>{t('orguser.invites.inviteByEmail')}</Typography>
                </MenuItem>
            </Menu>
            <ModalWrapper
                isShowModal={showNewModal}
                toggleModal={toggleNewModal}
                title={t('orguser.invites.createInvite')}
            >
                <InviteForm forms={forms} setIsOpened={setShowNewModal} />
            </ModalWrapper>
            <ModalWrapper
                isShowModal={showEmailModal}
                toggleModal={toggleEmailModal}
                title={t('orguser.invites.inviteByEmail')}
            >
                <SendInviteForm forms={forms} setIsOpened={setShowEmailModal} />
            </ModalWrapper>
        </Box>
    );
};

export default AddInvite;
