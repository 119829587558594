import {DEFAULT_NONE} from 'config/index';

export const TYPE_CASE_STATUS_DISTRIBUTION = 0;
export const TYPE_CASE_CHECKBOX_ANALYSIS = 1;
export const TYPE_CASE_STATUS_TIME_ANALYSIS = 2;
export const TYPE_CASE_TAG_STATUS = 3;

export const DATEFIELD_TYPE_ALL_TIME = 0;
export const DATEFIELD_TYPE_LAST_WEEK = 1;
export const DATEFIELD_TYPE_LAST_MONTH = 2;
export const DATEFIELD_TYPE_LAST_SIX_MONTHS = 3;
export const DATEFIELD_TYPE_LAST_YEAR = 4;

export const DISPLAY_TYPE_BAR_CHART = 0;
export const DISPLAY_TYPE_CIRCLE_CHART = 1;

export interface PanelOptionType {
    name: number;
    label: string;
}

export const panelTypesArray: PanelOptionType[] = [
    {name: TYPE_CASE_STATUS_DISTRIBUTION, label: 'orguser.analytics.panels.types.caseStatusDistribution'},
    // {name: TYPE_CASE_CHECKBOX_ANALYSIS, label: 'orguser.analytics.panels.types.caseCheckboxAnalysis'},
    {name: TYPE_CASE_STATUS_TIME_ANALYSIS, label: 'orguser.analytics.panels.types.caseStatusTimeAnalysis'},
    // {name: TYPE_CASE_TAG_STATUS, label: 'orguser.analytics.panels.types.caseTagStatus'},
];

export const panelDatefilterTypeArray: PanelOptionType[] = [
    {name: DATEFIELD_TYPE_ALL_TIME, label: 'orguser.analytics.panels.datefilterTypes.allTime'},
    {name: DATEFIELD_TYPE_LAST_WEEK, label: 'orguser.analytics.panels.datefilterTypes.lastWeek'},
    {name: DATEFIELD_TYPE_LAST_MONTH, label: 'orguser.analytics.panels.datefilterTypes.lastMonth'},
    {name: DATEFIELD_TYPE_LAST_SIX_MONTHS, label: 'orguser.analytics.panels.datefilterTypes.lastSixMonths'},
    {name: DATEFIELD_TYPE_LAST_YEAR, label: 'orguser.analytics.panels.datefilterTypes.lastYear'},
];

export const panelDisplayTypeArray: PanelOptionType[] = [
    {name: DISPLAY_TYPE_BAR_CHART, label: 'orguser.analytics.panels.displayTypes.barChart'},
    {name: DISPLAY_TYPE_CIRCLE_CHART, label: 'orguser.analytics.panels.displayTypes.circleChart'},
];

export const getPanelType = (type: number): string => {
    const item = panelTypesArray.find(item => item.name === type);
    return item ? item.label : DEFAULT_NONE;
};

export const getPanelDatefilterType = (type: number): string => {
    const item = panelDatefilterTypeArray.find(item => item.name === type);
    return item ? item.label : DEFAULT_NONE;
};

export const getPanelDisplayType = (type: number): string => {
    const item = panelDisplayTypeArray.find(item => item.name === type);
    return item ? item.label : DEFAULT_NONE;
};
