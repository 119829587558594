import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import {GET_LOGIN_ATTEMPTS} from 'appRedux/actions/admin';
import {RootReducer} from 'appRedux/reducers';
import {LoginAttemptTypes} from 'appRedux/actions/admin/types';

import SecureInformation from 'components/SecureInformation/SecureInformation';

const LoginAttempts: FC = () => {
    const dispatch = useDispatch();

    const {
        admin: {logins},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getAllAttempts = useCallback(data => dispatch({type: GET_LOGIN_ATTEMPTS.REQUEST, payload: data}), [dispatch]);

    const itemsList: LoginAttemptTypes[] = get(logins, 'data', []);
    const itemsNumber = get(logins, 'count', 0);

    return <SecureInformation getAllAttempts={getAllAttempts} itemsList={itemsList} itemsNumber={itemsNumber} />;
};

export default LoginAttempts;
