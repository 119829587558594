import React, {FC, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';

import ProfileSectionWrapper from 'components/AgentScreenComponents/_wrappers/ProfileSectionWrapper';
import UserInformationForm from 'components/Forms/SettingsForms/UserInformationForm/UserInformationForm';
import UserLoginAttemptsList from 'components/UserLoginAttemptsList/UserLoginAttemptsList';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import DeleteAccountForm from 'components/Forms/SettingsForms/DeleteAccountForm/DeleteAccountForm';

import UpdateEmailPanel from 'pages/common/account/UpdateEmailPanel';

import {userRoles} from 'config/index';

const AccountSettingsPage: FC = () => {
    const [t] = useTranslation();

    const {setPageTitle, setTopBarTitle, setBackLink, setBackTab, setBackLinkLabel} = useContext(RouteContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isSuperAdmin = profile && profile.roles.includes(userRoles.ROLE_SUPER_ADMIN);
    const isSsoLogin = profile && profile.isSsoLogin;
    const isAnonymous = profile && profile.isAnonymousAccount;

    useEffect(() => {
        setPageTitle(t('common.menu.account'));
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, []);

    return (
        <>
            <ProfileSectionWrapper
                identifier="editYourProfileInformation"
                title={t('common.account.editYourProfileInformation')}
                isEditProfile
            >
                <UserInformationForm />
            </ProfileSectionWrapper>
            {!isSuperAdmin && !isSsoLogin && !isAnonymous && (
                <ProfileSectionWrapper identifier="updateEmail" title={t('common.account.updateEmail')}>
                    <UpdateEmailPanel />
                </ProfileSectionWrapper>
            )}
            {!isSuperAdmin && (
                <ProfileSectionWrapper identifier="deleteAccount" title={t('common.account.deleteAccount')}>
                    <DeleteAccountForm />
                </ProfileSectionWrapper>
            )}
            <ClientSectionWrapper toggleIdentifier="lastLoginAttempts" title={t('common.account.lastLoginAttempts')}>
                <UserLoginAttemptsList />
            </ClientSectionWrapper>
        </>
    );
};

export default AccountSettingsPage;
