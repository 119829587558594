import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import {CreateOrganizationRequestTypes, OrganizationItemBasic} from 'appRedux/actions/organization/types';
import {RootReducer} from 'appRedux/reducers';
import {UPDATE_ORGANIZATION} from 'appRedux/actions/organization';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import UpdateOrganizationForm from 'components/Forms/OrganizationForm/UpdateOrganizationForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import OrganizationMediaForms from 'components/Forms/OrganizationForm/OrganizationMediaForms';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import OrganizationInformation from 'pages/admin/myOrganization/OrganizationInformation';
import OrganizationTwoFactorAuthSwitcher from 'pages/admin/updateOrganization/partials/OrganizationTwoFactorAuthSwitcher';
import OrganizationLanguages from 'pages/admin/updateOrganization/partials/OrganizationLanguages/OrganizationLanguages';
import OrganizationSsoCredentials from 'pages/admin/updateOrganization/partials/OrganizationSsoCredentials';

import {getLanguageById} from 'helpers/translationsHelper';

interface OrganizationContentType {
    organization: OrganizationItemBasic;
}

const OrganizationContent: FC<OrganizationContentType> = ({organization}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isOrganizationEditEnabled, isRoleViewEnabled} = useContext(PermissionContext);

    const [showForm, setShowForm] = useState<boolean>(false);

    const toggleForm = () => {
        setShowForm(previous => !previous);
    };

    const {
        admin: {languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitUpdateOrganization = useCallback(
        data => dispatch({type: UPDATE_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: CreateOrganizationRequestTypes) => {
        submitUpdateOrganization({
            ...values,
            id: organization.id,
            showAlert,
        });
    };

    const currentOrganizationLanguage = getLanguageById(languageList, organization.language);

    if (showForm && currentOrganizationLanguage) {
        return (
            <>
                <ClientSectionWrapper title={t('superadmin.organizations.organizationInfo')}>
                    <Grid container>
                        <Grid item sm={8}>
                            <UpdateOrganizationForm
                                organizationId={organization.id}
                                initialValues={organization}
                                onSubmitClicked={onSubmitClicked}
                                currentOrganizationLanguage={currentOrganizationLanguage}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <OrganizationMediaForms id={Number(organization.id)} organization={organization} />
                        </Grid>
                    </Grid>
                </ClientSectionWrapper>
                <ClientSectionWrapper title={t('superadmin.organizations.organizationLanguages')}>
                    <OrganizationLanguages
                        id={Number(organization.id)}
                        organizationLanguage={currentOrganizationLanguage}
                    />
                </ClientSectionWrapper>
                <ClientSectionWrapper title={t('superadmin.organizations.twoFactorAuthTitle')}>
                    <OrganizationTwoFactorAuthSwitcher
                        id={Number(organization.id)}
                        isForcedTwoFactor={Boolean(organization.isForcedTwoFactor)}
                    />
                </ClientSectionWrapper>
                {isRoleViewEnabled && (
                    <ClientSectionWrapper title={t('superadmin.organizations.organizationSsoCredentials')}>
                        <OrganizationSsoCredentials
                            organizationId={Number(organization.id)}
                            initialValues={organization}
                        />
                    </ClientSectionWrapper>
                )}
            </>
        );
    }

    return (
        <ClientSectionWrapper title={t('superadmin.organizations.organizationInfo')}>
            <OrganizationInformation organization={organization} />
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', m: 2}}>
                {isOrganizationEditEnabled && !showForm && (
                    <AgentSaveButton isSubmit={false} title={t('common.buttons.update')} onClick={toggleForm} />
                )}
            </Box>
        </ClientSectionWrapper>
    );
};

export default OrganizationContent;
