import React, {FC, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ExportSvgIcon from 'assets/icons/buttons/ExportSvgIcon';

import {RequesterCaseTagTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';
import {REQUEST_CASE_ACTIVITIES_EXPORT} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import AvatarImage from 'components/UserAvatar/AvatarImage';
import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

import {theme} from 'config/theme';
import {routes} from 'config/index';

const ActivityHeader: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, formId, tags, status, userName, caseLabel, isEncryptInfo} = currentCase;

    const exportCaseActivities = useCallback(
        data => dispatch({type: REQUEST_CASE_ACTIVITIES_EXPORT.REQUEST, payload: data}),
        [dispatch],
    );

    const redirectToCase = () => {
        navigate(`${routes.REQUESTER_CASE}/${id}/form/${formId}`);
    };

    const downloadFile = (blob: Blob | string) => {
        if (typeof blob === 'string') return;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Export case #${id} activities.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const exportActivitiesList = () => {
        exportCaseActivities({
            id,
            showAlert,
            callback: downloadFile,
        });
    };

    if (!currentCase) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                flex: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: 'inherit',
                pt: 1,
                pb: 2,
                borderRadius: 0,
                flexGrow: 1,
                mx: 2,
            }}
        >
            <AvatarImage
                size={48}
                avatarLink={''}
                avatarType={0}
                avatarTitle={getAvatarTitle(userName?.split(', ')[0] || null, userName?.split(', ')[1] || null, '')}
            />
            <Box sx={{pl: 1.5, mr: 3}}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Tooltip
                        title={caseLabel}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    fontSize: 18,
                                    backgroundColor: 'rgb(97, 97, 97)',
                                },
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 24,
                                fontWeight: 600,
                                color: theme.palette.info.main,
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                mr: 1,
                            }}
                        >
                            {caseLabel}
                        </Typography>
                    </Tooltip>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: theme.palette.info.main,
                            }}
                        >
                            {`${t('orguser.requesterCase.status')}: ` +
                                t(`orguser.requesterCase.statusTypes.${status}`) +
                                ' | '}
                        </Typography>
                    </Box>
                    {tags.map((item: RequesterCaseTagTypes, index: number) => {
                        const {tag, color} = item;
                        return (
                            <Tooltip title={tag} key={`tag-chip-${index}`}>
                                <Box
                                    sx={{
                                        width: 12,
                                        height: 12,
                                        ml: 1,
                                        borderRadius: '50%',
                                        backgroundColor: color,
                                    }}
                                />
                            </Tooltip>
                        );
                    })}
                </Box>
            </Box>
            <Box sx={{display: 'flex', flex: 'row'}}>
                <AgentInverseButton title={t('orguser.requesterCase.linkToCase')} onClick={redirectToCase} />
                {!isEncryptInfo && (
                    <AgentInverseButton
                        title={t('orguser.requesterCase.exportActivities')}
                        onClick={exportActivitiesList}
                        icon={<ExportSvgIcon />}
                    />
                )}
            </Box>
        </Box>
    );
};

export default ActivityHeader;
