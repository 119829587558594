import React, {FC} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {FormHasAdditionalTypes} from 'appRedux/actions/forms/types';

import {TableHeadTypography} from 'components/AgentScreenComponents/_form/CustomElements';
import FormHasAdditionalForm from 'components/Forms/FormBuilder/FormHasAdditionalForm/FormHasAdditionalForm';

import FormHasAdditionalItem from 'pages/admin/updateForm/partials/FormHasAdditional/FormHasAdditionalItem';

interface ComponentType {
    formId: number;
}

const FormHasAdditional: FC<ComponentType> = ({formId}) => {
    const [t] = useTranslation();

    const {
        admin: {formInfo, isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formHasAdditionalItems: FormHasAdditionalTypes[] = get(formInfo, 'additionalForms', []);

    return (
        <Box>
            {!isLoading && formHasAdditionalItems.length === 0 && (
                <Typography>{t('orguser.forms.formHasAdditional.noneAdditionalForm')}</Typography>
            )}
            {formHasAdditionalItems && formHasAdditionalItems.length > 0 && (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={250}>
                                <TableHeadTypography text={t('orguser.forms.formHasAdditional.type')} />
                            </TableCell>
                            <TableCell width={300}>
                                <TableHeadTypography text={t('orguser.forms.formHasAdditional.title')} />
                            </TableCell>
                            <TableCell>
                                <TableHeadTypography text={t('orguser.forms.formHasAdditional.description')} />
                            </TableCell>
                            <TableCell width={100}>
                                <TableHeadTypography text={t('orguser.forms.formHasAdditional.action')} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formHasAdditionalItems.map((item: FormHasAdditionalTypes) => {
                            return <FormHasAdditionalItem key={`form-has-additional-${item.uuid}`} item={item} />;
                        })}
                    </TableBody>
                </Table>
            )}
            <FormHasAdditionalForm formId={formId} />
        </Box>
    );
};

export default FormHasAdditional;
