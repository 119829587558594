import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {CHAT_AGENT_CONTACTS} from 'appRedux/actions/requestChat';
import {RootReducer} from 'appRedux/reducers';
import {ChatContactTypes} from 'appRedux/actions/requestChat/types';

import {AdminContext} from 'contexts/admin/context';

import ChatContactItem from 'pages/agent/chat/ChatContactItem';

import {theme} from 'config/theme';

const ChatContactsList: FC = () => {
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {searchValue} = useContext(AdminContext);

    const MINIMAL_SEARCH_LENGTH = 2;

    const getChatContacts = useCallback(() => dispatch({type: CHAT_AGENT_CONTACTS.REQUEST}), [dispatch]);

    const {
        requestChat: {contacts, messages},
        requestCase: {
            currentCase: {isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getChatContacts();
    }, [requestCase, messages]);

    const filteredContacts =
        contacts && Array.isArray(contacts)
            ? contacts.filter((contact: ChatContactTypes) => {
                  if (searchValue.length >= MINIMAL_SEARCH_LENGTH) {
                      const {firstName, lastName} = contact;
                      const lowerCaseValue = searchValue.toLowerCase();
                      return (
                          (firstName && firstName.toLowerCase().includes(lowerCaseValue)) ||
                          (lastName && lastName.toLowerCase().includes(lowerCaseValue))
                      );
                  }
                  return true;
              })
            : [];

    const isFirst = filteredContacts[0]?.caseId === Number(requestCase);

    return (
        <Box
            sx={{
                overflowY: 'auto',
                maxHeight: `calc(100vh - 180px)`,
                borderTop: isFirst ? 'none' : `1px solid ${theme.palette.info.contrastText}`,
            }}
        >
            {filteredContacts &&
                filteredContacts.map((item: ChatContactTypes, index: number) => {
                    return (
                        <ChatContactItem
                            key={`contact-${index}`}
                            item={item}
                            nextId={filteredContacts[index + 1]?.caseId}
                        />
                    );
                })}
        </Box>
    );
};

export default ChatContactsList;
