import React, {FC, RefObject, useContext, useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {MediaContext} from 'contexts/media/context';
import {AdminContext} from 'contexts/admin/context';

import QuestionSectionAttachment from 'components/RequesterChat/QuestionSectionAttachment';
import RequesterCaseChatForm from 'components/Forms/RequesterCaseChatForm/RequesterCaseChatForm';
import InfectedFilePopup from 'components/InfectedFilePopup/InfectedFilePopup';

import {getFileUploadErrorMessage} from 'helpers/documentsUploading';

import {ERROR_TEXT_COLOR} from 'config/theme';

interface RequesterCaseChatType {
    caseId?: string;
    wrapperRef?: RefObject<HTMLDivElement | undefined>;
}

const RequesterCaseChat: FC<RequesterCaseChatType> = ({caseId, wrapperRef}) => {
    const [t] = useTranslation();

    const {isMobile} = useContext(MediaContext);
    const {questionSectionId, questionSectionName, setQuestionSectionId, setQuestionSectionName} =
        useContext(AdminContext);

    const [errors, setErrors] = useState<IErrors>();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [attemptsNumber, setAttemptsNumber] = useState<number | null>(null);

    const toggleIsOpened = () => {
        setShowPopup(previous => !previous);
    };

    const showFileInfectedMessage = (value: number | null) => {
        setShowPopup(true);
        setAttemptsNumber(value);
    };

    const resetSelectedSection = () => {
        if (setQuestionSectionId) {
            setQuestionSectionId(null);
            setQuestionSectionName(null);
        }
    };

    useEffect(() => {
        return () => {
            resetSelectedSection();
        };
    }, []);

    return (
        <Box sx={{pb: isMobile ? 3 : 'initial'}}>
            <InfectedFilePopup
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                attemptsNumber={attemptsNumber}
                toggleIsOpened={toggleIsOpened}
            />
            {questionSectionId && questionSectionName && (
                <QuestionSectionAttachment
                    questionSectionName={questionSectionName}
                    resetSelectedSection={resetSelectedSection}
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <RequesterCaseChatForm
                    caseId={caseId}
                    setErrors={setErrors}
                    resetSelectedSection={resetSelectedSection}
                    showFileInfectedMessage={showFileInfectedMessage}
                    wrapperRef={wrapperRef}
                />
            </Box>
            {!isEmpty(errors) && (
                <Typography
                    align="center"
                    style={{
                        color: ERROR_TEXT_COLOR,
                        fontSize: 12,
                    }}
                >
                    {t(getFileUploadErrorMessage(String(errors)))}
                </Typography>
            )}
        </Box>
    );
};

export default RequesterCaseChat;
