import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import MessagesSvgIcon from 'assets/icons/menu/MessagesSvgIcon';

import {routes} from 'config/index';
import {MAIN_CLIENT_COLOR} from 'config/theme';

interface ChatButtonType {
    caseId: number;
    notReadMsg?: boolean;
}

const ChatButton: FC<ChatButtonType> = ({caseId, notReadMsg}) => {
    return (
        <Link to={`${routes.REQUESTER_CASE}/${caseId}/chat`}>
            <IconButton
                sx={{
                    width: 28,
                    height: 28,
                    backgroundColor: MAIN_CLIENT_COLOR,
                    '&:hover': {
                        backgroundColor: MAIN_CLIENT_COLOR,
                    },
                }}
            >
                <Badge color="error" variant="dot" invisible={!notReadMsg}>
                    <MessagesSvgIcon isActive />
                </Badge>
            </IconButton>
        </Link>
    );
};

export default ChatButton;
