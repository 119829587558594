import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import {FORM_FIELD_UPDATE} from 'appRedux/actions/forms';
import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import FieldForm from 'components/Forms/FormBuilder/FieldForm/Form';
import {formFieldInitialValues} from 'components/Forms/FormBuilder/FieldForm/validation';

import {getPopupFieldById, getFieldById} from 'pages/admin/updateForm/partials/FormStructure/helper';

interface UpdateFormFieldType {
    fieldId: number;
    toggleModal: () => void;
    isPopup?: boolean;
}

const UpdateFormField: FC<UpdateFormFieldType> = ({fieldId, toggleModal, isPopup}) => {
    const dispatch = useDispatch();

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const pages: FormPageTypes[] = get(formInfo, 'pages', []);

    const submitUpdateForm = useCallback(
        data => dispatch({type: FORM_FIELD_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const fieldValues = isPopup ? getPopupFieldById(pages, fieldId) : getFieldById(pages, fieldId);
    const initialValues = {...formFieldInitialValues, ...fieldValues};

    return (
        <FieldForm
            entityId={fieldId}
            initialValues={initialValues}
            onSubmitAction={submitUpdateForm}
            toggleModal={toggleModal}
            newItemPriority={get(initialValues, 'priority', 0)}
            isPopup={isPopup}
        />
    );
};

export default UpdateFormField;
