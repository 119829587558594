import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {RequesterCaseAllItemsListType} from 'appRedux/actions/requestCase/types';

import {ERROR_TEXT_COLOR} from 'config/theme';
import {DATE_FORMAT} from 'config/index';

interface DraftAutoRemoveAlertType {
    isMobile?: boolean;
    requestCase: RequesterCaseAllItemsListType;
}

const DraftAutoRemoveAlert: FC<DraftAutoRemoveAlertType> = ({requestCase, isMobile}) => {
    const [t] = useTranslation();

    const {createdAt} = requestCase;

    return (
        <Button
            sx={{
                cursor: 'initial',
                minWidth: 120,
                minHeight: 40,
                mr: isMobile ? 0 : 1,
                p: isMobile ? 1 : 0,
                pl: isMobile ? 1 : 2,
                pr: isMobile ? 1 : 2,
                borderRadius: 20,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#A9BBC4FF',
                backgroundColor: '#E4F6FF',
                '&:hover': {
                    backgroundColor: '#E4F6FF',
                },
            }}
        >
            <Typography
                sx={{
                    textTransform: 'none',
                    color: ERROR_TEXT_COLOR,
                    ml: 1,
                    mr: 1,
                }}
            >
                {t('requester.casesList.draftRemovingAlert').replace(
                    '%DATE%',
                    moment(createdAt).add(14, 'days').format(DATE_FORMAT),
                )}
            </Typography>
        </Button>
    );
};

export default DraftAutoRemoveAlert;
