import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import {SelectChangeEvent} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';
import {FORM_FILTERS_CLEAR, FORM_FILTERS_GET} from 'appRedux/actions/forms';
import {REQUESTER_CASES_LIST} from 'appRedux/actions/admin';
import {FormFieldFilterType} from 'appRedux/actions/forms/types';

import FilterSvgIcon from 'assets/icons/buttons/FilterSvgIcon';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';

import Filter from 'components/AgentScreenComponents/_topBar/Filter';
import Search from 'components/AgentScreenComponents/_topBar/Search';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import SelectButton from 'components/AgentScreenComponents/_caseBlock/SelectButton';
import SelectAllButton from 'components/AgentScreenComponents/_caseBlock/SelectAllButton';
import {filterParameters} from 'components/BlockView/helper';

import {VIEW_MODE_BOARD, VIEW_MODE_TABLE} from 'helpers/filter';

import {
    PARAMETER_AGENT,
    PARAMETER_VIEW_MODE,
    DEFAULT_PAGE,
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    ITEMS_PER_PAGE_DEFAULT,
    ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
    PARAMETER_TAG,
    PARAMETER_FORM,
    PARAMETER_FIRST_LETTER,
} from 'config/index';

const ActiveCasesTopBar: FC = () => {
    const {search} = useLocation();
    const [t] = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const {
        filtersNumber,
        itemsPerPage,
        showFiltersPanel,
        setShowFiltersPanel,
        setFiltersNumber,
        toggleFiltersPanel,
        isSelectClicked,
    } = useContext(AdminContext);
    const {isCaseEditEnabled} = useContext(PermissionContext);

    const viewModeFromUrl = searchParams.get(PARAMETER_VIEW_MODE);
    const agentFromUrl = searchParams.get(PARAMETER_AGENT);

    const currentForms = searchParams.get(PARAMETER_FORM);
    const currentFormsArray = currentForms ? currentForms.split(',') : [];

    const {
        admin: {formFieldFilters},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const clearFormFieldFilters = useCallback(() => dispatch({type: FORM_FILTERS_CLEAR.REQUEST}), [dispatch]);
    const refreshCases = useCallback(() => dispatch({type: REQUESTER_CASES_LIST.REQUEST_EMPTY}), [dispatch]);
    const getFormFieldFilters = useCallback(
        data => dispatch({type: FORM_FILTERS_GET.REQUEST, payload: data}),
        [dispatch],
    );

    const filters: FormFieldFilterType[] = get(formFieldFilters, 'filters', []);

    useEffect(() => {
        const filtersNumber = [
            ...filterParameters,
            ...filters.map((filter: FormFieldFilterType) => `field${filter.id}`),
        ].filter(parameter => searchParams.get(parameter)).length;
        setFiltersNumber(filtersNumber);
    }, [search, filters]);

    const clearAllFilters = () => {
        refreshCases();
        searchParams.delete(PARAMETER_PAGE);
        searchParams.delete(PARAMETER_PER_PAGE);
        searchParams.delete(PARAMETER_AGENT);
        searchParams.delete(PARAMETER_LAST_CREATED);
        searchParams.delete(PARAMETER_LAST_UPDATED);
        searchParams.delete(PARAMETER_TAG);
        searchParams.delete(PARAMETER_FORM);
        searchParams.delete(PARAMETER_FIRST_LETTER);
        filters.map((filter: FormFieldFilterType) => {
            searchParams.delete(`field${filter.id}`);
        });
        setFiltersNumber(0);
        setSearchParams(searchParams);
        clearFormFieldFilters();
    };

    const currentUserId = get(profile, 'id', null);

    const onAllCasesClicked = () => {
        searchParams.set(PARAMETER_AGENT, '');
        setSearchParams(searchParams);
    };

    const onMyCasesClicked = () => {
        searchParams.set(PARAMETER_AGENT, String(currentUserId));
        setSearchParams(searchParams);
    };

    const isMyCasesPage = String(agentFromUrl) === String(currentUserId);
    const isBoardMode = !viewModeFromUrl || viewModeFromUrl === VIEW_MODE_BOARD;

    const handlePerPageChange = (event: SelectChangeEvent): void => {
        const value = event.target.value;
        searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        searchParams.set(PARAMETER_PER_PAGE, value);
        setSearchParams(searchParams);
    };

    useEffect(() => {
        return () => {
            setShowFiltersPanel(false);
        };
    }, []);

    useEffect(() => {
        if (currentFormsArray.length === 1) {
            getFormFieldFilters({
                id: currentFormsArray[0],
            });
        }
    }, []);

    if (!profile) {
        return null;
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Search />
            {isMyCasesPage ? (
                <>
                    <AgentInverseButton
                        title={t('orguser.requesterCase.topbar.allCases')}
                        onClick={onAllCasesClicked}
                    />
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('orguser.requesterCase.topbar.myCases')}
                        onClick={onMyCasesClicked}
                    />
                </>
            ) : (
                <>
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('orguser.requesterCase.topbar.allCases')}
                        onClick={onAllCasesClicked}
                    />
                    <AgentInverseButton title={t('orguser.requesterCase.topbar.myCases')} onClick={onMyCasesClicked} />
                </>
            )}
            {showFiltersPanel ? (
                <AgentSaveButton
                    isSubmit={false}
                    title={`${t('orguser.requesterCase.topbar.filter')}${
                        filtersNumber > 0 ? ' (' + filtersNumber + ')' : ''
                    }`}
                    onClick={toggleFiltersPanel}
                    icon={filtersNumber === 0 ? <FilterSvgIcon isActive /> : undefined}
                    onClose={filtersNumber > 0 ? clearAllFilters : undefined}
                />
            ) : (
                <AgentInverseButton
                    title={`${t('orguser.requesterCase.topbar.filter')}${
                        filtersNumber > 0 ? ' (' + filtersNumber + ')' : ''
                    }`}
                    onClick={toggleFiltersPanel}
                    icon={filtersNumber === 0 ? <FilterSvgIcon /> : undefined}
                    onClose={filtersNumber > 0 ? clearAllFilters : undefined}
                />
            )}

            {/* BOARD/TABLE mode switcher */}
            {/*<AgentInverseButton*/}
            {/*    title={ignoreTagsTranslation(t, isBoardMode ? VIEW_MODE_TABLE : VIEW_MODE_BOARD)}*/}
            {/*    icon={isBoardMode ? <TableSvgIcon /> : <BoardSvgIcon />}*/}
            {/*    onClick={() => {*/}
            {/*        const changeTo = isBoardMode ? VIEW_MODE_TABLE : VIEW_MODE_BOARD;*/}
            {/*        localStorage.setItem(LOCAL_STORAGE_ACTIVE_CASES_VIEW, changeTo);*/}
            {/*        searchParams.set(PARAMETER_VIEW_MODE, changeTo);*/}
            {/*        searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));*/}
            {/*        searchParams.set(*/}
            {/*            PARAMETER_PER_PAGE,*/}
            {/*            String(isBoardMode ? ITEMS_PER_PAGE_FOR_CASES : itemsPerPage),*/}
            {/*        );*/}
            {/*        setSearchParams(searchParams);*/}
            {/*        setIsSelectClicked(false);*/}
            {/*        setSelectedIds([]);*/}
            {/*    }}*/}
            {/*/>*/}

            {isCaseEditEnabled && <SelectButton />}
            {isSelectClicked && isBoardMode && <SelectAllButton />}
            {!isBoardMode && (
                <Filter
                    filterName={PARAMETER_PER_PAGE}
                    filterLabel={t('common.perPage')}
                    optionList={ITEMS_PER_PAGE_OPTIONS_DEFAULT.map(item => ({
                        name: String(item),
                        label: String(item),
                    }))}
                    handleFilterChange={handlePerPageChange}
                    parameter={PARAMETER_PER_PAGE}
                />
            )}
        </Box>
    );
};

export default ActiveCasesTopBar;
