import {ElementType} from 'react';
import {TFunction} from 'react-i18next';

import HomeSvgIcon from 'assets/icons/buttons/HomeSvgIcon';
import DashboardSvgIcon from 'assets/icons/menu/DashboardSvgIcon';
import ActiveCasesSvgIcon from 'assets/icons/menu/ActiveCasesSvgIcon';
import MessagesSvgIcon from 'assets/icons/menu/MessagesSvgIcon';
import HelpedCasesSvgIcon from 'assets/icons/menu/HelpedCasesSvgIcon';
import SecuritySvgIcon from 'assets/icons/topBarMenu/SecuritySvgIcon';
import AccountSvgIcon from 'assets/icons/topBarMenu/AccountSvgIcon';
import PasswordSvgIcon from 'assets/icons/buttons/PasswordSvgIcon';
import RuleSvgIcon from 'assets/icons/buttons/RuleSvgIcon';
import PolicySvgIcon from 'assets/icons/buttons/PolicySvgIcon';
import EnvelopmentSvgIcon from 'assets/icons/buttons/EnvelopmentSvgIcon';
import UserRoleSvgIcon from 'assets/icons/buttons/UserRoleSvgIcon';
import LoginSvgIcon from 'assets/icons/buttons/LoginSvgIcon';
import CaseAccessRequestSvgIcon from 'assets/icons/buttons/CaseAccessRequestSvgIcon';
import OrgUsersSvgIcon from 'assets/icons/buttons/OrgUsersSvgIcon';
import WorkflowsSvgIcon from 'assets/icons/buttons/WorkflowSvgIcon';
import BoardSvgIcon from 'assets/icons/buttons/BoardSvgIcon';
import PanelSvgIcon from 'assets/icons/buttons/PanelSvgIcon';
import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {ChatContactTypes} from 'appRedux/actions/requestChat/types';
import {MainProfileResponseTypes} from 'appRedux/actions/profile/types';
import {AgentSavedFilterTypes} from 'appRedux/actions/agentFilters/types';
import {BoardItemTypes} from 'appRedux/actions/workflow/types';
import {DashboardItemsTypes} from 'appRedux/actions/analytics/types';

import {PermissionContextType} from 'contexts/permission/context';

import {getNotReadMessagesNumber} from 'components/AdminScreenComponents/translationsHelper';
import {getBoardIconByType, BOARD_TYPE_LIST, BOARD_TYPE_OVERVIEW} from 'components/Forms/BoardForm/helper';

import {getFilterIconByType} from 'pages/agent/savedFilters/helper';

import {
    isCustomBoardPage,
    isDashboardPanelsPage,
    isRolePage,
    isUsersListPage,
    isBoardsListPage,
    isInvitePage,
    isPublicKeysPage,
    isWorkflowsListPage,
    isRolesListPage,
    isAssetRequestsPage,
    isAccessRequestsListPage,
    isOrganizationTemplatesPage,
    isWorkflowPage,
} from 'helpers/menuHelper';

import {routes, PARAMETER_TAB, PARAMETER_LANGUAGE} from 'config/index';

export const ORG_USERS_LIST = 'users';
export const ROLES_LIST = 'roles';
export const BOARDS_LIST = 'boards';
export const FORMS_LIST = 'forms';
export const INVITES_LIST = 'invites';
export const PUBLIC_KEYS_LIST = 'publicKeys';
export const ACCESS_REQUESTS_LIST = 'accessRequests';
export const WORKFLOWS_LIST = 'workflows';
export const PANELS_LIST = 'panels';
export const DASHBOARDS_LIST = 'dashboards';
export const DASHBOARD_PAGE = 'dashboard';
export const TEMPLATES_LIST = 'templates';

export const ADMIN_DASHBOARD = '';
export const ADMIN_MY_ORGANIZATION = 'myOrganization';

export const AGENT_DASHBOARD = '';
export const AGENT_MESSAGES = 'messages';
export const AGENT_MY_ORGANIZATION = 'myOrganization';
export const AGENT_REQUESTERS = 'requesters';
export const AGENT_MY_REQUESTERS = 'myRequesters';

export const SUPER_ADMIN_DASHBOARD = '';
export const SUPER_ADMIN_INSTANCE = 'instance';
export const SUPER_ADMIN_ORGANIZATIONS = 'organizations';
export const SUPER_ADMIN_FORMS = 'forms';
export const SUPER_ADMIN_GENERAL_TEMPLATES = 'templates';
export const SUPER_ADMIN_SECURITY = 'secure';
export const SUPER_ADMIN_LOGIN_ATTEMPTS = 'loginAttempts';
export const SUPER_ADMIN_PASSWORD_RESET_ATTEMPTS = 'passwordResets';
export const SUPER_ADMIN_DRAFT_REMOVES = 'draftRemoves';
export const SUPER_ADMIN_IMPRINT = 'imprint';
export const SUPER_ADMIN_PRIVACY_POLICY = 'privacyPolicy';

export const AGENT_TOP_BAR_HEIGHT = 96;

export interface AgentMenuItemTypes {
    icon?: ElementType;
    label: string;
    href: string;
    link: string;
    isHidden?: boolean;
    badge?: number;
    matches?: string[];
    subItems?: AgentMenuItemTypes[];
}

export const getSuperAdminBlockItems = (lang: string): AgentMenuItemTypes[] => [
    {
        icon: HomeSvgIcon,
        label: 'common.menu.dashboard',
        href: '/',
        link: routes.SUPER_ADMIN,
    },
    {
        icon: ActiveCasesSvgIcon,
        label: 'common.menu.instance',
        href: `${routes.SUPER_ADMIN}?${PARAMETER_TAB}=${SUPER_ADMIN_INSTANCE}`,
        link: SUPER_ADMIN_INSTANCE,
    },
    {
        icon: AccountSvgIcon,
        label: 'common.menu.organizations',
        href: `${routes.SUPER_ADMIN}?${PARAMETER_TAB}=${SUPER_ADMIN_ORGANIZATIONS}`,
        link: SUPER_ADMIN_ORGANIZATIONS,
        matches: [routes.ADD_ORGANIZATION, routes.UPDATE_ORGANIZATION, routes.ROLE],
    },
    {
        icon: HelpedCasesSvgIcon,
        label: 'common.menu.forms',
        href: `${routes.SUPER_ADMIN}?${PARAMETER_TAB}=${SUPER_ADMIN_FORMS}`,
        link: SUPER_ADMIN_FORMS,
    },
    {
        icon: PasswordSvgIcon,
        label: 'common.menu.publicKeys',
        href: `${routes.SUPER_ADMIN}?${PARAMETER_TAB}=${PUBLIC_KEYS_LIST}`,
        link: PUBLIC_KEYS_LIST,
        matches: [PUBLIC_KEYS_LIST],
    },
    {
        icon: EnvelopmentSvgIcon,
        label: 'common.menu.mailer',
        href: `${routes.SUPER_ADMIN}?${PARAMETER_TAB}=${SUPER_ADMIN_GENERAL_TEMPLATES}&${PARAMETER_LANGUAGE}=${lang}`,
        link: SUPER_ADMIN_GENERAL_TEMPLATES,
    },
];

export const getSuperAdminSecureBlockItems: AgentMenuItemTypes[] = [
    {
        icon: LoginSvgIcon,
        href: `${routes.SUPER_ADMIN}?${PARAMETER_TAB}=${SUPER_ADMIN_LOGIN_ATTEMPTS}`,
        link: SUPER_ADMIN_LOGIN_ATTEMPTS,
        label: 'common.menu.loginAttempts',
        isHidden: false,
    },
    {
        icon: SecuritySvgIcon,
        href: `${routes.SUPER_ADMIN}?${PARAMETER_TAB}=${SUPER_ADMIN_PASSWORD_RESET_ATTEMPTS}`,
        link: SUPER_ADMIN_PASSWORD_RESET_ATTEMPTS,
        label: 'common.menu.passwordResets',
        isHidden: false,
    },
    {
        icon: DeleteSvgIcon,
        href: `${routes.SUPER_ADMIN}?${PARAMETER_TAB}=${SUPER_ADMIN_DRAFT_REMOVES}`,
        link: SUPER_ADMIN_DRAFT_REMOVES,
        label: 'common.menu.draftRemoves',
        isHidden: false,
    },
    {
        icon: RuleSvgIcon,
        href: `${routes.SUPER_ADMIN}?${PARAMETER_TAB}=${SUPER_ADMIN_IMPRINT}`,
        link: SUPER_ADMIN_IMPRINT,
        label: 'common.menu.imprint',
        isHidden: false,
    },
    {
        icon: PolicySvgIcon,
        href: `${routes.SUPER_ADMIN}?${PARAMETER_TAB}=${SUPER_ADMIN_PRIVACY_POLICY}`,
        link: SUPER_ADMIN_PRIVACY_POLICY,
        label: 'common.menu.privacyPolicy',
        isHidden: false,
    },
];

export const getSavedFiltersForMenu = (
    path: string,
    savedFilters: AgentSavedFilterTypes[],
    label: string,
): AgentMenuItemTypes[] => {
    const results: AgentMenuItemTypes[] = [];
    savedFilters.forEach(filter => {
        const {title, iconType, text} = filter;
        if (text.includes(label)) {
            results.push({
                icon: getFilterIconByType(iconType),
                label: title,
                href: `${path}${text}`,
                link: label,
            });
        }
    });
    return results;
};

export const getBoardSavedFilters = (savedFilters: AgentSavedFilterTypes[], uuid: string): AgentMenuItemTypes[] => {
    const results: AgentMenuItemTypes[] = [];
    savedFilters.forEach(filter => {
        const {title, iconType, text, boardUuid} = filter;
        if (text && uuid === boardUuid) {
            results.push({
                icon: getFilterIconByType(iconType),
                label: title,
                href: text,
                link: title,
            });
        }
    });
    return results;
};

export const getFirstAgentBlockItems = (
    firstCaseId: number | null,
    contacts: ChatContactTypes[],
    savedFilters: AgentSavedFilterTypes[],
    dashboards: DashboardItemsTypes[],
    permissions: PermissionContextType,
    isAgent: boolean,
    createdCaseAccessRequestsNumber: number,
): AgentMenuItemTypes[] => {
    const path = isAgent ? routes.AGENT : routes.ADMIN;
    return [
        {
            icon: HomeSvgIcon,
            label: 'common.menu.dashboard',
            href: '/',
            link: path,
        },
        {
            icon: AccountSvgIcon,
            label: 'common.menu.myOrganization',
            href: `${path}?${PARAMETER_TAB}=${AGENT_MY_ORGANIZATION}`,
            link: AGENT_MY_ORGANIZATION,
            isHidden: !permissions.isOrganizationViewEnabled,
            matches: [],
            subItems: getOrganizationSubItems(path, permissions, createdCaseAccessRequestsNumber),
        },
        {
            icon: HelpedCasesSvgIcon,
            label: 'common.menu.forms',
            href: `${path}?${PARAMETER_TAB}=${FORMS_LIST}`,
            link: FORMS_LIST,
            isHidden: !permissions.isFormViewEnabled,
            matches: [FORMS_LIST, routes.FORM, routes.FORM_TRANSLATIONS],
            subItems: getFormSubItems(path, permissions),
        },
        {
            icon: DashboardSvgIcon,
            label: 'common.menu.analytics',
            href: `${path}?${PARAMETER_TAB}=${DASHBOARDS_LIST}`,
            link: DASHBOARDS_LIST,
            isHidden: !permissions.isDashboardViewEnabled,
            matches: [DASHBOARDS_LIST],
            subItems: getAnalyticsSubItems(path, dashboards, permissions),
        },
        {
            icon: MessagesSvgIcon,
            label: 'common.menu.messages',
            href: `${routes.REQUESTER_CASE}/${firstCaseId}/chat`,
            link: AGENT_MESSAGES,
            isHidden: !firstCaseId || !permissions.isCaseEditEnabled,
            badge: getNotReadMessagesNumber(contacts),
            matches: ['messages', 'chat'],
        },
    ];
};

export const getBoardsItemsBlock = (
    boards: BoardItemTypes[],
    itemsPerPage: number,
    permissions: PermissionContextType,
    savedFilters: AgentSavedFilterTypes[],
): AgentMenuItemTypes[] => {
    const results: AgentMenuItemTypes[] = [];
    boards.forEach((board: BoardItemTypes) => {
        const {iconType, title, isActive, uuid, type} = board;
        const Icon = getBoardIconByType(iconType);
        if (isActive) {
            results.push({
                icon: Icon,
                label: title,
                href: getBoardHref(uuid, type),
                matches: [uuid],
                link: 'customBoard',
                isHidden: !permissions.isCaseViewEnabled,
                subItems: getBoardSavedFilters(savedFilters, uuid),
            });
        }
    });
    return results;
};

export const getBoardHref = (uuid: string, type: number) => {
    if (type === BOARD_TYPE_LIST) {
        return `${routes.LIST}/${uuid}/cases`;
    }
    if (type === BOARD_TYPE_OVERVIEW) {
        return `${routes.BOARD_OVERVIEW}/${uuid}/cases`;
    }
    return `${routes.BOARD}/${uuid}/cases`;
};

export const getDashboardTitle = (t: TFunction, profile?: MainProfileResponseTypes) => {
    if (!profile) {
        return '';
    }
    const {firstName, lastName, email} = profile;
    const userName = firstName && lastName ? `${firstName} ${lastName}` : email;
    return `${t('common.hello')}, ${userName}!`;
};

export const isMenuItemActive = (menuItem: AgentMenuItemTypes, pathname: string, search: string | null) => {
    return (
        (search && search.includes(`${PARAMETER_TAB}=${menuItem.link}`)) ||
        (search && menuItem.href.includes(search)) ||
        (menuItem.matches &&
            menuItem.matches.some(route => {
                return pathname.includes(route) || (search && search.includes(route));
            })) ||
        (!search && menuItem.href === '/' && [routes.AGENT, routes.ADMIN, routes.SUPER_ADMIN].includes(pathname))
    );
};

export const isMenuSubItemActive = (
    menuItem: AgentMenuItemTypes,
    pathname: string,
    search: string | null,
    uuid?: string,
): boolean => {
    if (menuItem.subItems) {
        if (uuid && isWorkflowPage(pathname, uuid)) {
            return menuItem.subItems.some(item => item.href.includes(WORKFLOWS_LIST));
        }
        if (uuid && isRolePage(pathname, uuid)) {
            return menuItem.subItems.some(item => item.href.includes(ROLES_LIST));
        }
        if (uuid && isCustomBoardPage(pathname, uuid)) {
            return menuItem.subItems.some(item => item.href.includes(`/${uuid}/cases`));
        }
        if (uuid && isDashboardPanelsPage(pathname, uuid)) {
            return menuItem.subItems.some(item => item.href.includes(`/${uuid}/panels`));
        }
        if (uuid && isAssetRequestsPage(pathname, uuid)) {
            return menuItem.subItems.some(item => item.href.includes(ACCESS_REQUESTS_LIST));
        }
        if (search) {
            return (
                isOrganizationTemplatesPage(search, menuItem.subItems) ||
                isUsersListPage(search, menuItem.subItems) ||
                isBoardsListPage(search, menuItem.subItems) ||
                isInvitePage(search, menuItem.subItems) ||
                isPublicKeysPage(search, menuItem.subItems) ||
                isWorkflowsListPage(search, menuItem.subItems) ||
                isRolesListPage(search, menuItem.subItems) ||
                isAccessRequestsListPage(search, menuItem.subItems) ||
                menuItem.subItems.some(item => item.href.includes(search))
            );
        }
    }
    return false;
};

export const isAllSubItemsInactive = (
    menuSubItems: AgentMenuItemTypes[],
    pathname: string,
    search: string | null,
    isAgentPage: boolean,
    uuid?: string,
): boolean => {
    const path = isAgentPage ? routes.AGENT : routes.ADMIN;
    for (let i = 0, n = menuSubItems.length; i < n; i++) {
        if (isCustomBoardPage(pathname, uuid)) {
            return search === '';
        }
        if (isDashboardPanelsPage(pathname, uuid)) {
            return search === '';
        }
        if (menuSubItems[i].href === path + search) {
            return false;
        }
    }
    return true;
};

export const getOrganizationSubItems = (
    path: string,
    permissions: PermissionContextType,
    createdCaseAccessRequestsNumber: number,
): AgentMenuItemTypes[] => {
    return [
        {
            icon: OrgUsersSvgIcon,
            label: 'common.menu.users',
            href: `${path}?${PARAMETER_TAB}=${ORG_USERS_LIST}`,
            link: ORG_USERS_LIST,
            isHidden: !permissions.isUserViewEnabled,
        },
        {
            icon: WorkflowsSvgIcon,
            label: 'common.menu.workflows',
            href: `${path}?${PARAMETER_TAB}=${WORKFLOWS_LIST}`,
            link: WORKFLOWS_LIST,
            isHidden: !permissions.isOrganizationEditEnabled,
            matches: [WORKFLOWS_LIST, routes.WORKFLOW],
        },
        {
            icon: UserRoleSvgIcon,
            label: 'common.menu.roles',
            href: `${path}?${PARAMETER_TAB}=${ROLES_LIST}`,
            link: ROLES_LIST,
            isHidden: !permissions.isRoleViewEnabled,
        },
        {
            icon: BoardSvgIcon,
            label: 'common.menu.boards',
            href: `${path}?${PARAMETER_TAB}=${BOARDS_LIST}`,
            link: BOARDS_LIST,
            isHidden: !permissions.isOrganizationEditEnabled,
        },
        {
            icon: PasswordSvgIcon,
            label: 'common.menu.publicKeys',
            href: `${path}?${PARAMETER_TAB}=${PUBLIC_KEYS_LIST}`,
            link: PUBLIC_KEYS_LIST,
            isHidden: !permissions.isUserEditEnabled && !permissions.isUserViewEnabled,
            matches: [PUBLIC_KEYS_LIST],
        },
        {
            icon: CaseAccessRequestSvgIcon,
            label: 'common.menu.accessRequests',
            href: `${path}?${PARAMETER_TAB}=${ACCESS_REQUESTS_LIST}`,
            link: ACCESS_REQUESTS_LIST,
            isHidden: !permissions.isCaseViewEnabled,
            badge: createdCaseAccessRequestsNumber,
            matches: [ACCESS_REQUESTS_LIST],
        },
        {
            icon: EnvelopmentSvgIcon,
            label: 'common.menu.mailer',
            href: `${path}?${PARAMETER_TAB}=${TEMPLATES_LIST}`,
            link: TEMPLATES_LIST,
            isHidden: !permissions.isOrganizationEditEnabled,
            matches: [TEMPLATES_LIST],
        },
    ];
};

export const getFormSubItems = (path: string, permissions: PermissionContextType): AgentMenuItemTypes[] => {
    return [
        {
            icon: EnvelopmentSvgIcon,
            label: 'common.menu.invites',
            href: `${path}?${PARAMETER_TAB}=${INVITES_LIST}`,
            link: INVITES_LIST,
            isHidden: !permissions.isInviteViewEnabled,
            matches: [INVITES_LIST, routes.INVITE],
        },
    ];
};

export const getAnalyticsSubItems = (
    path: string,
    dashboards: DashboardItemsTypes[],
    permissions: PermissionContextType,
): AgentMenuItemTypes[] => {
    const result: AgentMenuItemTypes[] = [];
    result.push({
        icon: PanelSvgIcon,
        label: 'common.menu.panels',
        href: `${path}?${PARAMETER_TAB}=${PANELS_LIST}`,
        link: PANELS_LIST,
        isHidden: !permissions.isDashboardViewEnabled,
        matches: [PANELS_LIST],
    });

    dashboards.forEach(item => {
        result.push({
            icon: DashboardSvgIcon,
            label: item.title,
            href: `${routes.DASHBOARD}/${item.uuid}/panels`,
            link: `${routes.DASHBOARD}/${item.uuid}/panels`,
            isHidden: !permissions.isDashboardViewEnabled,
            matches: [DASHBOARD_PAGE, '/panels'],
        });
    });

    return result;
};
