import React, {FC, ReactNode, useContext, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {CircularProgress} from '@material-ui/core';
import Grid from '@mui/material/Grid';

import {RootReducer} from 'appRedux/reducers';
import {MessageTypes} from 'appRedux/actions/requestChat/types';

import {MediaContext} from 'contexts/media/context';

import ChatMessageEncrypt from 'components/RequesterChat/ChatMessageEncrypt';
import ChatMessage from 'components/RequesterChat/ChatMessage';
import {getDateLabel} from 'components/RequesterChat/helper';
import useLoadMessagesList from 'components/RequesterChat/useLoadMessagesList';

import {PARAMETER_MESSAGE_ID} from 'config/index';
import {theme} from 'config/theme';

interface ChatMessagesListType {
    isRequesterChat?: boolean;
}

const ChatMessagesList: FC<ChatMessagesListType> = ({isRequesterChat}) => {
    const [t] = useTranslation();
    const {requestCase} = useParams();
    const [searchParams] = useSearchParams();

    const currentMessageId = searchParams.get(PARAMETER_MESSAGE_ID);

    const {isMobile} = useContext(MediaContext);

    const messagesEndRef = useRef<HTMLDivElement>();
    const isScrolledRef = useRef<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const {
        profile,
        requestCase: {
            currentCase: {isEncryptInfo},
        },
        requestChat: {isLoading, oldestMessageId, allMessagesLoaded},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;
    const userId = get(profile, ['profile', 'id'], null);

    const {messages} = useLoadMessagesList({wrapperRef});

    useEffect(() => {
        if (isScrolledRef.current && messagesEndRef && messagesEndRef.current) {
            if (currentMessageId) return;

            messagesEndRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
            return;
        }

        const scrollDelay = setTimeout(() => {
            if (!currentMessageId && messagesEndRef && messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
                isScrolledRef.current = true;
            }
        }, 750);

        return () => clearTimeout(scrollDelay);
    }, [requestCase, messages.length]);

    const prevHeight = useRef<number>(0);

    useEffect(() => {
        const scrollHeight = wrapperRef.current?.scrollHeight;

        if (!scrollHeight) {
            prevHeight.current = 0;
            return;
        }

        if (scrollContainerRef?.current && prevHeight.current && scrollHeight > prevHeight.current) {
            scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollTop + scrollHeight - prevHeight.current;
        }

        prevHeight.current = scrollHeight;
    }, [oldestMessageId, messages.length]);

    let senderId = 0;
    let firstUnreadMessageId: string | null = null;

    return (
        <Box
            ref={scrollContainerRef}
            sx={{
                overflowY: 'scroll',
                display: 'flex',
                height: `calc(100vh - ${isRequesterChat ? '148px' : '248px'})`,
                flexDirection: 'column-reverse',
                justifyContent: 'flex-start',
                width: '100%',
            }}
        >
            <Grid container component="div" ref={wrapperRef}>
                {isRequesterChat && !isMobile && <Grid item sm={2}></Grid>}
                <Grid component="div" item sm={isRequesterChat && !isMobile ? 8 : 12}>
                    {messages.length === 0 && (
                        <Typography
                            align="center"
                            sx={{
                                m: 2,
                                fontWeight: 600,
                                color: theme.palette.primary.contrastText,
                                fontSize: isMobile ? 11 : 14,
                            }}
                        >
                            {t('orguser.chat.noMessagesYet')}
                        </Typography>
                    )}
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 50,
                        }}
                    >
                        {isLoading && !allMessagesLoaded && <CircularProgress size={35} />}
                    </Box>
                    <Box>
                        {messages.map((message: MessageTypes, messageIndex: number) => {
                            const previousMessageSenderSame = senderId === message.senderId;
                            senderId = message.senderId;

                            let chatMessage: ReactNode;

                            if (isEncryptInfo) {
                                chatMessage = (
                                    <ChatMessageEncrypt
                                        key={`chat-encrypt-${requestCase}-message-${message.uuid}`}
                                        item={message}
                                        userId={userId}
                                        previousMessageSenderSame={previousMessageSenderSame}
                                    />
                                );
                            } else {
                                chatMessage = (
                                    <ChatMessage
                                        key={`chat-${requestCase}-message-${message.uuid}`}
                                        item={message}
                                        caseId={Number(requestCase)}
                                        userId={userId}
                                        previousMessageSenderSame={previousMessageSenderSame}
                                        text={message.text}
                                    />
                                );
                            }

                            if (messageIndex === 0 || message.createdDate !== messages[messageIndex - 1].createdDate)
                                chatMessage = (
                                    <>
                                        <Typography
                                            align="center"
                                            sx={{
                                                m: 2,
                                                fontWeight: 600,
                                                color: theme.palette.primary.contrastText,
                                                fontSize: isMobile ? 11 : 14,
                                            }}
                                        >
                                            {getDateLabel(t, message.createdDate)}
                                        </Typography>
                                        {chatMessage}
                                    </>
                                );

                            if (
                                senderId !== userId &&
                                !firstUnreadMessageId &&
                                (!message.readAt || new Date().getTime() - new Date(message.readAt).getTime() < 5000)
                            ) {
                                firstUnreadMessageId = message.uuid;
                                chatMessage = (
                                    <>
                                        <Box ref={messagesEndRef} sx={{height: 1}} />
                                        {chatMessage}
                                    </>
                                );
                            }

                            return chatMessage;
                        })}
                    </Box>
                </Grid>
                {isRequesterChat && !isMobile && <Grid item sm={2}></Grid>}
            </Grid>
        </Box>
    );
};

export default ChatMessagesList;
