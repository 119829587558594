import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import {LANGUAGE_LIST} from 'appRedux/actions/admin';
import {FORM_INFORMATION, FORMS_LIST} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';
import {GET_ORGANIZATION_LANGUAGES_LIST} from 'appRedux/actions/organizationLanguage';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import UpdateConfigurationForm from 'components/Forms/FormBuilder/ConfigurationForm/UpdateConfigurationForm';
import FormLogoForm from 'components/Forms/FormBuilder/LogoForm/FormLogoForm';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import {formInitialValues} from 'components/Forms/FormBuilder/ConfigurationForm/validation';
import FormSnippet from 'components/Forms/FormBuilder/SnippetForm/SnippetForm';

import FormStatusSwitcher from 'pages/admin/updateForm/partials/FormStatusSwitcher';
import FormLanguages from 'pages/admin/updateForm/partials/FormLanguages/FormLanguages';
import FormPauseSection from 'pages/admin/updateForm/partials/FormPauseSection';
import FormTags from 'pages/admin/updateForm/partials/FormTags/FormTags';
import FormVersionsList from 'pages/admin/updateForm/partials/FormVersions/FormVersionsList';
import FormAllowListComponent from 'pages/admin/updateForm/partials/FormAllowlist/FormAllowListComponent';
import FormHasAdditional from 'pages/admin/updateForm/partials/FormHasAdditional/FormHasAdditional';

import {isOrgAdminUser} from 'helpers/roles';
import {getLanguageById, showLanguageSelector} from 'helpers/translationsHelper';

import {PARAMETER_TAB, routes} from 'config/index';

const UpdateFormComponent: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {id} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {setBackLink, setBackLinkLabel, setPageTitle} = useContext(RouteContext);

    const [formLogoSource, setFormLogoSource] = useState<string | null>(null);

    const getFormInformation = useCallback(
        data => dispatch({type: FORM_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getLanguageList = useCallback(() => dispatch({type: LANGUAGE_LIST.REQUEST}), [dispatch]);

    const getFormsList = useCallback(data => dispatch({type: FORMS_LIST.REQUEST, payload: data}), [dispatch]);

    const getOrganizationLanguages = useCallback(
        data => dispatch({type: GET_ORGANIZATION_LANGUAGES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {formsList, languageList, organizationList, organizationLanguages},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;
    const organizationLanguage = myOrganization ? getLanguageById(languageList, myOrganization.language) : null;

    const formId = Number(id);

    const currentForm = formsList ? formsList.find(item => item.id === formId) : null;

    const initialValues =
        id && formsList ? {...formInitialValues, ...formsList.find(item => item.id === formId)} : formInitialValues;

    const {isSubForm} = initialValues;

    const activeOrganizationLanguages =
        organizationLanguages && organizationLanguage
            ? organizationLanguages.filter(item => item.isActive && item.languageName !== organizationLanguage.name)
            : [];

    useEffect(() => {
        getFormInformation({id});
        getLanguageList();
        getFormsList({});
    }, []);

    useEffect(() => {
        if (myOrganization) {
            getOrganizationLanguages({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    useEffect(() => {
        if (profile) {
            const route = isOrgAdminUser(profile) ? routes.ADMIN : routes.AGENT;
            setBackLink(`${route}?${PARAMETER_TAB}=forms`);
        }
        setBackLinkLabel(t('common.links.backToForms'));
        setPageTitle(t(isSubForm ? 'orguser.forms.updateSubForm' : 'orguser.forms.updateForm'));
    }, [isSubForm]);

    if (!organizationLanguage) return null;

    return (
        <>
            {currentForm && <FormStatusSwitcher currentForm={currentForm} isActive={currentForm.isActive} />}
            {currentForm && currentForm.isPaused && <FormPauseSection currentForm={currentForm} />}
            <ClientSectionWrapper title={t('orguser.forms.formConfiguration')}>
                <Grid container>
                    <Grid item sm={8}>
                        <UpdateConfigurationForm
                            formId={formId}
                            initialValues={initialValues}
                            organizationLanguage={organizationLanguage}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        {!isSubForm && (
                            <FormLogoForm
                                formId={formId}
                                initialValues={initialValues}
                                setFormLogoSource={setFormLogoSource}
                            />
                        )}
                    </Grid>
                </Grid>
            </ClientSectionWrapper>
            {id && myOrganization && currentForm && (
                <Box>
                    {!isSubForm && (
                        <>
                            <ClientSectionWrapper title={t('orguser.forms.formSnippet.formSnippet')}>
                                <FormSnippet logoSource={formLogoSource} currentForm={currentForm} />
                            </ClientSectionWrapper>
                            <ClientSectionWrapper title={t('orguser.forms.formTags')}>
                                <FormTags formId={formId} />
                            </ClientSectionWrapper>
                            <ClientSectionWrapper title={t('orguser.forms.formHasAdditional.formHasAdditional')}>
                                <FormHasAdditional formId={formId} />
                            </ClientSectionWrapper>
                        </>
                    )}
                    {showLanguageSelector(activeOrganizationLanguages, organizationLanguage) && (
                        <ClientSectionWrapper title={t('orguser.forms.formLanguages')}>
                            <FormLanguages
                                formId={formId}
                                formTitle={currentForm.title}
                                organizationLanguage={organizationLanguage}
                                activeOrganizationLanguages={activeOrganizationLanguages}
                            />
                        </ClientSectionWrapper>
                    )}
                    {!isSubForm && myOrganization.isClosed && <FormAllowListComponent />}
                    <ClientSectionWrapper title={t('orguser.forms.formVersions')}>
                        <FormVersionsList />
                    </ClientSectionWrapper>
                </Box>
            )}
        </>
    );
};

export default UpdateFormComponent;
