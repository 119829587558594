import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import PanelCreateForm from 'components/Forms/Dashboard/PanelCreateForm';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

const AddPanelButton: FC = () => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <AgentInverseButton title={t('orguser.analytics.panels.addPanel')} onClick={toggleModal} />
            <ModalWrapper
                isShowModal={isOpened}
                toggleModal={toggleModal}
                title={t('orguser.analytics.panels.addPanel')}
                isWideModal
            >
                <PanelCreateForm closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </>
    );
};

export default AddPanelButton;
