import React, {FC, MouseEvent, useContext, useEffect, useRef, useState, useCallback} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import {useDispatch} from 'react-redux';
import {createPortal} from 'react-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ProgressSvgIcon from 'assets/icons/buttons/ProgressSvgIcon';
import WarningSvgIcon from 'assets/icons/buttons/WarningSvgIcon';
import TasksCompletedSvgIcon from 'assets/icons/buttons/TasksCompletedSvgIcon';

import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {REQUEST_CASE_DRAG_AND_DROP} from 'appRedux/actions/admin';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import TagsPopover from 'components/AgentScreenComponents/_caseBlock/TagsPopover';
import EmptyArea from 'components/EmptyArea';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';
import AvatarWithProgress from 'components/AgentScreenComponents/_caseBlock/AvatarWithProgress';
import RequesterCaseDrawer from 'components/AgentScreenComponents/_caseBlock/RequesterCaseDrawer/RequesterCaseDrawer';
import RequesterCaseContextMenu from 'components/AgentScreenComponents/_caseBlock/RequesterCaseContextMenu';
import ChatButton from 'components/AgentScreenComponents/_caseBlock/ChatButton';
import HasNotApprovedStatusChangeRequestsButton from 'components/HasNotApprovedStatusChangeRequestsButton/HasNotApprovedStatusChangeRequestsButton';

import {getRequesterCaseUserName} from 'pages/agent/listView/helper';

import {checkIsCaseEditEnabled} from 'helpers/permissions';
import {VIEW_MODE_BOARD} from 'helpers/filter';

import {theme, MAIN_CLIENT_COLOR} from 'config/theme';
import {
    PARAMETER_AGENT,
    PARAMETER_PAGE,
    PARAMETER_FORM,
    PARAMETER_SEARCH,
    PARAMETER_TAG,
    DEFAULT_PAGE,
    PARAMETER_VIEW_MODE,
    STATUS_OPENING,
    routes,
} from 'config/index';

interface RequesterCaseBlockType {
    item: RequesterCaseResponseTypes;
    permittedUuids: string[];
    currentUserEmail: string | null;
    pinnedCasesList?: boolean;
    toggleAction?: () => void;
}

const RequesterCaseBlock: FC<RequesterCaseBlockType> = ({
    item,
    permittedUuids,
    pinnedCasesList,
    toggleAction,
    currentUserEmail,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {uuid} = useParams();
    const navigate = useNavigate();

    const ref = useRef<HTMLDivElement>();

    const {
        isPinned,
        caseId,
        formId,
        tags,
        notReadMsg,
        agentEmail,
        lastComment,
        status,
        hasNotApprovedStatusChangingRequest,
        hasRejectedStatusChangingRequest,
        hasNotCompletedTasks,
        statusUuid,
    } = item;

    const {showAlert} = useContext(AlertContext);
    const {
        activeRow,
        setActiveRow,
        isSelectClicked,
        selectedIds,
        setSelectedIds,
        toggleIsUpdated,
        itemsPerPage,
        toggleDragStart,
        dragType,
        setDragType,
        setDraggedCaseInitialStatus,
        setDraggedCasePermittedUuids,
    } = useContext(AdminContext);
    const {isCaseEditEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, formId);

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
    const [anchorHorizontal, setAnchorHorizontal] = useState<number | null>(null);
    const [anchorVertical, setAnchorVertical] = useState<number | null>(null);

    const requesterCaseDragAndDrop = useCallback(
        data => dispatch({type: REQUEST_CASE_DRAG_AND_DROP.REQUEST, payload: data}),
        [dispatch],
    );

    const pageFromUrl = searchParams.get(PARAMETER_PAGE);
    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);
    const modeFromUrl = searchParams.get(PARAMETER_VIEW_MODE) ?? VIEW_MODE_BOARD;

    const clickTimeout = useRef<NodeJS.Timeout>();
    const onBlockClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        switch (event.detail) {
            case 1:
                clickTimeout.current = setTimeout(() => {
                    if (isSelectClicked) {
                        return selectCase();
                    }
                    setActiveRow(caseId);
                }, 200);
                break;
            case 2:
                if (!isSelectClicked) {
                    clearTimeout(clickTimeout.current);
                    setActiveRow(null);
                    navigate(`${routes.REQUESTER_CASE}/${caseId}/form/${formId}`);
                }
                break;
        }
    };

    const onRightClick = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setShowContextMenu(true);
        if (ref && ref.current) {
            const left = event.pageX;
            const top = event.pageY;
            setAnchorHorizontal(left);
            setAnchorVertical(top);
        }
    };

    const selectCase = () => {
        if (selectedIds.some(item => item === caseId)) {
            for (let i = 0, n = selectedIds.length; i < n; i++) {
                if (selectedIds[i] === caseId) {
                    selectedIds.splice(i, 1);
                }
            }
        } else {
            selectedIds.push(caseId);
        }
        setIsChecked(previous => !previous);
        setSelectedIds(selectedIds);
        toggleIsUpdated();
    };

    const onDragStart = () => {
        if (!isEditEnabled) {
            return;
        }
        setDraggedCaseInitialStatus(statusUuid);
        setDraggedCasePermittedUuids(permittedUuids);
        setActiveRow(null);
        toggleDragStart();
    };

    const onDragEnd = () => {
        if (!isEditEnabled) {
            return;
        }
        toggleDragStart();
        if (dragType) {
            const commonParameters = {
                id: caseId,
                showAlert,
                page: DEFAULT_PAGE,
                perPage: Number(pageFromUrl) * itemsPerPage,
                agent: getParameterFromUrl(agentFromUrl),
                form: getParameterFromUrl(formFromUrl),
                tags: getParameterFromUrl(tagsFromUrl),
                search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
                viewMode: modeFromUrl,
            };
            if (dragType !== statusUuid) {
                requesterCaseDragAndDrop({
                    ...commonParameters,
                    workflowStatus: dragType,
                    customBoardUuid: uuid,
                });
            }
        }
        setDragType(null);
    };

    useEffect(() => {
        if (selectedIds.length === 0) {
            setIsChecked(false);
        }

        if (selectedIds.includes(item.caseId)) {
            setIsChecked(true);
        }
    }, [selectedIds, item]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                position: 'relative',
                cursor: 'pointer',
                backgroundColor:
                    isSelectClicked && !isChecked ? `rgb(255, 255, 255, 0.4)` : theme.palette.background.default,

                overflow: 'hidden',
                borderRadius: 2,
                minHeight: 65,
                mt: 'initial',
                mb: 1,
                p: 2,
                pt: 2,
                '&:hover': {
                    backgroundColor: isSelectClicked
                        ? !isChecked
                            ? `rgb(7, 216, 137, 0.15)`
                            : `rgb(7, 216, 137, 0.35)`
                        : theme.palette.background.default,
                },
            }}
            ref={ref}
            onClick={onBlockClick}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onContextMenu={onRightClick}
            draggable={isEditEnabled && status !== STATUS_OPENING}
        >
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    borderLeft: isPinned ? `8px solid ${MAIN_CLIENT_COLOR}` : '',
                    opacity: 0.8,
                }}
            />
            {isEditEnabled &&
                createPortal(
                    <RequesterCaseContextMenu
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                        anchorHorizontal={anchorHorizontal}
                        anchorVertical={anchorVertical}
                        permittedUuids={permittedUuids}
                        item={item}
                        pinnedCasesList={pinnedCasesList}
                        toggleAction={toggleAction}
                    />,
                    document.body,
                )}
            <Box sx={{width: '100%'}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}
                >
                    <Box sx={{width: '80%'}}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                display: 'block',
                                maxWidth: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {getRequesterCaseUserName(t, item, true)}
                        </Typography>
                    </Box>
                    <EmptyArea />
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        {agentEmail === currentUserEmail && isEditEnabled && (
                            <ChatButton caseId={caseId} notReadMsg={notReadMsg} />
                        )}
                        <AvatarWithProgress item={item} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 1,
                    }}
                >
                    <TagsPopover tags={tags} />
                    <EmptyArea />
                    {(hasNotApprovedStatusChangingRequest || hasRejectedStatusChangingRequest) && (
                        <HasNotApprovedStatusChangeRequestsButton
                            title={t(
                                hasRejectedStatusChangingRequest
                                    ? 'orguser.requesterCase.hasRejectedRequests'
                                    : 'orguser.requesterCase.hasNotApprovedStatusChangingRequest',
                            )}
                            icon={hasRejectedStatusChangingRequest ? WarningSvgIcon : ProgressSvgIcon}
                        />
                    )}
                    {hasNotCompletedTasks && (
                        <HasNotApprovedStatusChangeRequestsButton
                            title={t('orguser.requesterCase.hasNotCompletedTasks')}
                            icon={TasksCompletedSvgIcon}
                        />
                    )}
                </Box>
                {lastComment && (
                    <Box sx={{mt: 1}}>
                        <Typography
                            sx={{
                                fontSize: 12,
                                display: 'block',
                                maxWidth: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                cursor: 'pointer',
                            }}
                        >
                            {lastComment}
                        </Typography>
                    </Box>
                )}
            </Box>
            {activeRow === caseId && <RequesterCaseDrawer requesterCase={item} />}
        </Box>
    );
};

export default RequesterCaseBlock;
