import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';

import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TripOriginIcon from '@mui/icons-material/TripOrigin';

import ProgressSvgIcon from 'assets/icons/buttons/ProgressSvgIcon';
import WarningSvgIcon from 'assets/icons/buttons/WarningSvgIcon';

import {REQUEST_CASE_CHANGE_STATUS} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';
import {FormWorkflowStatusTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import HasNotApprovedStatusChangeRequestsButton from 'components/HasNotApprovedStatusChangeRequestsButton/HasNotApprovedStatusChangeRequestsButton';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';
import StatusSwitchButtons from 'pages/agent/requesterPage/formInformation/status/StatusSwitchButtons';

interface ChangeStatusFormType {
    initialStatus: string;
    hasRejectedStatusChangingRequest: boolean;
}

const ChangeStatusForm: FC<ChangeStatusFormType> = ({initialStatus, hasRejectedStatusChangingRequest}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const [requesterStatus, setRequesterStatus] = useState<string>(initialStatus);

    const changeRequesterStatus = useCallback(
        data => dispatch({type: REQUEST_CASE_CHANGE_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const handleStatusChange = (event: SelectChangeEvent) => {
        if (event.target.value) {
            changeRequesterStatus({
                status: event.target.value,
                caseId: Number(caseId),
                showAlert,
                callback: () => {
                    setRequesterStatus(event.target.value as string);
                },
            });
        }
    };

    const {
        admin: {formInfo},
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        setRequesterStatus(initialStatus);
    }, [initialStatus]);

    const statuses: FormWorkflowStatusTypes[] = get(formInfo, 'statuses', []);
    const statusPermittedIds = get(currentCase, 'statusPermittedIds', '');
    const rejectedStatusIds: number[] = get(currentCase, 'rejectedStatusIds', []);

    const filteredStatuses = statuses.filter(status => {
        const {uuid, id} = status;
        return statusPermittedIds?.split(OPTIONS_SEPARATOR).includes(String(id)) || uuid === requesterStatus;
    });

    if (filteredStatuses.length <= 4 || hasRejectedStatusChangingRequest) {
        const currentStatus = filteredStatuses.find(item => item.uuid === requesterStatus);
        return currentStatus ? (
            <StatusSwitchButtons
                title={currentStatus.title}
                color={currentStatus.color}
                filteredStatuses={filteredStatuses}
                currentStatus={requesterStatus}
                hasRejectedStatusChangingRequest={hasRejectedStatusChangingRequest}
            />
        ) : null;
    }
    return (
        <FormControl
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
            fullWidth
        >
            <Select
                name="status"
                variant="standard"
                fullWidth
                value={requesterStatus}
                IconComponent={KeyboardArrowDownIcon}
                onChange={handleStatusChange}
                sx={{
                    '& div': {
                        display: 'flex',
                    },
                }}
                disableUnderline
            >
                {filteredStatuses &&
                    filteredStatuses.map(item => {
                        const {uuid, color, title, id} = item;
                        if (rejectedStatusIds.includes(id)) {
                            return null;
                        }
                        return (
                            <MenuItem
                                key={`status-${uuid}`}
                                value={uuid}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    pl: 1,
                                }}
                            >
                                <TripOriginIcon sx={{color: color}} />
                                <Typography sx={{ml: 1, fontWeight: 600}}>{title}</Typography>
                            </MenuItem>
                        );
                    })}
            </Select>
        </FormControl>
    );
};

export default ChangeStatusForm;
