import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {theme} from 'config/theme';

import {Box} from '@mui/material';

import {DELETE_CHAT_MESSAGE, CHAT_DOCUMENT_URL} from 'appRedux/actions/requestChat';
import {MessageTypes} from 'appRedux/actions/requestChat/types';
import {RootReducer} from 'appRedux/reducers';

import {CaseKeyContext} from 'contexts/caseKey/context';
import {ChatFilesContext} from 'contexts/chatFiles/context';

import {getEncryptedFileData} from 'helpers/cryptoApiHelper';

import ThumbItem from 'components/ClientScreenComponents/ThumbItem';

interface AttachedThumbType {
    item: MessageTypes;
    userId: number | null;
}

const AttachedThumb: FC<AttachedThumbType> = ({item, userId}) => {
    const dispatch = useDispatch();

    const {unwrappedCaseKey} = useContext(CaseKeyContext);
    const {selectFile, filesList, downloadFile} = useContext(ChatFilesContext);

    const {uuid, senderId, file, encryptedPrefix, thumb} = item;
    const isCurrentUserSender = senderId === userId;

    const [thumbLink, setThumbLink] = useState<string | null | undefined>();

    const {
        requestCase: {
            currentCase: {isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const removeChatMessage = useCallback(
        data => dispatch({type: DELETE_CHAT_MESSAGE.REQUEST, payload: data}),
        [dispatch],
    );

    const getFileUrl = useCallback(data => dispatch({type: CHAT_DOCUMENT_URL.REQUEST, payload: data}), [dispatch]);

    useEffect(() => {
        let isMounted = true;
        if (thumbLink) return;

        const downloadEncryptedThumb = async (url: string) => {
            return await getEncryptedFileData(url, 'data:image/jpeg;base64,', unwrappedCaseKey);
        };

        const getThumbLink = async () => {
            if (!thumb || (encryptedPrefix && !unwrappedCaseKey)) return '';
            return encryptedPrefix && isEncryptInfo ? downloadEncryptedThumb(thumb) : thumb;
        };
        getThumbLink()
            .then(link => {
                isMounted && setThumbLink(link);
            })
            .catch(err => {
                console.error(err);
            });

        return () => {
            isMounted = false;
        };
    }, [thumb, encryptedPrefix, unwrappedCaseKey]);

    const downloadEncryptedFile = async (url: string) => {
        if (encryptedPrefix && file) {
            const decryptedFileSource = await getEncryptedFileData(url, encryptedPrefix, unwrappedCaseKey);
            if (decryptedFileSource) {
                downloadFile(decryptedFileSource, file);
            }
        }
    };

    const downloadFileHandler = () => {
        getFileUrl({
            uuid,
            callback: encryptedPrefix ? downloadEncryptedFile : downloadFile,
        });
    };

    const deleteFile = callback => {
        removeChatMessage({
            uuid,
            callback,
        });
    };

    const currentFile = useMemo(() => filesList && filesList.find(file => file.uuid === uuid), [filesList, uuid]);

    return (
        <Box
            data-id={`#thumb-attachment-${uuid}`}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: 1,
                p: 1,
                borderRadius: 2,
                cursor: 'pointer',
                backgroundColor: theme.palette.secondary.contrastText,
                maxWidth: 'fit-content',
            }}
        >
            <ThumbItem
                uuid={uuid}
                currentFile={currentFile}
                selectFile={selectFile}
                isUserOwner={isCurrentUserSender}
                downloadFile={downloadFileHandler}
                deleteFile={deleteFile}
                thumbSrc={thumbLink}
            />
        </Box>
    );
};

export default AttachedThumb;
