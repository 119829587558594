import React, {FC, ReactNode} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DescriptionPopover from 'pages/agent/requesterPage/partials/DescriptionPopover';

interface InformationWrapperType {
    title: string;
    description: string;
    children: ReactNode;
}

const InformationWrapper: FC<InformationWrapperType> = ({children, title, description}) => {
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{fontWeight: 600}}>{title}</Typography>
                {description && description !== '' && <DescriptionPopover text={description} />}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    backgroundColor: `rgba(34, 34, 34, 0.05)`,
                    borderRadius: 2,
                    mt: 1,
                    mb: 2,
                    p: 2,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default InformationWrapper;
