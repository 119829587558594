import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {SET_ERRORS} from 'appRedux/actions/errors';
import {RootReducer} from 'appRedux/reducers';

import mobileMenuLogo from 'assets/images/mobileMenuLogo.png';
import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';
import HelpRequestSvgIcon from 'assets/icons/HelpRequestSvgIcon';
import MainLogo from 'assets/mainLogo';
import AccountSvgIcon from 'assets/icons/topBarMenu/AccountSvgIcon';
import SecuritySvgIcon from 'assets/icons/topBarMenu/SecuritySvgIcon';
import LogoutSvgIcon from 'assets/icons/topBarMenu/LogoutSvgIcon';

import {RouteContext} from 'contexts/route/context';

import AvatarNameAndRole from 'components/TopBarMenu/AvatarNameAndRole';
import EmptyArea from 'components/EmptyArea';

import {theme} from 'config/theme';
import {routes} from 'config/index';

interface ClientMobileMenuType {
    toggleMenu: () => void;
}

const bottomTextStyles = {
    color: theme.palette.background.default,
    fontSize: 18,
};

const ClientMobileMenu: FC<ClientMobileMenuType> = ({toggleMenu}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {onLogoutClicked} = useContext(RouteContext);

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const {
        auth: {organization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const redirectToLink = (link: string) => {
        refreshErrors(null);
        navigate(link);
        toggleMenu();
    };

    const mainMenuItems = [
        {
            visible: true,
            icon: AccountSvgIcon,
            label: t('common.menu.account'),
            onClickAction: () => redirectToLink(routes.ACCOUNT),
        },
        {
            visible: true,
            icon: SecuritySvgIcon,
            label: t('common.menu.security'),
            onClickAction: () => redirectToLink(routes.SECURITY),
        },
        {
            visible: true,
            icon: HelpRequestSvgIcon,
            label: t('common.menu.myCases'),
            onClickAction: () => redirectToLink(routes.REQUESTS_LIST),
        },
        {
            visible: true,
            icon: LogoutSvgIcon,
            label: t('common.menu.logout'),
            onClickAction: () => onLogoutClicked(),
        },
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                overflowX: 'hidden',
                width: '100vw',
                pt: 1,
                pr: 1,
                pl: 4,
                pb: 3,
                height: window.innerHeight,
                backgroundColor: theme.palette.info.main,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    mb: 3,
                }}
            >
                <IconButton onClick={toggleMenu}>
                    <CancelSvgIcon isActive />
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <AvatarNameAndRole isMobileMenu />
            </Box>
            <EmptyArea />
            <Box>
                {mainMenuItems.map(item => {
                    const Icon = item.icon;
                    if (!item.visible) {
                        return null;
                    }
                    return (
                        <Box
                            key={`mainMenu-${item.label}`}
                            onClick={item.onClickAction}
                            sx={{
                                mt: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton sx={{width: 40, height: 40}}>
                                <Icon isActive />
                            </IconButton>
                            <Typography
                                sx={{
                                    ml: 1,
                                    color: theme.palette.background.default,
                                    fontWeight: 600,
                                }}
                            >
                                {item.label}
                            </Typography>
                        </Box>
                    );
                })}
            </Box>
            <EmptyArea />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography sx={{mb: 2, ...bottomTextStyles}}>{t('common.helpRequestFor')}</Typography>
                    <Typography sx={{...bottomTextStyles, fontWeight: 600}}>
                        {organization ? organization.name : 'Herupu'}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <MainLogo isMobileMenu />
                </Grid>
            </Grid>
            <EmptyArea />
            <Grid container>
                <Grid item xs={4}>
                    <img src={mobileMenuLogo} alt="Logo" />
                </Grid>
                <Grid item xs={4}>
                    <Link
                        to={routes.RULE_ROUTE_IMPRINT}
                        style={{textDecoration: 'none', color: theme.palette.background.default}}
                    >
                        <Typography>{t('common.imprint')}</Typography>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link
                        to={routes.RULE_ROUTE_PRIVACY_POLICY}
                        style={{textDecoration: 'none', color: theme.palette.background.default}}
                    >
                        <Typography>{t('common.privacyPolicy')}</Typography>
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClientMobileMenu;
