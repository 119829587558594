import React, {FC, ReactNode, useContext, useLayoutEffect, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AdminContextWrapper from 'contexts/admin';
import {RouteContext} from 'contexts/route/context';
import {MediaContext} from 'contexts/media/context';
import PermissionContextWrapper from 'contexts/permission';

import AgentMenu from 'components/AgentScreenComponents/AgentMenu';
import AgentTopBar from 'components/AgentScreenComponents/AgentTopBar';
import LoadingBottomButton from 'components/AgentScreenComponents/_buttons/LoadingBottomButton';
import AgentAddIconButton from 'components/AgentScreenComponents/_buttons/AgentAddIconButton';

import AgentChatWrapper from 'pages/agent/chat/AgentChatWrapper';

import {theme} from 'config/theme';

interface AgentScreenWrapperType {
    currentTab: string;
    setCurrentTab: (value: string) => void;
    toggleAdminMenu: (value: string) => void;
    children: ReactNode;
}

const AgentScreenWrapper: FC<AgentScreenWrapperType> = ({children, setCurrentTab, toggleAdminMenu, currentTab}) => {
    const [t] = useTranslation();

    const {pageTitle, isRequesterChatPage, addLink, setIsClientMode} = useContext(RouteContext);
    const {isMobile, isTablet, isMiddle} = useContext(MediaContext);

    const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(isMobile || isTablet || isMiddle);

    const toggleMenu = () => {
        setIsMenuCollapsed(previous => !previous);
    };

    useLayoutEffect(() => {
        setIsMenuCollapsed(isMobile || isTablet || isMiddle);
    }, [isMobile, isTablet, isMiddle]);

    useEffect(() => {
        setIsClientMode(false);
    }, []);

    return (
        <PermissionContextWrapper>
            <AdminContextWrapper>
                <LoadingBottomButton />
                <Grid container spacing={0}>
                    <Grid
                        item
                        sm={isMenuCollapsed ? 1 : 3}
                        lg={isMenuCollapsed ? 0.5 : 2}
                        xl={isMenuCollapsed ? 0.4 : 1.5}
                        sx={{
                            position: 'fixed',
                            height: '100vh',
                            width: '100%',
                            overflowY: 'scroll',
                            scrollbarWidth: 'none' /* Firefox */,
                            msOverflowStyle: 'none' /* IE and Edge */,
                            '&::-webkit-scrollbar': {
                                display: 'none' /* Chrome */,
                            },
                        }}
                    >
                        <AgentMenu
                            toggleAdminMenu={toggleAdminMenu}
                            isMenuCollapsed={isMenuCollapsed}
                            toggleMenu={toggleMenu}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={isMenuCollapsed ? 1 : 3}
                        lg={isMenuCollapsed ? 0.5 : 2}
                        xl={isMenuCollapsed ? 0.4 : 1.5}
                    ></Grid>
                    <Grid
                        item
                        sm={isMenuCollapsed ? 11 : 9}
                        lg={isMenuCollapsed ? 11.5 : 10}
                        xl={isMenuCollapsed ? 11.6 : 10.5}
                        sx={{backgroundColor: theme.palette.warning.contrastText}}
                    >
                        <Box sx={{minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
                            <AgentTopBar setCurrentTab={setCurrentTab} />
                            <Box sx={{pl: 6, pr: 4, pb: 3, display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                                {pageTitle && (
                                    <Box sx={{display: 'flex', alignItems: 'center', mb: 4}}>
                                        <Typography
                                            sx={{
                                                fontSize: 40,
                                                fontWidth: 300,
                                                lineHeight: '48px',
                                            }}
                                        >
                                            {t(pageTitle)}
                                        </Typography>
                                        {addLink && (
                                            <Link to={addLink} style={{marginLeft: 24}}>
                                                <AgentAddIconButton />
                                            </Link>
                                        )}
                                    </Box>
                                )}
                                {isRequesterChatPage ? <AgentChatWrapper>{children}</AgentChatWrapper> : children}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </AdminContextWrapper>
        </PermissionContextWrapper>
    );
};

export default AgentScreenWrapper;
