import get from 'lodash/get';
import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    FORM_FIELD_ADD,
    FORM_FIELD_UPDATE,
    FORM_FIELD_COPY,
    FORM_FIELD_DELETE,
    FORM_FIELD_UP,
    FORM_FIELD_DOWN,
    addFormField,
    updateFormField,
    copyFormField,
    deleteFormField,
    formFieldUpPriority,
    formFieldDownPriority,
    FORM_FIELD_CREATE_OPTION,
    createFormFieldOption,
    FORM_FIELD_UPDATE_OPTION,
    updateFormFieldOption,
    FORM_FIELD_DELETE_OPTION,
    deleteFormFieldOption,
    FORM_FIELD_MOVE,
    formFieldMoving,
    FORM_FIELD_ADD_RELATED_SECTION,
    FORM_FIELD_REMOVE_RELATED_SECTION,
} from 'appRedux/actions/forms';
import {
    FormTotalInformationTypes,
    FormAddFieldRequest,
    CommonFormBuilderRequest,
    FormChangeStatusType,
    OptionFileCreateResponseType,
    OptionFileUpdateResponseType,
    OptionFileRequestType,
    FormFieldMoveRequestType,
    FormItemBasicResponseTypes,
    FormFieldAddRelatedSection,
    FormItemPagesResponseTypes,
} from 'appRedux/actions/forms/types';
import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormFieldAdding() {
    while (true) {
        const {
            payload: {id, callback, showAlert, setErrors, ...values},
        }: IActionType<FormAddFieldRequest> = yield take(FORM_FIELD_ADD.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(addFormField.success(data.results));
                callback && data.fieldId && callback(data.fieldId);
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                setErrors && setErrors(data.errors);
                yield put(updateFormField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(addFormField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldUpdating() {
    while (true) {
        const {
            payload: {id, callback, showAlert, setErrors, ...values},
        }: IActionType<FormAddFieldRequest> = yield take(FORM_FIELD_UPDATE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/edit`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormField.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                setErrors && setErrors(data.errors);
                yield put(updateFormField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldDeleting() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<CommonFormBuilderRequest> = yield take(FORM_FIELD_DELETE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteFormField.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldCopying() {
    while (true) {
        const {
            payload: {id, showAlert, ...values},
        }: IActionType<CommonFormBuilderRequest> = yield take(FORM_FIELD_COPY.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/copy`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(copyFormField.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(copyFormField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(copyFormField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldMoving() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<FormFieldMoveRequestType> = yield take(FORM_FIELD_MOVE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/move`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(formFieldMoving.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(formFieldMoving.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formFieldMoving.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldUpPriority() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_FIELD_UP.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/up`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(formFieldUpPriority.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formFieldUpPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formFieldUpPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldDownPriority() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_FIELD_DOWN.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/down`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(formFieldDownPriority.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formFieldDownPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formFieldDownPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldAddRelatedSection() {
    while (true) {
        const {
            payload: {id, showAlert, ...values},
        }: IActionType<FormFieldAddRelatedSection> = yield take(FORM_FIELD_ADD_RELATED_SECTION.REQUEST);
        try {
            const data: FormItemPagesResponseTypes = yield call(http, `option/${id}/relate/section`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(formFieldUpPriority.success(data.pages));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formFieldUpPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formFieldUpPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldDeleteRelatedSection() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_FIELD_REMOVE_RELATED_SECTION.REQUEST);
        try {
            const data: FormItemPagesResponseTypes = yield call(http, `option/${id}/remove/section`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(formFieldUpPriority.success(data.pages));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formFieldUpPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formFieldUpPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldOptionFileCreation() {
    while (true) {
        const {
            payload: {fieldId, title, text, priority, file, callback, showAlert, setErrors},
        }: IActionType<OptionFileCreateResponseType> = yield take(FORM_FIELD_CREATE_OPTION.REQUEST);
        try {
            const formData = new FormData();
            formData.append('priority', String(priority));
            formData.append('title', title);
            formData.append('text', text || '');
            file && formData.append('media', file);

            const data: FormTotalInformationTypes[] = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `field/${fieldId}/option/create`,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            console.log('FORM_FIELD_CREATE_OPTION', data);
            const errors = get(data, 'errors', null);
            if (errors) {
                setErrors && setErrors(errors);
                yield put(createFormFieldOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            } else if (data) {
                const pages = get(data, 'pages', []);
                yield put(createFormFieldOption.success(pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createFormFieldOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createFormFieldOption.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldOptionFileUpdating() {
    while (true) {
        const {
            payload: {uuid, title, text, priority, file, callback, showAlert, setErrors},
        }: IActionType<OptionFileUpdateResponseType> = yield take(FORM_FIELD_UPDATE_OPTION.REQUEST);
        try {
            const formData = new FormData();
            formData.append('priority', String(priority));
            formData.append('title', title);
            formData.append('text', text || '');
            file && formData.append('media', file);

            const data: FormTotalInformationTypes[] = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `field/${uuid}/option/update`,
                {
                    method: 'POST',
                    body: formData,
                },
            );
            const errors = get(data, 'errors', null);
            if (errors) {
                setErrors && setErrors(errors);
                yield put(updateFormFieldOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            } else if (data) {
                const pages = get(data, 'pages', []);
                yield put(updateFormFieldOption.success(pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateFormFieldOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormFieldOption.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldOptionFileDeleting() {
    while (true) {
        const {
            payload: {fieldId, callback, showAlert, setErrors, ...values},
        }: IActionType<OptionFileRequestType> = yield take(FORM_FIELD_DELETE_OPTION.REQUEST);
        try {
            const data: FormTotalInformationTypes[] = yield call(http, `field/${fieldId}/option/delete`, {
                method: 'DELETE',
                body: JSON.stringify(values),
            });
            const errors = get(data, 'errors', null);
            if (errors) {
                setErrors && setErrors(errors);
                yield put(deleteFormFieldOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            } else if (data) {
                const pages = get(data, 'pages', []);
                yield put(deleteFormFieldOption.success(pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormFieldOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(addFormField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormFieldAdding),
    fork(watchFormFieldUpdating),
    fork(watchFormFieldDeleting),
    fork(watchFormFieldCopying),
    fork(watchFormFieldMoving),
    fork(watchFormFieldUpPriority),
    fork(watchFormFieldDownPriority),
    fork(watchFormFieldAddRelatedSection),
    fork(watchFormFieldDeleteRelatedSection),
    fork(watchFormFieldOptionFileCreation),
    fork(watchFormFieldOptionFileUpdating),
    fork(watchFormFieldOptionFileDeleting),
];
