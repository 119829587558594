import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {GridColDef} from '@mui/x-data-grid';

import {LoginAttemptTypes} from 'appRedux/actions/admin/types';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import {DATE_TIME_FORMAT, EMPTY_DATA} from 'config/index';

interface SecureInformationDataGridType {
    itemsList: LoginAttemptTypes[];
}

const SecureInformationDataGrid: FC<SecureInformationDataGridType> = ({itemsList}) => {
    const [t] = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('superadmin.secureInfo.name'),
            minWidth: 200,
            valueGetter: ({row: {firstName, lastName, email}}) =>
                firstName && lastName ? `${lastName}, ${firstName}` : email,
        },
        {
            field: 'email',
            headerName: t('superadmin.secureInfo.email'),
            minWidth: 200,
        },
        {
            field: 'role',
            headerName: t('superadmin.secureInfo.role'),
            minWidth: 150,
            valueGetter: ({value}) => value ?? EMPTY_DATA,
        },
        {
            field: 'isSuccess',
            headerName: t('superadmin.secureInfo.status'),
            width: 80,
            valueGetter: ({value}) =>
                t(value ? 'superadmin.secureInfo.result.success' : 'superadmin.secureInfo.result.failure'),
        },
        {
            field: 'userAgent',
            headerName: t('superadmin.secureInfo.userAgent'),
            width: 300,
        },
        {
            field: 'ip',
            headerName: t('superadmin.secureInfo.IP'),
            width: 100,
        },
        {
            field: 'dateTime',
            headerName: t('superadmin.secureInfo.dateTime'),
            width: 200,
            valueGetter: ({value}) => moment(value).format(DATE_TIME_FORMAT),
        },
    ];

    return (
        <CustomDataGrid
            rows={itemsList}
            columns={columns}
            getRowId={row => row.dateTime}
            checkboxSelection={false}
            disableColumnFilter
            disableSortable
            enablePaging
            paginationMode="server"
        />
    );
};

export default SecureInformationDataGrid;
