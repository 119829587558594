import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';

import herupuHorizontalLogo from 'assets/images/herupuHorizontalLogo.png';
import herupuCollapsedMenuLogo from 'assets/images/herupuCollapsedMenuLogo.png';
import MainLogo from 'assets/mainLogo';

import {RootReducer} from 'appRedux/reducers';
import {AGENT_FILTERS_LIST} from 'appRedux/actions/agentFilters';
import {CHAT_AGENT_CONTACTS} from 'appRedux/actions/requestChat';
import {GET_CASE_ACCESS_REQUESTS} from 'appRedux/actions/crypto';
import {GET_BOARDS_LIST} from 'appRedux/actions/workflow';

import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';

import MenuItemsBlock from 'components/AgentScreenComponents/MenuItemsBlock';
import {
    getSuperAdminBlockItems,
    getSuperAdminSecureBlockItems,
    AgentMenuItemTypes,
    getFirstAgentBlockItems,
    getBoardsItemsBlock,
} from 'components/AgentScreenComponents/helper';
import EmptyArea from 'components/EmptyArea';

import {CASE_ACCESS_REQUEST_CREATED} from 'pages/admin/accessRequests/helper';

import {getLanguageById} from 'helpers/translationsHelper';

import {userRoles, ITEMS_PER_PAGE_BOARD} from 'config/index';
import {theme} from 'config/theme';

interface AgentMenuType {
    toggleAdminMenu: (value: string) => void;
    isMenuCollapsed: boolean;
    toggleMenu: () => void;
}

const AgentMenu: FC<AgentMenuType> = ({toggleAdminMenu, isMenuCollapsed, toggleMenu}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {isSuperAdminPage, isAgentPage, isAdminPage} = useContext(RouteContext);
    const permissions = useContext(PermissionContext);
    const {showAlert} = useContext(AlertContext);

    const getSavedFilters = useCallback(() => dispatch({type: AGENT_FILTERS_LIST.REQUEST}), [dispatch]);

    const getBoardsList = useCallback(data => dispatch({type: GET_BOARDS_LIST.REQUEST, payload: data}), [dispatch]);

    const getChatContacts = useCallback(() => dispatch({type: CHAT_AGENT_CONTACTS.REQUEST}), [dispatch]);

    const getCaseAccessRequests = useCallback(
        data => dispatch({type: GET_CASE_ACCESS_REQUESTS.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {savedFilters, languageList},
        analytics: {dashboards},
        crypto: {caseAccessRequests},
        profile: {profile},
        requestChat: {contacts},
        workflow: {boards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const redirectToHome = () => {
        navigate('/');
    };

    const getMenuItems = () => {
        const roles: string[] = get(profile, 'roles', []);
        if (profile && roles.includes(userRoles.ROLE_SUPER_ADMIN)) {
            const language = getLanguageById(languageList, profile.languageId);
            return (
                <Box sx={{mb: 3, width: '100%'}}>
                    <MenuItemsBlock
                        isMenuCollapsed={isMenuCollapsed}
                        items={getSuperAdminBlockItems(language.name)}
                        toggleAdminMenu={toggleAdminMenu}
                    />
                    <MenuItemsBlock
                        isMenuCollapsed={isMenuCollapsed}
                        items={getSuperAdminSecureBlockItems}
                        toggleAdminMenu={toggleAdminMenu}
                    />
                </Box>
            );
        }

        const createdCaseAccessRequests = caseAccessRequests.filter(
            item => item.status === CASE_ACCESS_REQUEST_CREATED,
        );

        const firstAgentBlockItems: AgentMenuItemTypes[] = getFirstAgentBlockItems(
            get(contacts, [0, 'caseId'], null),
            contacts,
            savedFilters,
            dashboards,
            permissions,
            isAgentPage,
            createdCaseAccessRequests.length,
        );

        const boardsItems: AgentMenuItemTypes[] = boards
            ? getBoardsItemsBlock(boards, ITEMS_PER_PAGE_BOARD, permissions, savedFilters)
            : [];

        if (roles.includes(userRoles.ROLE_AGENT) || roles.includes(userRoles.ROLE_ADMIN)) {
            return (
                <Box sx={{mb: 3, width: '100%'}}>
                    <MenuItemsBlock
                        isMenuCollapsed={isMenuCollapsed}
                        items={firstAgentBlockItems}
                        toggleAdminMenu={toggleAdminMenu}
                    />
                    {boards && boards.length > 0 && (
                        <MenuItemsBlock
                            isMenuCollapsed={isMenuCollapsed}
                            items={boardsItems}
                            toggleAdminMenu={toggleAdminMenu}
                        />
                    )}
                </Box>
            );
        }
        return null;
    };

    useEffect(() => {
        if (!isSuperAdminPage && permissions.isCaseEditEnabled) {
            getSavedFilters();
        }
    }, [permissions.isCaseEditEnabled]);

    useEffect(() => {
        if ((isAgentPage || isAdminPage) && permissions.isCaseViewEnabled) {
            getChatContacts();
            getCaseAccessRequests({
                showAlert,
            });
        }
        if ((isAgentPage || isAdminPage) && permissions.isBoardViewEnabled) {
            getBoardsList({showAlert});
        }
    }, [permissions.isCaseViewEnabled]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: isMenuCollapsed ? 'center' : 'flex-start',
                pt: 2,
                pb: 2,
                position: 'relative',
                minHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 5,
                }}
            >
                {isMenuCollapsed ? (
                    <Box onClick={redirectToHome} sx={{cursor: 'pointer', px: 1}}>
                        <MainLogo navBar />
                    </Box>
                ) : (
                    <Box onClick={redirectToHome} sx={{cursor: 'pointer'}}>
                        <MainLogo navBar />
                    </Box>
                )}
            </Box>
            {getMenuItems()}
            <EmptyArea />
            <EmptyArea />
            {isMenuCollapsed && (
                <img
                    src={herupuCollapsedMenuLogo}
                    alt={'Herupu'}
                    style={{
                        height: 30,
                    }}
                />
            )}
            <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: isMenuCollapsed ? 'center' : 'space-between',
                    width: '100%',
                    pl: isMenuCollapsed ? 2 : 3,
                    pr: isMenuCollapsed ? 2 : 3,
                    pb: 2,
                }}
            >
                {!isMenuCollapsed && (
                    <Box>
                        <Typography sx={{fontSize: 11, mb: 1}}>{t('common.poweredBy')}</Typography>
                        <img
                            src={herupuHorizontalLogo}
                            alt={'Herupu logo'}
                            style={{
                                height: 30,
                                marginLeft: 5,
                                marginRight: 10,
                            }}
                        />
                    </Box>
                )}
                <IconButton
                    data-id="button#menu-toggle-collapse"
                    sx={{
                        backgroundColor: theme.palette.info.main,
                        '&:hover': {backgroundColor: theme.palette.info.main},
                        mt: 3,
                    }}
                    onClick={toggleMenu}
                >
                    {isMenuCollapsed ? (
                        <KeyboardArrowRight sx={{color: theme.palette.background.default}} />
                    ) : (
                        <KeyboardArrowLeft sx={{color: theme.palette.background.default}} />
                    )}
                </IconButton>
            </Box>
        </Box>
    );
};

export default AgentMenu;
