import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import {SelectChangeEvent} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';
import {GET_DRAFT_REMOVES} from 'appRedux/actions/admin';
import {DraftRemoveItemTypes} from 'appRedux/actions/admin/types';
import {ORGANIZATION_LIST} from 'appRedux/actions/organization';
import {FORMS_LIST} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';

import DraftRemovesDataGrid from 'pages/admin/draftRemoves/DraftRemovesDataGrid';
import {getFormVersionsList} from 'pages/agent/tableView/helper';
import {getOrganizationsList} from 'pages/admin/draftRemoves/helper';

import {
    DEFAULT_PAGE,
    PARAMETER_ORGANIZATION,
    PARAMETER_FORM,
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    PARAMETER_SORT_BY,
    PARAMETER_SORT_TYPE,
    ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    PARAMETER_SEARCH,
} from 'config/index';

const DraftRemoves: FC = () => {
    const [t] = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const pageFromUrl = searchParams.get(PARAMETER_PAGE);
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE);
    const organizationFromUrl = searchParams.get(PARAMETER_ORGANIZATION);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const sortByFromUrl = searchParams.get(PARAMETER_SORT_BY);
    const sortTypeFromUrl = searchParams.get(PARAMETER_SORT_TYPE);

    const {
        admin: {formsList, organizationList, draftRemoves},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getOrganizationList = useCallback(() => dispatch({type: ORGANIZATION_LIST.REQUEST}), [dispatch]);

    const getFormsList = useCallback(data => dispatch({type: FORMS_LIST.REQUEST, payload: data}), [dispatch]);

    const getDraftRemovesList = useCallback(
        data => dispatch({type: GET_DRAFT_REMOVES.REQUEST, payload: data}),
        [dispatch],
    );

    const itemsList: DraftRemoveItemTypes[] = get(draftRemoves, 'data', []);
    const itemsNumber = get(draftRemoves, 'count', 0);

    const setSearch = (searchParams: URLSearchParams) => {
        searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        setSearchParams(searchParams);
    };

    const handleSelectParameter = (parameter: string, event: SelectChangeEvent<string>) => {
        searchParams.set(parameter, event.target.value);
        setSearch(searchParams);
    };

    useEffect(() => {
        getOrganizationList();
        getFormsList({});
    }, []);

    useEffect(() => {
        getDraftRemovesList({
            showAlert,
            organization: organizationFromUrl,
            form: formFromUrl,
            page: pageFromUrl,
            perPage: perPageFromUrl,
            search: searchFromUrl,
            sortBy: sortByFromUrl,
            sortType: sortTypeFromUrl,
        });
    }, [organizationFromUrl, formFromUrl, pageFromUrl, perPageFromUrl, searchFromUrl, sortByFromUrl, sortTypeFromUrl]);

    return (
        <DataGridWrapper
            itemsList={itemsList}
            itemsTotalNumber={itemsNumber}
            itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS_DEFAULT}
            filters={[
                {
                    name: 'organization',
                    label: t('superadmin.draftRemoves.organization'),
                    parameter: PARAMETER_ORGANIZATION,
                    options: getOrganizationsList(organizationList ?? []),
                    handleFilterChange: e => handleSelectParameter(PARAMETER_ORGANIZATION, e),
                },
                {
                    name: 'form',
                    label: t('superadmin.draftRemoves.form'),
                    parameter: PARAMETER_FORM,
                    options: getFormVersionsList(formsList ?? []),
                    handleFilterChange: e => handleSelectParameter(PARAMETER_FORM, e),
                },
            ]}
            hasSearch
        >
            <DraftRemovesDataGrid itemsList={itemsList} />
        </DataGridWrapper>
    );
};

export default DraftRemoves;
