import {
    HELP_START,
    CREATE_ANONYMOUS_REQUESTER_CASE,
    LOGIN,
    GET_REDIRECT_URL,
    LOGIN_REDIRECT,
    FORGET_NICKNAME,
    RESET_PASSWORD,
    LOGOUT,
    GET_USER_SESSIONS,
    REMOVE_USER_SESSION,
    FORM_BASE_INFORMATION,
    FORM_BASE_INVITE_INFORMATION,
    ORGANIZATION_BASE_INFORMATION_LANGUAGE,
    ORGANIZATION_BASE_INFORMATION,
    TWO_FACTOR_CONFIRMATION,
    TWO_FACTOR_ACTIVATION,
    TWO_FACTOR_REGENERATION,
    TWO_FACTOR_CODES_DOWNLOAD,
    TWO_FACTOR_FIRST_STEP_PASSED,
    TWO_FACTOR_DEACTIVATE,
    TWO_FACTOR_CANCEL,
    REFRESH_USER_TOKEN,
    RECOVERY_PASSWORD,
    GET_USER_LOGIN_ATTEMPTS,
    OPEN_ID_LOGIN,
    OPEN_ID_AUTH,
    OPEN_ID_REGISTRATION,
    LOAD_KEYS,
} from 'appRedux/actions/auth';
import {
    AuthResponseTypes,
    OrganizationBaseInformationTypes,
    FormBaseInformationTypes,
    UserLoginAttemptsTypes,
    UserSessionTypes,
} from 'appRedux/actions/auth/types';

export const initialState: AuthState = {
    isLoading: false,
    isFormInformationLoading: false,
    isOrganizationInfoLoading: false,
    isOrganizationInfoUpdating: false,
    authData: undefined,
    form: undefined,
    organization: undefined,
    loginAttempts: [],
    userSessions: [],
    error: undefined,
};

export interface AuthState {
    isLoading: boolean;
    isFormInformationLoading: boolean;
    isOrganizationInfoLoading: boolean;
    isOrganizationInfoUpdating: boolean;
    authData: AuthResponseTypes | undefined;
    form: FormBaseInformationTypes | undefined;
    organization: OrganizationBaseInformationTypes | undefined;
    loginAttempts: UserLoginAttemptsTypes[];
    userSessions: UserSessionTypes[];
    error: IErrors | undefined;
}

const auth = (
    state = initialState,
    action: IActionType<AuthResponseTypes | UserLoginAttemptsTypes | UserSessionTypes | IErrors>,
): AuthState => {
    switch (action.type) {
        case HELP_START.REQUEST:
        case CREATE_ANONYMOUS_REQUESTER_CASE.REQUEST:
        case LOGIN.REQUEST:
        case LOGIN_REDIRECT.REQUEST:
        case GET_REDIRECT_URL.REQUEST:
        case LOGOUT.REQUEST:
        case OPEN_ID_LOGIN.REQUEST:
        case OPEN_ID_AUTH.REQUEST:
        case OPEN_ID_REGISTRATION.REQUEST:
        case GET_USER_SESSIONS.REQUEST:
        case REMOVE_USER_SESSION.REQUEST:
        case GET_USER_LOGIN_ATTEMPTS.REQUEST:
        case TWO_FACTOR_CONFIRMATION.REQUEST:
        case TWO_FACTOR_ACTIVATION.REQUEST:
        case TWO_FACTOR_CODES_DOWNLOAD.REQUEST:
        case TWO_FACTOR_REGENERATION.REQUEST:
        case TWO_FACTOR_DEACTIVATE.REQUEST:
        case TWO_FACTOR_CANCEL.REQUEST:
        case FORGET_NICKNAME.REQUEST:
        case RESET_PASSWORD.REQUEST:
        case REFRESH_USER_TOKEN.REQUEST:
        case RECOVERY_PASSWORD.REQUEST:
        case LOAD_KEYS.REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        }

        case FORM_BASE_INFORMATION.REQUEST:
        case FORM_BASE_INVITE_INFORMATION.REQUEST: {
            return {
                ...state,
                isFormInformationLoading: true,
                error: undefined,
            };
        }

        case ORGANIZATION_BASE_INFORMATION.REQUEST: {
            return {
                ...state,
                isOrganizationInfoLoading: true,
                error: undefined,
            };
        }

        case ORGANIZATION_BASE_INFORMATION_LANGUAGE.REQUEST: {
            return {
                ...state,
                isOrganizationInfoUpdating: true,
                error: undefined,
            };
        }

        case OPEN_ID_LOGIN.SUCCESS:
        case LOGOUT.SUCCESS:
        case RECOVERY_PASSWORD.SUCCESS:
        case TWO_FACTOR_FIRST_STEP_PASSED.SUCCESS:
        case REFRESH_USER_TOKEN.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        }

        case GET_USER_SESSIONS.SUCCESS:
        case REMOVE_USER_SESSION.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                userSessions: action.payload as UserSessionTypes[],
                error: undefined,
            };
        }

        case GET_USER_LOGIN_ATTEMPTS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                loginAttempts: action.payload as UserLoginAttemptsTypes[],
                error: undefined,
            };
        }

        case FORM_BASE_INVITE_INFORMATION.SUCCESS:
        case FORM_BASE_INFORMATION.SUCCESS: {
            return {
                ...state,
                isFormInformationLoading: false,
                form: action.payload as FormBaseInformationTypes,
            };
        }

        case ORGANIZATION_BASE_INFORMATION_LANGUAGE.SUCCESS:
        case ORGANIZATION_BASE_INFORMATION.SUCCESS: {
            return {
                ...state,
                isOrganizationInfoLoading: false,
                organization: action.payload as OrganizationBaseInformationTypes,
            };
        }

        case HELP_START.SUCCESS:
        case CREATE_ANONYMOUS_REQUESTER_CASE.SUCCESS: {
            return {
                ...state,
                authData: action.payload as AuthResponseTypes,
                error: undefined,
            };
        }

        case LOGIN.SUCCESS:
        case LOGIN_REDIRECT.SUCCESS:
        case TWO_FACTOR_CONFIRMATION.SUCCESS:
        case OPEN_ID_REGISTRATION.SUCCESS:
        case OPEN_ID_AUTH.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                authData: action.payload as AuthResponseTypes,
                error: undefined,
            };
        }

        case TWO_FACTOR_ACTIVATION.SUCCESS:
        case TWO_FACTOR_DEACTIVATE.SUCCESS:
        case TWO_FACTOR_CANCEL.SUCCESS:
        case TWO_FACTOR_REGENERATION.SUCCESS: {
            const currentAuthData = state.authData;
            return {
                ...state,
                isLoading: false,
                authData: {
                    ...currentAuthData,
                    ...(action.payload as AuthResponseTypes),
                },
                error: undefined,
            };
        }

        case GET_REDIRECT_URL.SUCCESS:
        case TWO_FACTOR_CODES_DOWNLOAD.SUCCESS:
        case FORGET_NICKNAME.SUCCESS:
        case RESET_PASSWORD.SUCCESS:
        case LOAD_KEYS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        }

        case HELP_START.ERROR:
        case CREATE_ANONYMOUS_REQUESTER_CASE.ERROR:
        case LOGIN.ERROR:
        case LOGIN_REDIRECT.ERROR:
        case GET_REDIRECT_URL.ERROR:
        case LOGOUT.ERROR:
        case OPEN_ID_LOGIN.ERROR:
        case OPEN_ID_AUTH.ERROR:
        case OPEN_ID_REGISTRATION.ERROR:
        case GET_USER_SESSIONS.ERROR:
        case REMOVE_USER_SESSION.ERROR:
        case GET_USER_LOGIN_ATTEMPTS.ERROR:
        case TWO_FACTOR_CONFIRMATION.ERROR:
        case TWO_FACTOR_ACTIVATION.ERROR:
        case TWO_FACTOR_CODES_DOWNLOAD.ERROR:
        case TWO_FACTOR_REGENERATION.ERROR:
        case TWO_FACTOR_DEACTIVATE.ERROR:
        case TWO_FACTOR_CANCEL.ERROR:
        case FORGET_NICKNAME.ERROR:
        case RESET_PASSWORD.ERROR:
        case FORM_BASE_INFORMATION.ERROR:
        case FORM_BASE_INVITE_INFORMATION.ERROR:
        case ORGANIZATION_BASE_INFORMATION_LANGUAGE.ERROR:
        case ORGANIZATION_BASE_INFORMATION.ERROR:
        case REFRESH_USER_TOKEN.ERROR:
        case RECOVERY_PASSWORD.ERROR: {
            return {
                ...state,
                isLoading: false,
                isFormInformationLoading: false,
                isOrganizationInfoLoading: false,
                isOrganizationInfoUpdating: false,
                error: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default auth;
