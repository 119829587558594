import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Markdown from 'react-markdown';

import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';

import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';
import {getCurrentRule} from 'pages/start/rule/helper';

import {RULE_TYPE_IMPRINT} from 'config/index';

interface RulePageType {
    type: number;
}

const RulePage: FC<RulePageType> = ({type}) => {
    const [t] = useTranslation();

    const {
        auth: {organization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentRule = getCurrentRule(type, organization);

    return (
        <LoginPageWrapper>
            <Typography sx={{fontSize: 18, fontWeight: 600, mt: 2, mb: 1}}>
                {t(type === RULE_TYPE_IMPRINT ? 'common.imprint' : 'common.privacyPolicy')}
            </Typography>
            {currentRule && <Markdown>{currentRule}</Markdown>}
        </LoginPageWrapper>
    );
};

export default RulePage;
