import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    FORM_HAS_ADDITIONAL_CREATE,
    createFormHasAdditional,
    FORM_HAS_ADDITIONAL_DELETE,
    deleteFormHasAdditional,
} from 'appRedux/actions/forms';
import {
    FormHasAdditionalCreateTypes,
    FormHasAdditionalDeleteTypes,
    FormHasAdditionalResponseType,
} from 'appRedux/actions/forms/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormHasAdditionalCreation() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<FormHasAdditionalCreateTypes> = yield take(FORM_HAS_ADDITIONAL_CREATE.REQUEST);
        try {
            const data: FormHasAdditionalResponseType = yield call(http, `form/additional/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createFormHasAdditional.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(createFormHasAdditional.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createFormHasAdditional.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormHasAdditionalUpdating() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<FormHasAdditionalDeleteTypes> = yield take(FORM_HAS_ADDITIONAL_DELETE.REQUEST);
        try {
            const data: FormHasAdditionalResponseType = yield call(http, `form/additional/${uuid}/remove`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteFormHasAdditional.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(deleteFormHasAdditional.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormHasAdditional.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [fork(watchFormHasAdditionalCreation), fork(watchFormHasAdditionalUpdating)];
