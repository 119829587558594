import React, {FC, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {FORM_FIELD_POPUP_ADD} from 'appRedux/actions/forms';

import FieldForm from 'components/Forms/FormBuilder/FieldForm/Form';
import {formFieldInitialValues} from 'components/Forms/FormBuilder/FieldForm/validation';

import {UPDATE_FIELD_POPUP_KEYWORD} from 'pages/admin/updateForm/partials/constants';

interface CreateFormPopupFieldType {
    popupId: number;
    toggleModal: () => void;
    fieldType?: number | null;
    newItemPriority?: number;
    setModalTitle: (value: string) => void;
    setEntityId: (value: number) => void;
}

const CreateFormPopupField: FC<CreateFormPopupFieldType> = ({
    popupId,
    toggleModal,
    fieldType,
    newItemPriority,
    setModalTitle,
    setEntityId,
}) => {
    const dispatch = useDispatch();

    const submitCreateForm = useCallback(
        data => dispatch({type: FORM_FIELD_POPUP_ADD.REQUEST, payload: data}),
        [dispatch],
    );

    const initialValues = fieldType ? {...formFieldInitialValues, type: fieldType} : formFieldInitialValues;

    const continueEditing = (fieldId: number) => {
        console.log('fieldId', fieldId);
        setModalTitle(UPDATE_FIELD_POPUP_KEYWORD);
        setEntityId(fieldId);
    };

    return (
        <Box>
            <FieldForm
                entityId={popupId}
                newItemPriority={newItemPriority}
                initialValues={initialValues}
                onSubmitAction={submitCreateForm}
                toggleModal={toggleModal}
                continueEditing={continueEditing}
                isPopup
                isCreate
            />
        </Box>
    );
};

export default CreateFormPopupField;
