import React, {FC, useContext, useEffect, useRef} from 'react';
import moment from 'moment';
import {useSearchParams} from 'react-router-dom';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {MessageTypes} from 'appRedux/actions/requestChat/types';

import ChatReadSvgIcon from 'assets/icons/small/ChatReadSvgIcon';
import ChatSentSvgIcon from 'assets/icons/small/ChatSentSvgIcon';

import {RouteContext} from 'contexts/route/context';
import {MediaContext} from 'contexts/media/context';

import TextWrappedLabel from 'components/TextWrappedLabel';
import AttachedDocumentLabel from 'components/RequesterChat/AttachedDocumentLabel';
import AttachedSectionLabel from 'components/RequesterChat/AttachedSectionLabel';
import ChatMessageAvatar from 'components/RequesterChat/ChatMessageAvatar';
import AttachedThumb from 'components/RequesterChat/AttachedThumb';

import {getChatMessageBlockColor, getAvatarAreaWidth} from 'components/RequesterChat/helper';

import {theme} from 'config/theme';
import {PARAMETER_MESSAGE_ID} from 'config/index';

interface ChatMessageType {
    previousMessageSenderSame: boolean;
    userId: number | null;
    caseId: number;
    item: MessageTypes;
    text: string;
    isMessenger?: boolean;
}

const ChatMessage: FC<ChatMessageType> = ({item, caseId, previousMessageSenderSame, isMessenger, userId, text}) => {
    const [searchParams] = useSearchParams();

    const messageRef = useRef<HTMLDivElement>();

    const currentMessageId = searchParams.get(PARAMETER_MESSAGE_ID);

    const {isClientMode} = useContext(RouteContext);
    const {isMobile} = useContext(MediaContext);

    const {senderId, senderName, uuid, readAt, sectionId, file, createdAt, thumb, hasMarkdown} = item;

    const isCurrentUserSender = senderId === userId;

    useEffect(() => {
        if (currentMessageId && currentMessageId === uuid) {
            if (messageRef && messageRef.current) {
                messageRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        }
    }, [currentMessageId]);

    return (
        <Box
            ref={messageRef}
            sx={{
                display: 'flex',
                flexDirection: isCurrentUserSender ? 'row-reverse' : 'row',
                justifyContent: 'flex-start',
                mt: previousMessageSenderSame ? 1 : 2,
                mb: 1,
                ml: 2,
                mr: 2,
            }}
        >
            <Grid
                container
                display={'flex'}
                flexDirection={'row'}
                justifyContent={isCurrentUserSender ? 'flex-end' : 'flex-start'}
                sx={{
                    ml: isCurrentUserSender ? 1 : 'initial',
                }}
            >
                {isCurrentUserSender && (
                    <Grid
                        item
                        xs={isCurrentUserSender ? 2 : 0}
                        sm={getAvatarAreaWidth(isCurrentUserSender, isMessenger)}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        sx={{pr: 2}}
                    >
                        {!previousMessageSenderSame && !isMessenger && !isClientMode && (
                            <ChatMessageAvatar item={item} isCurrentUserSender={isCurrentUserSender} />
                        )}
                    </Grid>
                )}
                <Grid
                    item
                    sx={{
                        width: thumb ? 'fit-content' : isMessenger ? '91.666666%' : '83.333333%',
                        maxWidth: isMessenger ? '91.666666%' : '83.333333%',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: getChatMessageBlockColor(isCurrentUserSender, isMessenger),
                            borderRadius: 2,
                            p: 1,
                            pb: 0.5,
                            maxWidth: '100%',
                            width: !thumb && (sectionId || file || !previousMessageSenderSame) ? '100%' : 'fit-content',
                            ml: isCurrentUserSender ? 'auto' : 0,
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            flexDirection: sectionId || file ? 'column' : 'row',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                flexGrow: sectionId || file ? 1 : 0,
                            }}
                        >
                            {!previousMessageSenderSame && (
                                <Typography
                                    sx={{
                                        mb: 1,
                                        fontWeight: 600,
                                        color: isCurrentUserSender
                                            ? theme.palette.info.main
                                            : theme.palette.background.default,
                                        width: '100%',
                                        textWrap: 'wrap',
                                    }}
                                >
                                    {senderName}
                                </Typography>
                            )}
                            {thumb && <AttachedThumb item={item} userId={userId} />}
                            {hasMarkdown ? (
                                <Box
                                    sx={{
                                        color: isCurrentUserSender
                                            ? theme.palette.info.main
                                            : theme.palette.background.default,
                                        '& p': {
                                            mt: 0,
                                            mb: 1,
                                            fontSize: isMobile ? 12 : 14,
                                            lineHeight: '22px',
                                        },
                                        '& ul': {
                                            mt: 0,
                                            mb: 1,
                                            fontSize: isMobile ? 12 : 14,
                                            lineHeight: '18px',
                                        },
                                        '& li': {
                                            mt: 0,
                                            mb: 1,
                                            fontSize: isMobile ? 12 : 14,
                                            lineHeight: '18px',
                                        },
                                        '& a': {
                                            color: isCurrentUserSender ? 'default' : theme.palette.primary.main,
                                        },
                                    }}
                                >
                                    <Markdown
                                        remarkPlugins={[remarkGfm]}
                                        components={{
                                            a: (props: any) => {
                                                if (!props.href) return null;
                                                return (
                                                    <a href={props.href} target="_blank" rel="noreferrer">
                                                        {props.children}
                                                    </a>
                                                );
                                            },
                                        }}
                                    >
                                        {text}
                                    </Markdown>
                                </Box>
                            ) : (
                                <TextWrappedLabel
                                    text={text}
                                    color={
                                        isCurrentUserSender ? theme.palette.info.main : theme.palette.background.default
                                    }
                                />
                            )}
                            {sectionId && <AttachedSectionLabel item={item} caseId={caseId} />}
                            {file && !thumb && <AttachedDocumentLabel item={item} userId={userId} />}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                flexGrow: 1,
                                alignItems: 'center',
                                mt: 'auto',
                                ml: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    color: isCurrentUserSender
                                        ? theme.palette.primary.contrastText
                                        : theme.palette.background.default,
                                    fontSize: isMobile ? 11 : 12,
                                    mr: 0.5,
                                }}
                            >
                                {moment(createdAt).format('HH:mm')}
                            </Typography>
                            {readAt ? <ChatReadSvgIcon /> : <ChatSentSvgIcon />}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChatMessage;
