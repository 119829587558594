import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {FORM_CHANGE_STATUS} from 'appRedux/actions/forms';
import {FormItemBasic} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import EmptyArea from 'components/EmptyArea';
import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

import PauseReasonModal from 'pages/admin/formsList/PauseReasonModal';
import {isMaximalActiveFormsNumberReached} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';
import ActiveFormsNumberQuotaAlertPopup from 'pages/admin/updateForm/partials/ActiveFormsNumberQuotaAlertPopup';

interface FormStatusSwitcherType {
    currentForm: FormItemBasic;
    isActive: boolean;
}

const FormStatusSwitcher: FC<FormStatusSwitcherType> = ({currentForm, isActive}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {id, isPaused, isSubForm} = currentForm;

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(isActive);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const toggleIsChecked = () => {
        setIsChecked(previous => !previous);
    };

    const changeFormStatus = useCallback(
        data => dispatch({type: FORM_CHANGE_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {organizationList, formsList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const activeForms = formsList ? formsList.filter(item => item.isActive) : [];

    const handleChangeStatus = () => {
        if (myOrganization) {
            if (!isActive && isMaximalActiveFormsNumberReached(myOrganization, activeForms.length + 1, false)) {
                setIsOpened(true);
            } else {
                toggleIsChecked();
                changeFormStatus({
                    id,
                    showAlert,
                    callbackError: toggleIsChecked,
                });
            }
        }
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
            {!isPaused && !isSubForm && (
                <>
                    <PauseReasonModal form={currentForm} />
                    <Typography variant="body2" sx={{fontWeight: 300, ml: 1}}>
                        {t('orguser.forms.formPauseOn')}
                    </Typography>
                </>
            )}
            <EmptyArea />
            <Typography variant="body2" sx={{fontWeight: 300, mr: 2}}>
                {t('orguser.forms.formStatusSwitcher')}
            </Typography>
            <CustomSwitcher
                checked={isChecked}
                onClick={handleChangeStatus}
                disabled={
                    myOrganization && !isActive
                        ? isMaximalActiveFormsNumberReached(myOrganization, activeForms.length + 1, true)
                        : false
                }
            />
            <ActiveFormsNumberQuotaAlertPopup
                formId={Number(id)}
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                toggleIsOpened={toggleIsOpened}
                toggleIsChecked={toggleIsChecked}
                changeFormStatus={changeFormStatus}
            />
        </Box>
    );
};

export default FormStatusSwitcher;
