import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';

import {FormHasAdditionalTypes} from 'appRedux/actions/forms/types';

import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import FormHasAdditionalDeleteButton from 'pages/admin/updateForm/partials/FormHasAdditional/FormHasAdditionalDeleteButton';

interface FormHasAdditionalItemType {
    item: FormHasAdditionalTypes;
}

const FormHasAdditionalItem: FC<FormHasAdditionalItemType> = ({item}) => {
    const [t] = useTranslation();

    const {title, description, isSubForm} = item;

    return (
        <TableRow>
            <TableCell width={250}>
                <Typography>
                    {t(
                        isSubForm
                            ? 'orguser.forms.formHasAdditional.types.subForm'
                            : 'orguser.forms.formHasAdditional.types.additionalForm',
                    )}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>{title}</Typography>
            </TableCell>
            <TableCell>
                <MarkdownWithShowAll text={description} />
            </TableCell>
            <TableCell>
                <FormHasAdditionalDeleteButton item={item} />
            </TableCell>
        </TableRow>
    );
};

export default FormHasAdditionalItem;
