import React, {FC, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {GET_ALL_REQUESTER_CASES} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';
import {RequesterAllCaseItemTypes} from 'appRedux/actions/requestCase/types';

import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';

import RequesterCaseItem from 'pages/client/requests/partials/RequesterCaseItem';

import {theme} from 'config/theme';

const ClientStartPage: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {
        profile,
        requestCase: {allCases, isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getAllRequesterCases = useCallback(
        data => dispatch({type: GET_ALL_REQUESTER_CASES.REQUEST, payload: data}),
        [dispatch],
    );

    const userId = get(profile, ['profile', 'id'], null);

    useEffect(() => {
        if (userId) {
            getAllRequesterCases({
                id: userId,
            });
        }
    }, []);

    return (
        <ClientScreenWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                    mb: 1,
                }}
            >
                <Typography align="center" sx={{fontSize: 24, mb: 2}}>
                    {t('requester.casesList.yourCases')}
                </Typography>
            </Box>
            {Object.entries(allCases).length === 0 && (
                <Box sx={{width: '100%', mt: 2}}>
                    <Typography align="center" sx={{fontWeight: 600}}>
                        {t(isLoading ? 'common.pleaseWait' : 'requester.casesList.userNotHaveCases')}{' '}
                    </Typography>
                </Box>
            )}
            {Object.entries(allCases).map((item: any, index: number) => {
                const organizationName = item[0];
                const requesterCases: RequesterAllCaseItemTypes[] = item[1];
                return (
                    <Box key={`organization-${index}`} sx={{mb: 3}}>
                        <Typography
                            align="center"
                            sx={{
                                m: 2,
                                fontWeight: 600,
                                fontSize: 18,
                                color: theme.palette.primary.contrastText,
                            }}
                        >
                            {organizationName}
                        </Typography>
                        {Object.values(requesterCases).map((item: RequesterAllCaseItemTypes, caseIndex: number) => {
                            return <RequesterCaseItem key={`form-box-${caseIndex}`} requesterCase={item} />;
                        })}
                    </Box>
                );
            })}
        </ClientScreenWrapper>
    );
};

export default ClientStartPage;
