import React from 'react';
import moment from 'moment';
import {TFunction} from 'i18next';
import {useSelector} from 'react-redux';

import {GridColDef} from '@mui/x-data-grid';
import {Box} from '@mui/material';

import PinSvgIcon from 'assets/icons/buttons/PinSvgIcon';

import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {RootReducer} from 'appRedux/reducers';

import {PermissionContextType} from 'contexts/permission/context';

import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import TagsPopover from 'components/AgentScreenComponents/_caseBlock/TagsPopover';
import ChatButton from 'components/AgentScreenComponents/_caseBlock/ChatButton';
import {TableBodyTypography} from 'components/AgentScreenComponents/_form/CustomElements';

import {TABLE_WIDTH_FORM, TABLE_WIDTH_PINNED, TABLE_WIDTH_USERNAME} from 'pages/agent/tableView/helper';
import FullNameWithTooltip from 'pages/agent/tableView/FullNameWithTooltip';
import {getAgentName, getRequesterCaseUserName} from 'pages/agent/listView/helper';

import {checkIsCaseEditEnabled} from 'helpers/permissions';
import {getFormKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {DATE_TIME_FORMAT} from 'config/index';

interface GetRequestersColumnsType {
    t: TFunction;
    currentUserEmail: string | null;
    permissions: PermissionContextType;
    isLargeDesktop: boolean;
    items: RequesterCaseResponseTypes[];
}

const getRequestersColumns = ({
    t,
    currentUserEmail,
    permissions,
    isLargeDesktop,
    items,
}: GetRequestersColumnsType): GridColDef[] => {
    const {isCaseEditEnabled, filtersCaseEditAllowed} = permissions;

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return [
        {
            field: 'isPinned',
            headerName: '',
            width: TABLE_WIDTH_PINNED,
            renderCell: ({value}) => value && <PinSvgIcon />,
            sortable: false,
        },
        {
            field: 'lastName',
            headerName: t('orguser.requesterCase.requesterName'),
            type: 'singleSelect',
            valueOptions: [...new Set(items.map(item => getRequesterCaseUserName(t, item)))],
            valueGetter: ({row}) => getRequesterCaseUserName(t, row),
            minWidth: TABLE_WIDTH_USERNAME,
            renderCell: ({row, value}) => (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <AvatarImage
                        size={32}
                        avatarLink={row.avatarLink}
                        avatarType={row.avatarType}
                        avatarTitle={getAvatarTitle(row.firstName, row.lastName, row.email)}
                    />
                    <FullNameWithTooltip name={value} />
                </Box>
            ),
        },
        {
            field: 'workflowTitle',
            headerName: t('orguser.forms.workflow'),
            width: 150,
            sortable: true,
        },
        {
            field: 'statusTitle',
            headerName: t('orguser.requesterCase.status'),
            valueOptions: [...new Set(items.map(({status}) => status))],
            width: 150,
            sortable: true,
        },
        {
            field: 'agentName',
            headerName: t('orguser.requesterCase.agent'),
            type: 'singleSelect',
            valueOptions: [
                ...new Set(
                    items.map(item =>
                        item.agentEmail ? getAgentName(t, item) : t('orguser.requesterCase.notAssigned'),
                    ),
                ),
            ],
            valueGetter: ({row}) => (row.agentEmail ? getAgentName(t, row) : t('orguser.requesterCase.notAssigned')),
            minWidth: 200,
            renderCell: ({row, value}) =>
                row.agentEmail ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <AvatarImage
                            size={32}
                            avatarLink={row.agentAvatarLink}
                            avatarType={row.agentAvatarType}
                            avatarTitle={getAvatarTitle(row.agentFirstName, row.agentLastName, row.agentEmail)}
                        />
                        <FullNameWithTooltip name={value} />
                    </Box>
                ) : (
                    <TableBodyTypography text={value} />
                ),
        },
        {
            field: 'formTitle',
            headerName: t('orguser.requesterCase.form'),
            type: 'singleSelect',
            valueOptions: [
                ...new Set(
                    items.map(item => {
                        const formTitleKeyword = getFormKeyword(item.formId, 'title');
                        return getFormTranslatedLabel(translations, formTitleKeyword, item.formTitle);
                    }),
                ),
            ],
            valueGetter: ({value, row}) => {
                const formTitleKeyword = getFormKeyword(row.formId, 'title');
                return getFormTranslatedLabel(translations, formTitleKeyword, value);
            },
            width: TABLE_WIDTH_FORM,
        },
        ...(isLargeDesktop
            ? [
                  {
                      field: 'tags',
                      headerName: t('orguser.requesterCase.tags.tags'),
                      align: 'center',
                      headerAlign: 'center',
                      minWidth: 60,
                      flex: 0.5,
                      renderCell: ({value}) => {
                          return <TagsPopover tags={value} />;
                      },
                      sortable: false,
                  } as GridColDef,
              ]
            : []),
        {
            field: 'updatedAt',
            headerName: t('orguser.requesterCase.updatedAt'),
            minWidth: 70,
            valueGetter: ({value}) => moment(value).format(DATE_TIME_FORMAT),
        },
        {
            field: 'action',
            headerName: t('orguser.requesterCase.action'),
            align: 'center',
            sortable: false,
            width: 70,
            renderCell: ({row}) => {
                const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, row.formId);

                return (
                    row.agentEmail === currentUserEmail &&
                    isEditEnabled && <ChatButton caseId={row.caseId} notReadMsg={row.notReadMsg} />
                );
            },
        },
    ];
};

export default getRequestersColumns;
