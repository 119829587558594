import {requestTypeCreator} from 'helpers/index';
import {MessagesListResponseTypes, SendMessageRequestTypes, MessagesListRequestTypes} from './types';

export const SEND_MESSAGE = requestTypeCreator('SEND_MESSAGE');
export const GET_MESSAGES_LIST = requestTypeCreator('GET_MESSAGES_LIST');

export const sendMessage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: SEND_MESSAGE.ERROR,
    }),
    request: (payload: SendMessageRequestTypes): IActionType<SendMessageRequestTypes> => ({
        payload,
        type: SEND_MESSAGE.REQUEST,
    }),
    success: (payload: MessagesListResponseTypes): IActionType<MessagesListResponseTypes> => ({
        payload,
        type: SEND_MESSAGE.SUCCESS,
    }),
};

export const getMessagesList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_MESSAGES_LIST.ERROR,
    }),
    request: (payload: MessagesListRequestTypes): IActionType<MessagesListRequestTypes> => ({
        payload,
        type: GET_MESSAGES_LIST.REQUEST,
    }),
    success: (payload: MessagesListResponseTypes): IActionType<MessagesListResponseTypes> => ({
        payload,
        type: GET_MESSAGES_LIST.SUCCESS,
    }),
};
