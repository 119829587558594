import get from 'lodash/get';

import {OrganizationBaseInformationTypes} from 'appRedux/actions/auth/types';

import {RULE_TYPE_IMPRINT} from 'config/index';

export const getCurrentRule = (type: number, organization?: OrganizationBaseInformationTypes): string | null => {
    const ruleType = type === RULE_TYPE_IMPRINT ? 'imprint' : 'privacyPolicy';
    return get(organization, [ruleType, 'text'], null);
};
