import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import moment from 'moment';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {REQUEST_CASE_ARCHIVE} from 'appRedux/actions/requestCase';

import {CaseKeyContext} from 'contexts/caseKey/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import FilesListWithGallery from 'components/FilesListWithGallery/FilesListWithGallery';
import {getAllUserFiles} from 'components/FilesListWithGallery/helper';

import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';
import DownloadArchiveButton from 'pages/agent/requesterPage/partials/DownloadArchiveButton';

import {DATE_TIME_FORMAT} from 'config/index';

const AttachedFiles: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const [filesList, setFilesList] = useState<CommonFileType[]>([]);

    const {
        requestCase: {currentCase, allCases},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const downloadArchive = useCallback(
        data => dispatch({type: REQUEST_CASE_ARCHIVE.REQUEST, payload: data}),
        [dispatch],
    );

    const downloadAllFiles = (blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Requester case #${currentCase.id} - ${moment().format(DATE_TIME_FORMAT)}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const onClickArchiveDownload = () => {
        downloadArchive({
            id: caseId,
            callback: downloadAllFiles,
        });
    };

    useEffect(() => {
        const timer = setTimeout(async () => {
            const allFiles: CommonFileType[] = await getAllUserFiles(currentCase, unwrappedCaseKey);
            setFilesList(allFiles);
        }, 200);
        return () => clearTimeout(timer);
    }, [caseId, currentCase]);

    if (filesList.length === 0) {
        return (
            <Box>
                <Typography>{t('orguser.requesterCase.documents.noFiles')}</Typography>
            </Box>
        );
    }
    return (
        <Box>
            <FilesListWithGallery caseId={Number(caseId)} allFiles={filesList} isAttachedFilesList />
            <Box sx={{mt: 2}}>
                {currentCase.isEncryptInfo ? (
                    <DownloadArchiveButton />
                ) : (
                    <AgentInverseButton
                        title={t('orguser.requesterCase.documents.downloadArchive')}
                        onClick={onClickArchiveDownload}
                    />
                )}
            </Box>
        </Box>
    );
};

export default AttachedFiles;
