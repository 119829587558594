import {TFunction} from 'react-i18next';

import {WorkflowStatusTaskItemTypes, WorkflowTaskEmailTemplatesItemTypes} from 'appRedux/actions/workflow/types';

import {MdxEditorCustomButtonType} from 'components/MdxEditorField/helper';

export const WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING = 0;
export const WORKFLOW_STATUS_TASK_TYPE_CHANGE_STATUS = 1;

export interface WorkflowTaskTypes {
    type: number;
    label: string;
    period: number;
}

export const workflowTasksTypes: WorkflowTaskTypes[] = [
    {
        type: WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING,
        label: 'orguser.workflows.tasks.types.sendEmail',
        period: 2,
    },
    {
        type: WORKFLOW_STATUS_TASK_TYPE_CHANGE_STATUS,
        label: 'orguser.workflows.tasks.types.changeStatus',
        period: 14,
    },
];

export const getWorkflowTaskLabel = (type: number): string => {
    const currentResult = workflowTasksTypes.find(item => item.type === type);
    return currentResult ? currentResult.label : '';
};

export const getWorkflowTaskLabelDetails = (
    t: TFunction,
    item: WorkflowStatusTaskItemTypes,
    selectedLanguage: number,
): string | null => {
    const {type, period, templates, newStatusTitle, isForAgent, isForOther, isForRequester} = item;
    const periodLabel = t('orguser.workflows.tasks.periodDays').replace('{DAYS}', String(period));
    if (type === WORKFLOW_STATUS_TASK_TYPE_CHANGE_STATUS && newStatusTitle) {
        return `${t('orguser.workflows.workflowStatusParameters.status')}: ${newStatusTitle} (${periodLabel})`;
    }
    if (type === WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING && templates.length > 0) {
        const templateForCurrentLanguage = templates
            ? templates.find(template => template.language === selectedLanguage)
            : null;
        if (templateForCurrentLanguage) {
            const {subject} = templateForCurrentLanguage;
            const forLabel = getTemplateForLabel(t, isForAgent, isForOther, isForRequester);
            const subjectLabel = `${t('orguser.workflows.tasks.emailTemplate.subject')}: ${
                subject ? subject : t('common.none')
            }`;
            return `${forLabel}, ${subjectLabel} (${periodLabel})`;
        }
        return periodLabel;
    }
    return null;
};

export const getTemplateForLabel = (
    t: TFunction,
    isForAgent: boolean,
    isForOther: boolean,
    isForRequester: boolean,
): string => {
    if (isForRequester && isForAgent && isForOther) {
        return t('orguser.workflows.tasks.forRequesterAgentOther');
    }
    if (isForRequester && isForAgent) {
        return t('orguser.workflows.tasks.forRequesterAgent');
    }
    if (isForRequester && isForOther) {
        return t('orguser.workflows.tasks.forRequesterOther');
    }
    if (isForAgent && isForOther) {
        return t('orguser.workflows.tasks.forAgentOther');
    }
    if (isForAgent) {
        return t('orguser.workflows.tasks.isForAgent');
    }
    if (isForOther) {
        return t('orguser.workflows.tasks.isForOther');
    }
    if (isForRequester) {
        return t('orguser.workflows.tasks.isForRequester');
    }
    return '';
};

export const getWorkflowTaskDaysPeriod = (type: number): number => {
    const currentResult = workflowTasksTypes.find(item => item.type === type);
    return currentResult ? currentResult.period : 0;
};

export const customButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.requesterCase.name',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.requesterCase.caseLink',
        markdown: '{{ url }}',
    },
    {
        title: 'orguser.requesterCase.caseLabel',
        markdown: '{{ caseLabel }}',
    },
    {
        title: 'orguser.requesterCase.status',
        markdown: '{{ statusTitle }}',
    },
];
