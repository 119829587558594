import React, {FC, useCallback} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import {RootReducer} from 'appRedux/reducers';
import {SET_ERRORS} from 'appRedux/actions/errors';

import TopBarMenu from 'components/TopBarMenu/TopBarMenu';
import EmailNotVerified from 'components/ClientScreenComponents/EmailNotVerified';
import CryptoKeysAccessNeeded from 'components/ClientScreenComponents/CryptoKeysAccessNeeded';
import SessionTimer from 'components/SessionTimer/SessionTimer';
import EmptyArea from 'components/EmptyArea';

import {theme} from 'config/theme';
import {routes, DEFAULT_TOP_BAR_LABEL} from 'config/index';

const MENU_BUTTON_SIZE = 45;

const TopBar: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        profile,
        auth: {organization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const isUserVerified = get(profile, ['profile', 'isVerify'], false);

    const redirectToCasesList = () => {
        refreshErrors(null);
        navigate(`${routes.REQUESTS_LIST}`);
    };

    return (
        <AppBar position="sticky">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: theme.palette.info.main,
                    height: 60,
                    pl: 4,
                    pr: 5,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        alignItems: 'center',
                        gap: 3,
                    }}
                >
                    <IconButton
                        data-id="button@topbar-left-menu"
                        sx={{
                            width: MENU_BUTTON_SIZE,
                            height: MENU_BUTTON_SIZE,
                        }}
                        onClick={redirectToCasesList}
                    >
                        <MenuIcon sx={{color: theme.palette.background.default}} fontSize="large" />
                    </IconButton>
                    <Typography
                        variant="body1"
                        sx={{
                            color: theme.palette.background.default,
                            fontWeight: 600,
                        }}
                    >
                        {organization?.name ?? DEFAULT_TOP_BAR_LABEL}
                    </Typography>
                </Box>
                <EmptyArea />
                <SessionTimer inverse />
                <TopBarMenu />
            </Box>
            {!isUserVerified && <EmailNotVerified />}
            <CryptoKeysAccessNeeded />
        </AppBar>
    );
};

export default TopBar;
