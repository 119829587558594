import React, {FC, useCallback, useContext, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import {UpdateEmailRequestType} from 'appRedux/actions/profile/types';
import {EMAIL_UPDATE_REQUEST} from 'appRedux/actions/profile';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import LoadingStartButton from 'components/StartScreenComponents/LoadingStartButton';
import ClientFormikTextInput from 'components/StartScreenComponents/ClientFormTextInput';

import {validationSchema} from 'pages/start/emailUpdate/validation';

import {preventSendForm} from 'helpers/index';

import {routes} from 'config/index';

const EmailUpdateForm: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {hash} = useLocation();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);
    const {onLogoutClicked} = useContext(RouteContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const emailUpdateRequest = useCallback(
        data => dispatch({type: EMAIL_UPDATE_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

    const toggleShowNewPassword = () => {
        setShowNewPassword(previous => !previous);
    };

    const toggleShowRepeatPassword = () => {
        setShowRepeatPassword(previous => !previous);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Box sx={{width: '100%'}}>
            <Typography align={'center'} sx={{mb: 2, fontWeight: 600}}>
                {t('common.emailUpdateForm.emailUpdate')}
            </Typography>
            <Formik
                initialValues={{
                    nickname: '',
                    newEmail: '',
                    password: {
                        first: '',
                        second: '',
                    },
                    code: String(hash).replace('#', ''),
                }}
                validationSchema={() => validationSchema(t)}
                onSubmit={(values, {resetForm}) => {
                    emailUpdateRequest({
                        ...values,
                        showAlert,
                        callback: () => {
                            resetForm();
                            if (profile) {
                                onLogoutClicked();
                            } else {
                                navigate(routes.START);
                            }
                        },
                    });
                }}
            >
                {(formik: FormikProps<UpdateEmailRequestType>) => {
                    return (
                        <Form onKeyDown={preventSendForm}>
                            <Field
                                name="nickname"
                                label={t('common.emailUpdateForm.nickname')}
                                placeholder={t('common.emailUpdateForm.nickname')}
                                component={ClientFormikTextInput}
                            />
                            <Field
                                name="newEmail"
                                label={t('common.emailUpdateForm.newEmail')}
                                placeholder={t('common.emailUpdateForm.newEmail')}
                                component={ClientFormikTextInput}
                            />
                            <Field
                                type={showNewPassword ? 'text' : 'password'}
                                name="password.first"
                                label={t('common.emailUpdateForm.password')}
                                placeholder={t('common.emailUpdateForm.password')}
                                component={ClientFormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Field
                                type={showRepeatPassword ? 'text' : 'password'}
                                name="password.second"
                                label={t('common.emailUpdateForm.repeatPassword')}
                                placeholder={t('common.emailUpdateForm.repeatPassword')}
                                component={ClientFormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowRepeatPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box sx={{mt: 2}}>
                                <LoadingStartButton label={t('common.buttons.update')} />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default EmailUpdateForm;
