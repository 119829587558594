import React, {FC, useState, MouseEvent} from 'react';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import {RequesterCaseTagTypes} from 'appRedux/actions/requestCase/types';

import TagChip from 'components/TagChip/TagChip';

import {theme} from 'config/theme';

interface TagsPopoverType {
    tags: RequesterCaseTagTypes[];
}

const TagsPopover: FC<TagsPopoverType> = ({tags}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Box
                sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {tags.map((item: RequesterCaseTagTypes, index: number) => {
                    return (
                        <Box
                            key={`tags-${index}`}
                            sx={{
                                width: 10,
                                height: 10,
                                borderRadius: 5,
                                mr: 1,
                                mb: 1,
                                backgroundColor: item.color,
                            }}
                        ></Box>
                    );
                })}
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    pointerEvents: 'none',
                }}
                onClose={handlePopoverClose}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.palette.background.default,
                        p: 2,
                        pb: 1,
                    }}
                >
                    {tags.map((item: RequesterCaseTagTypes, index: number) => {
                        const {id, tag, color} = item;
                        return (
                            <TagChip
                                key={`tag-chip-${index}`}
                                id={String(id)}
                                title={tag}
                                color={color}
                                hasOpacity={true}
                            />
                        );
                    })}
                </Box>
            </Popover>
        </>
    );
};

export default TagsPopover;
