import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';
import {OrganizationAdminCreateTypes, UserHasRolesTypes} from 'appRedux/actions/organizationAdmin/types';
import {ORGANIZATION_ADMIN_CREATE} from 'appRedux/actions/organizationAdmin';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import OrganizationAdminForm from 'components/Forms/OrganizationAdminForm/OrganizationAdminForm';
import AddOrganizationUserAlertContent from 'components/OrganizationAdminsList/AddOrganizationUserAlertContent';

import {isMaximalOrganizationUsersNumberReached} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';

interface AddOrganizationAdminModalType {
    organizationId: number;
    closeModal: () => void;
}

const AddOrganizationAdminModal: FC<AddOrganizationAdminModalType> = ({organizationId, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isSuperAdminPage} = useContext(RouteContext);

    const [userHasRoles, setUserHasRoles] = useState<UserHasRolesTypes[]>([]);

    const {
        admin: {organizationAdminList, organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const onSubmitClicked = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_CREATE.REQUEST, payload: data}),
        [dispatch],
    );

    const showForm =
        myOrganization && !isMaximalOrganizationUsersNumberReached(myOrganization, organizationAdminList.length, true);

    return (
        <>
            {isSuperAdminPage || showForm ? (
                <OrganizationAdminForm
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                    }}
                    userHasRoles={userHasRoles}
                    setUserHasRoles={setUserHasRoles}
                    onSubmitClicked={(values: OrganizationAdminCreateTypes) =>
                        onSubmitClicked({
                            ...values,
                            userHasRoles,
                            id: organizationId,
                            setErrors: values.setErrors,
                            callback: closeModal,
                            showAlert,
                        })
                    }
                />
            ) : (
                <AddOrganizationUserAlertContent closeModal={closeModal} />
            )}
        </>
    );
};

export default AddOrganizationAdminModal;
