import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import moment from 'moment';

import Box from '@mui/material/Box';

import {GET_ORGANIZATIONS_STATISTIC} from 'appRedux/actions/organization';

import {RouteContext} from 'contexts/route/context';
import {AlertContext} from 'contexts/alert/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import SendOrganizationStatisticEmailButton from 'pages/admin/dashboard/organizationStatistic/SendOrganizationStatisticEmailButton';
import OrganizationStatisticDataGrid from 'pages/admin/dashboard/organizationStatistic/OrganizationStatisticDataGrid';

import {DATE_TIME_FORMAT} from 'config/index';

const SuperAdminDashboard: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {setTopBarTitle, setPageTitle, setBackLinkLabel, setBackLink, setBackTab} = useContext(RouteContext);

    const getMonthStatistic = useCallback(
        data => dispatch({type: GET_ORGANIZATIONS_STATISTIC.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        setPageTitle('');
        setBackLinkLabel('');
        setBackLink('');
        setBackTab('');
        setTopBarTitle('');
    }, []);

    const downloadFile = (blob: Blob | string) => {
        if (typeof blob === 'string') return;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Export organizations statistic ${moment().format(DATE_TIME_FORMAT)}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const onMonthOrganizationStatisticClick = () => {
        getMonthStatistic({
            showAlert,
            callback: downloadFile,
        });
    };

    return (
        <ClientSectionWrapper title={t('superadmin.dashboard.statisticPerMonth')}>
            <AgentSaveButton
                title="Sentry test"
                isSubmit={false}
                onClick={() => {
                    throw new Error('Sentry Test Error');
                }}
            />
            <OrganizationStatisticDataGrid />
            <Box sx={{mt: 3}}>
                <AgentSaveButton
                    title={t('superadmin.dashboard.generateReport')}
                    isSubmit={false}
                    onClick={onMonthOrganizationStatisticClick}
                />
                <SendOrganizationStatisticEmailButton />
            </Box>
        </ClientSectionWrapper>
    );
};

export default SuperAdminDashboard;
