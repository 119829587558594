import React, {FC, useCallback, useContext, useEffect} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';

import MessagesSvgIcon from 'assets/icons/menu/MessagesSvgIcon';
import LockSvgIcon from 'assets/icons/buttons/LockSvgIcon';
import NoEditSvgIcon from 'assets/icons/buttons/NoEditSvgIcon';
import SendMailSvgIcon from 'assets/icons/buttons/SendMailSvgIcon';
import AddFileSvgIcon from 'assets/icons/buttons/AddFileSvgIcon';
import NotificationSvgIcon from 'assets/icons/buttons/NotificationSvgIcon';
import AnonymousSvgIcon from 'assets/icons/buttons/UserRoleSvgIcon';

import {GET_WORKFLOWS_LIST} from 'appRedux/actions/workflow';
import {WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {FormConfigurationRequestTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {formValidationSchema} from 'components/Forms/FormBuilder/ConfigurationForm/validation';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import CheckboxIconButton from 'components/AdminScreenComponents/CheckboxIconButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import UpdateFormWorkflowButton from 'components/Forms/FormBuilder/ConfigurationForm/UpdateFormWorkflowButton';

interface ConfigurationFormType {
    initialValues: FormConfigurationRequestTypes;
    onSubmitClicked: (values: FormConfigurationRequestTypes) => void;
    isLoading: boolean;
    isCreate?: boolean;
}

const ConfigurationForm: FC<ConfigurationFormType> = ({initialValues, isLoading, isCreate, onSubmitClicked}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {organizationList},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const {isActive, isLocked} = initialValues;

    useEffect(() => {
        if (myOrganization) {
            getWorkflowsList({
                organizationId: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization, isCreate]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() => formValidationSchema(t)}
            enableReinitialize
            onSubmit={(values, {setErrors}) => {
                onSubmitClicked({
                    ...values,
                    setErrors,
                });
            }}
        >
            {(formik: FormikProps<FormConfigurationRequestTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.forms.title')}
                                component={FormikTextInput}
                            />
                        </Box>
                        {isLocked && (
                            <Alert severity={'error'} sx={{mt: 2, mb: 1}}>
                                <Typography>{t('orguser.forms.formIsLocked')}</Typography>
                            </Alert>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <FormControl sx={{mt: 1}} required fullWidth>
                                <InputLabel>{t('orguser.forms.workflow')}</InputLabel>
                                <Select
                                    label={t('orguser.forms.workflow')}
                                    name={'workflow'}
                                    value={formik.values.workflow}
                                    onChange={formik.handleChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    disabled={!isCreate}
                                    fullWidth
                                >
                                    {organizationWorkflows &&
                                        organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                                            return (
                                                <MenuItem key={`workflow-${index}`} value={item.id}>
                                                    <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                            {!isCreate && (
                                <UpdateFormWorkflowButton
                                    currentWorkflowId={Number(initialValues.workflow)}
                                    isLocked={isLocked}
                                />
                            )}
                        </Box>
                        <Box sx={{mt: 1}}>
                            <MdxEditorField
                                name="description"
                                value={formik.values.description ? String(formik.values.description) : ''}
                                label={t('orguser.forms.description')}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="url"
                                disabled={isActive}
                                placeholder={t('orguser.forms.formUrl')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="handlePrefix"
                                placeholder={t('orguser.forms.handlePrefix')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="formButtonText"
                                placeholder={t('orguser.forms.requestHelp')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <MdxEditorField
                                name="privacyPolice"
                                value={formik.values.privacyPolice ? String(formik.values.privacyPolice) : ''}
                                label={t('orguser.forms.privacyPolicy')}
                            />
                        </Box>
                        <Box sx={{mt: 2, display: 'flex', gap: 2}}>
                            <Field
                                type="checkbox"
                                name="isChatActivated"
                                label={t('orguser.forms.activateChatOnDefault')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        Icon={MessagesSvgIcon}
                                        title={t('orguser.forms.defaultChat')}
                                    />
                                )}
                            />
                            <Field
                                type="checkbox"
                                name="isEmailsEnabled"
                                label={t('orguser.forms.isEmailEnabled')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        Icon={NotificationSvgIcon}
                                        title={t('orguser.forms.email')}
                                    />
                                )}
                            />
                            <Field
                                type="checkbox"
                                name="isAutoChatEnabled"
                                label={t('orguser.forms.isChatMailingEnabled')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        Icon={NotificationSvgIcon}
                                        title={t('orguser.forms.chat')}
                                    />
                                )}
                            />
                            {myOrganization && myOrganization.isEncryptionPossible && (
                                <Field
                                    type="checkbox"
                                    name="isEncryptInfo"
                                    label={t('orguser.forms.isEncryptInfo')}
                                    component={props => (
                                        <CheckboxIconButton
                                            {...props}
                                            Icon={LockSvgIcon}
                                            title={t('orguser.forms.encryption')}
                                        />
                                    )}
                                />
                            )}
                            {myOrganization && myOrganization.isMultipleRequestPossible && (
                                <Field
                                    type="checkbox"
                                    name="isMultipleRequestPossible"
                                    label={t('orguser.forms.multipleRequestPossible')}
                                    component={props => (
                                        <CheckboxIconButton
                                            {...props}
                                            Icon={AddFileSvgIcon}
                                            title={t('orguser.forms.multipleRequests')}
                                        />
                                    )}
                                />
                            )}
                            {myOrganization && myOrganization.isInviteRegistration && (
                                <Field
                                    type="checkbox"
                                    name="isOnlyInvite"
                                    label={t('orguser.forms.registrationByInvite')}
                                    component={props => (
                                        <CheckboxIconButton
                                            {...props}
                                            Icon={SendMailSvgIcon}
                                            title={t('orguser.forms.inviteOnly')}
                                        />
                                    )}
                                />
                            )}
                            <Field
                                type="checkbox"
                                name="noEditForm"
                                label={t('orguser.forms.requestNotEditableAfterSubmit')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        Icon={NoEditSvgIcon}
                                        title={t('orguser.forms.notEditable')}
                                    />
                                )}
                            />
                            <Field
                                type="checkbox"
                                name="isAnonymousRequestPossible"
                                label={t('orguser.forms.isAnonymousRequestPossible')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        disabled={!isCreate}
                                        Icon={AnonymousSvgIcon}
                                        title={t('orguser.forms.anonymous')}
                                    />
                                )}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton
                                isSubmit
                                isLoading={isLoading}
                                title={t(isCreate ? 'orguser.forms.addNewForm' : 'common.buttons.saveButton')}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ConfigurationForm;
