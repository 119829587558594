import React, {FC, useContext} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';
import {AdminContext} from 'contexts/admin/context';

import TopBarMenu from 'components/TopBarMenu/TopBarMenu';
import EmptyArea from 'components/EmptyArea';
import {AGENT_TOP_BAR_HEIGHT} from 'components/AgentScreenComponents/helper';
import ActiveCasesTopBar from 'components/AgentScreenComponents/_caseBlock/ActiveCasesTopBar';
import ActiveCasesFilterPanel from 'components/AgentScreenComponents/_caseBlock/ActiveCasesFilterPanel';
import BackLinkLabel from 'components/AgentScreenComponents/_topBar/BackLinkLabel';
import ProfileNotification from 'components/AgentScreenComponents/_topBar/ProfileNotification';
import SessionTimer from 'components/SessionTimer/SessionTimer';
import Notifications from 'components/Notifications/Notifications';

import {theme} from 'config/theme';

interface AgentTopBarType {
    setCurrentTab: (value: string) => void;
}

const AgentTopBar: FC<AgentTopBarType> = ({setCurrentTab}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const {key} = location;

    const {showFiltersPanel} = useContext(AdminContext);
    const {
        isSuperAdminPage,
        backLink,
        backLinkLabel,
        backTab,
        topBarTitle,
        topBarSubtitle,
        isBoardCasesPage,
        isBoardListCasesPage,
    } = useContext(RouteContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onLinkClick = () => {
        if (backTab) {
            setCurrentTab(backTab);
        } else if (key !== 'default') {
            navigate(-1);
        }
    };

    if (!profile) return null;

    const {isVerify, isTwoFactorActivated, secretCodeExpiredTime} = profile;

    return (
        <>
            <Box
                sx={{
                    top: 0,
                    position: 'sticky',
                    zIndex: 100,
                    backgroundColor: theme.palette.warning.contrastText,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: backLinkLabel || topBarTitle ? 'space-between' : 'flex-end',
                    alignItems: 'center',
                    pl: 3,
                    pr: 3,
                    pt: 2,
                    pb: 2,
                    minHeight: AGENT_TOP_BAR_HEIGHT,
                }}
            >
                <Box sx={{mr: 1}}>
                    {backLink && backLinkLabel ? (
                        <Link to={backLink} style={{textDecoration: 'none'}} onClick={onLinkClick}>
                            <BackLinkLabel backLinkLabel={backLinkLabel} onLinkClick={onLinkClick} />
                        </Link>
                    ) : backLinkLabel && key !== 'default' ? (
                        <BackLinkLabel backLinkLabel={backLinkLabel} onLinkClick={onLinkClick} />
                    ) : null}
                </Box>
                {topBarTitle && (
                    <Typography
                        sx={{
                            ml: 3,
                            fontSize: 24,
                        }}
                    >
                        {topBarTitle}
                    </Typography>
                )}
                {topBarSubtitle && <Typography sx={{ml: 3, fontSize: 12}}>{topBarSubtitle}</Typography>}
                <EmptyArea />
                {(isBoardCasesPage || isBoardListCasesPage) && <ActiveCasesTopBar />}
                <EmptyArea />
                <EmptyArea />
                <SessionTimer />
                {(!isVerify || (!isTwoFactorActivated && secretCodeExpiredTime)) && <ProfileNotification />}
                {!isSuperAdminPage && <Notifications />}
                <TopBarMenu />
            </Box>
            {showFiltersPanel && (isBoardCasesPage || isBoardListCasesPage) && <ActiveCasesFilterPanel />}
        </>
    );
};

export default AgentTopBar;
