import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';
import {
    GET_REQUESTER_CASE_COMMENTS,
    ADD_REQUESTER_CASE_COMMENT,
    REMOVE_REQUESTER_CASE_COMMENT,
    UPDATE_REQUESTER_CASE_COMMENT,
} from 'appRedux/actions/comments';

import {
    RequesterCaseTypes,
    RequesterAllCaseTypes,
    RequesterCaseItemType,
    RequesterCasesStatisticTypes,
    RequesterCaseTagTypes,
    RequesterCaseActivitiesTypes,
    RequesterCaseAttachmentsForArchiveTypes,
    CaseWatcherTypes,
    RequesterCaseApproveTypes,
    InviteAdditionalFormTypes,
    SubFormRequestTypes,
    CaseHasTasksTypes,
} from 'appRedux/actions/requestCase/types';
import {
    GET_ALL_REQUESTER_CASES,
    CREATE_REQUESTER_CASE,
    UPDATE_REQUESTER_CASE,
    UPDATE_CHECKED_SECTIONS,
    GET_REQUESTER_CASE,
    GET_REQUESTER_CASES_STATISTIC,
    UPLOAD_REQUESTER_DOCUMENT,
    DELETE_REQUESTER_DOCUMENT,
    REQUESTER_PREPARED_STATUS,
    REQUEST_CASE_CHAT_ACTIVATE,
    REQUEST_CASE_PINNING,
    REQUEST_CASE_ASSIGN_AGENT,
    REQUEST_CASE_CHANGE_STATUS,
    REQUEST_CASE_ADD_TAG,
    REQUEST_CASE_REMOVE_TAG,
    REQUEST_CASE_ARCHIVE,
    REQUEST_CASE_QUESTION,
    REQUEST_CASE_ACTIVITIES,
    REQUEST_CASE_ACTIVITIES_EXPORT,
    REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE,
    CREATE_CASE_WATCHER,
    DELETE_CASE_WATCHER,
    REQUESTER_CASE_EMAIL_REQUEST,
    EDIT_REQUESTER_DOCUMENT,
    UPLOAD_REQUESTER_SIGNATURE,
    UPDATE_STATUS_CHANGE_APPROVE_REQUEST,
    UNSET_STATUS_CHANGE_APPROVE_REQUEST,
    DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS,
    DELETE_REQUESTER_SIGNATURE,
    CREATE_ADDITIONAL_FORM_INVITE,
    DELETE_ADDITIONAL_FORM_INVITE,
    CREATE_SUBFORM_REQUEST,
    SUBMIT_SUBFORM_REQUEST,
    DELETE_SUBFORM_REQUEST,
    IGNORE_CASE_HAS_TASK,
} from 'appRedux/actions/requestCase';

import {
    UPDATE_CLIENT_TRANSLATION,
    DELETE_CLIENT_TRANSLATION,
    AUTO_CLIENT_TRANSLATION,
    UPDATE_CLIENT_TRANSLATION_ITEM,
} from 'appRedux/actions/clientTranslation';
import {ClientTranslationTypes} from 'appRedux/actions/clientTranslation/types';

import {RequesterDocumentResponseType} from 'appRedux/actions/profile/types';

export interface RequestCaseState {
    isLoading: boolean;
    isCaseLoading: boolean;
    isCaseUpdateLoading: boolean;
    isEmailRequestLoading: boolean;
    allCases: RequesterAllCaseTypes[];
    requesterCasesStatistic: RequesterCasesStatisticTypes[];
    currentCase: RequesterCaseTypes;
    caseAttachments: RequesterCaseAttachmentsForArchiveTypes[];
    error: IErrors | undefined;
}

export const initialState: RequestCaseState = {
    isLoading: false,
    isCaseLoading: false,
    isCaseUpdateLoading: false,
    isEmailRequestLoading: false,
    allCases: [],
    requesterCasesStatistic: [],
    currentCase: {
        id: null,
        userName: null,
        userEmail: null,
        agentName: null,
        agentEmail: null,
        readAt: 0,
        updatedAt: 0,
        createdAt: 0,
        deletedAt: 0,
        status: '',
        statusColor: '#6EC181',
        statusUuid: '',
        statusInitial: false,
        statusTitle: '',
        statusPermittedIds: '',
        formId: null,
        formTitle: '',
        formTags: [],
        formPrefix: '',
        result: {},
        isAssigned: false,
        isFormChatActivated: false,
        isEncryptInfo: false,
        noEditForm: false,
        isChatActivatedByAgent: false,
        isPinned: false,
        isAnonymousAccount: false,
        hasNotApprovedStatusRequestChanges: false,
        hasRejectedStatusChangingRequest: false,
        rejectedStatusIds: [],
        notReadMessages: 0,
        chatFiles: [],
        tags: [],
        approves: [],
        checkedSections: [],
        files: [],
        signatures: [],
        comments: [],
        activities: [],
        translations: [],
        watchers: [],
        invites: [],
        subFormRequests: [],
        tasks: [],
        caseLabel: '',
    },
    caseAttachments: [],
    error: undefined,
};

const requestCase = (
    state = initialState,
    action: IActionType<
        | RequesterCaseTypes
        | RequesterAllCaseTypes[]
        | RequesterCasesStatisticTypes[]
        | string[]
        | RequesterDocumentResponseType[]
        | RequesterCaseTagTypes[]
        | RequesterCaseCommentsTypes[]
        | RequesterCaseActivitiesTypes[]
        | ClientTranslationTypes[]
        | CaseWatcherTypes[]
        | RequesterCaseApproveTypes[]
        | RequesterCaseAttachmentsForArchiveTypes[]
        | InviteAdditionalFormTypes[]
        | SubFormRequestTypes[]
        | CaseHasTasksTypes[]
        | IErrors
    >,
): RequestCaseState => {
    switch (action.type) {
        case GET_ALL_REQUESTER_CASES.REQUEST:
        case GET_REQUESTER_CASES_STATISTIC.REQUEST:
        case CREATE_REQUESTER_CASE.REQUEST:
        case UPDATE_CHECKED_SECTIONS.REQUEST:
        case UPLOAD_REQUESTER_DOCUMENT.REQUEST:
        case EDIT_REQUESTER_DOCUMENT.REQUEST:
        case UPLOAD_REQUESTER_SIGNATURE.REQUEST:
        case DELETE_REQUESTER_SIGNATURE.REQUEST:
        case DELETE_REQUESTER_DOCUMENT.REQUEST:
        case REQUESTER_PREPARED_STATUS.REQUEST:
        case REQUEST_CASE_ASSIGN_AGENT.REQUEST:
        case REQUEST_CASE_CHANGE_STATUS.REQUEST:
        case REQUEST_CASE_CHAT_ACTIVATE.REQUEST:
        case REQUEST_CASE_PINNING.REQUEST:
        case REQUEST_CASE_ADD_TAG.REQUEST:
        case REQUEST_CASE_REMOVE_TAG.REQUEST:
        case REQUEST_CASE_ARCHIVE.REQUEST:
        case REQUEST_CASE_QUESTION.REQUEST:
        case REQUEST_CASE_ACTIVITIES.REQUEST:
        case REQUEST_CASE_ACTIVITIES_EXPORT.REQUEST:
        case GET_REQUESTER_CASE_COMMENTS.REQUEST:
        case ADD_REQUESTER_CASE_COMMENT.REQUEST:
        case UPDATE_REQUESTER_CASE_COMMENT.REQUEST:
        case REMOVE_REQUESTER_CASE_COMMENT.REQUEST:
        case CREATE_CASE_WATCHER.REQUEST:
        case DELETE_CASE_WATCHER.REQUEST:
        case UPDATE_CLIENT_TRANSLATION.REQUEST:
        case UPDATE_CLIENT_TRANSLATION_ITEM.REQUEST:
        case DELETE_CLIENT_TRANSLATION.REQUEST:
        case AUTO_CLIENT_TRANSLATION.REQUEST:
        case REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.REQUEST:
        case CREATE_ADDITIONAL_FORM_INVITE.REQUEST:
        case DELETE_ADDITIONAL_FORM_INVITE.REQUEST:
        case CREATE_SUBFORM_REQUEST.REQUEST:
        case SUBMIT_SUBFORM_REQUEST.REQUEST:
        case DELETE_SUBFORM_REQUEST.REQUEST:
        case UPDATE_STATUS_CHANGE_APPROVE_REQUEST.REQUEST:
        case UNSET_STATUS_CHANGE_APPROVE_REQUEST.REQUEST:
        case DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS.REQUEST:
        case IGNORE_CASE_HAS_TASK.REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case UPDATE_REQUESTER_CASE.REQUEST: {
            return {
                ...state,
                isCaseUpdateLoading: true,
            };
        }

        case GET_REQUESTER_CASE.REQUEST: {
            return {
                ...state,
                isLoading: true,
                isCaseLoading: true,
            };
        }

        case REQUESTER_CASE_EMAIL_REQUEST.REQUEST: {
            return {
                ...state,
                isEmailRequestLoading: true,
            };
        }

        case REQUEST_CASE_ACTIVITIES_EXPORT.SUCCESS:
        case REQUEST_CASE_ARCHIVE.SUCCESS:
        case REQUEST_CASE_QUESTION.SUCCESS:
        case CREATE_REQUESTER_CASE.SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case REQUESTER_CASE_EMAIL_REQUEST.SUCCESS: {
            return {
                ...state,
                isEmailRequestLoading: false,
            };
        }

        case GET_ALL_REQUESTER_CASES.SUCCESS:
            return {
                ...state,
                isLoading: false,
                allCases: action.payload as RequesterAllCaseTypes[],
            };

        case GET_REQUESTER_CASES_STATISTIC.SUCCESS:
            return {
                ...state,
                isLoading: false,
                requesterCasesStatistic: action.payload as RequesterCasesStatisticTypes[],
            };

        case REQUEST_CASE_ASSIGN_AGENT.SUCCESS:
        case REQUEST_CASE_CHAT_ACTIVATE.SUCCESS:
        case REQUEST_CASE_PINNING.SUCCESS:
        case REQUEST_CASE_CHANGE_STATUS.SUCCESS:
        case UPDATE_STATUS_CHANGE_APPROVE_REQUEST.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    ...(action.payload as RequesterCaseTypes),
                },
            };
        }

        case GET_REQUESTER_CASE.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                isCaseLoading: false,
                currentCase: {
                    ...currentCase,
                    ...(action.payload as RequesterCaseTypes),
                },
            };
        }

        case REQUESTER_PREPARED_STATUS.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    result: action.payload as RequesterCaseItemType,
                },
            };
        }

        case UPDATE_REQUESTER_CASE.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isCaseUpdateLoading: false,
                currentCase: {
                    ...currentCase,
                    result: action.payload as RequesterCaseItemType,
                },
            };
        }

        case UPDATE_CHECKED_SECTIONS.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    checkedSections: action.payload as string[],
                },
            };
        }

        case REQUEST_CASE_ADD_TAG.SUCCESS:
        case REQUEST_CASE_REMOVE_TAG.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    tags: action.payload as RequesterCaseTagTypes[],
                },
            };
        }

        case CREATE_CASE_WATCHER.SUCCESS:
        case DELETE_CASE_WATCHER.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    watchers: action.payload as CaseWatcherTypes[],
                },
            };
        }

        case UNSET_STATUS_CHANGE_APPROVE_REQUEST.SUCCESS:
        case DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    approves: action.payload as RequesterCaseApproveTypes[],
                },
            };
        }

        case EDIT_REQUESTER_DOCUMENT.SUCCESS:
        case DELETE_REQUESTER_DOCUMENT.SUCCESS:
        case UPLOAD_REQUESTER_DOCUMENT.SUCCESS:
        case UPLOAD_REQUESTER_SIGNATURE.SUCCESS:
        case DELETE_REQUESTER_SIGNATURE.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    ...(action.payload as RequesterDocumentResponseType[]),
                },
            };
        }

        case GET_REQUESTER_CASE_COMMENTS.SUCCESS:
        case ADD_REQUESTER_CASE_COMMENT.SUCCESS:
        case UPDATE_REQUESTER_CASE_COMMENT.SUCCESS:
        case REMOVE_REQUESTER_CASE_COMMENT.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    comments: action.payload as RequesterCaseCommentsTypes[],
                },
            };
        }

        case REQUEST_CASE_ACTIVITIES.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    activities: action.payload as RequesterCaseActivitiesTypes[],
                },
            };
        }

        case AUTO_CLIENT_TRANSLATION.SUCCESS:
        case DELETE_CLIENT_TRANSLATION.SUCCESS:
        case UPDATE_CLIENT_TRANSLATION_ITEM.SUCCESS:
        case UPDATE_CLIENT_TRANSLATION.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    translations: action.payload as ClientTranslationTypes[],
                },
            };
        }

        case CREATE_SUBFORM_REQUEST.SUCCESS:
        case SUBMIT_SUBFORM_REQUEST.SUCCESS:
        case DELETE_SUBFORM_REQUEST.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    subFormRequests: action.payload as SubFormRequestTypes[],
                },
            };
        }

        case CREATE_ADDITIONAL_FORM_INVITE.SUCCESS:
        case DELETE_ADDITIONAL_FORM_INVITE.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    invites: action.payload as InviteAdditionalFormTypes[],
                },
            };
        }

        case IGNORE_CASE_HAS_TASK.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    tasks: action.payload as CaseHasTasksTypes[],
                },
            };
        }

        case REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                caseAttachments: action.payload as RequesterCaseAttachmentsForArchiveTypes[],
            };
        }

        case GET_ALL_REQUESTER_CASES.ERROR:
        case GET_REQUESTER_CASE.ERROR:
        case GET_REQUESTER_CASES_STATISTIC.ERROR:
        case CREATE_REQUESTER_CASE.ERROR:
        case UPDATE_CHECKED_SECTIONS.ERROR:
        case UPLOAD_REQUESTER_DOCUMENT.ERROR:
        case EDIT_REQUESTER_DOCUMENT.ERROR:
        case UPLOAD_REQUESTER_SIGNATURE.ERROR:
        case DELETE_REQUESTER_SIGNATURE.ERROR:
        case DELETE_REQUESTER_DOCUMENT.ERROR:
        case REQUEST_CASE_CHAT_ACTIVATE.ERROR:
        case REQUEST_CASE_CHANGE_STATUS.ERROR:
        case REQUEST_CASE_ASSIGN_AGENT.ERROR:
        case REQUEST_CASE_ARCHIVE.ERROR:
        case REQUESTER_PREPARED_STATUS.ERROR:
        case UPDATE_REQUESTER_CASE.ERROR:
        case REQUEST_CASE_ADD_TAG.ERROR:
        case REQUEST_CASE_QUESTION.ERROR:
        case REQUEST_CASE_ACTIVITIES.ERROR:
        case REQUEST_CASE_ACTIVITIES_EXPORT.ERROR:
        case GET_REQUESTER_CASE_COMMENTS.ERROR:
        case REQUEST_CASE_PINNING.ERROR:
        case ADD_REQUESTER_CASE_COMMENT.ERROR:
        case UPDATE_REQUESTER_CASE_COMMENT.ERROR:
        case REMOVE_REQUESTER_CASE_COMMENT.ERROR:
        case CREATE_CASE_WATCHER.ERROR:
        case DELETE_CASE_WATCHER.ERROR:
        case UPDATE_CLIENT_TRANSLATION.ERROR:
        case UPDATE_CLIENT_TRANSLATION_ITEM.ERROR:
        case DELETE_CLIENT_TRANSLATION.ERROR:
        case AUTO_CLIENT_TRANSLATION.ERROR:
        case REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.ERROR:
        case REQUEST_CASE_REMOVE_TAG.ERROR:
        case CREATE_ADDITIONAL_FORM_INVITE.ERROR:
        case DELETE_ADDITIONAL_FORM_INVITE.ERROR:
        case CREATE_SUBFORM_REQUEST.ERROR:
        case SUBMIT_SUBFORM_REQUEST.ERROR:
        case DELETE_SUBFORM_REQUEST.ERROR:
        case UPDATE_STATUS_CHANGE_APPROVE_REQUEST.ERROR:
        case UNSET_STATUS_CHANGE_APPROVE_REQUEST.ERROR:
        case DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS.ERROR:
        case REQUESTER_CASE_EMAIL_REQUEST.ERROR:
        case IGNORE_CASE_HAS_TASK.ERROR: {
            return {
                ...state,
                isLoading: false,
                isEmailRequestLoading: false,
                isCaseUpdateLoading: false,
                error: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default requestCase;
