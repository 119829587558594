import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik, FormikProps} from 'formik';
import i18n from 'i18next';
import {useLocation, useParams} from 'react-router-dom';
import get from 'lodash/get';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {SelectChangeEvent} from '@mui/material';

import {LANGUAGE_LIST} from 'appRedux/actions/admin';
import {
    FORM_BASE_INFORMATION,
    ORGANIZATION_BASE_INFORMATION_LANGUAGE,
    FORM_BASE_INVITE_INFORMATION,
} from 'appRedux/actions/auth';
import {SET_LANGUAGE} from 'appRedux/actions/profile';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {RootReducer} from 'appRedux/reducers';
import {SetLanguageFormPropsTypes} from 'appRedux/actions/profile/types';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import {LOCAL_STORAGE_CURRENT_LANGUAGE} from 'services/localStorage';

import {preventSendForm} from 'helpers/index';
import {getLanguageById} from 'helpers/translationsHelper';

export interface SetLanguagePropsTypes {
    color?: string;
    isFormRegistrationPage?: boolean;
    isFormInviteRegistration?: boolean;
}

const SetLanguage: FC<SetLanguagePropsTypes> = ({color, isFormRegistrationPage, isFormInviteRegistration}) => {
    const dispatch = useDispatch();
    const {form} = useParams();
    const {hash} = useLocation();

    const {showAlert} = useContext(AlertContext);
    const {language, setLanguage, setIsInitialLanguageUpdated} = useContext(MediaContext);

    const {
        admin: {languageList},
        auth,
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getLanguageList = useCallback(() => dispatch({type: LANGUAGE_LIST.REQUEST}), [dispatch]);

    const getOrganizationInfoByLanguage = useCallback(
        data => dispatch({type: ORGANIZATION_BASE_INFORMATION_LANGUAGE.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormBaseInformation = useCallback(
        data => dispatch({type: FORM_BASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getInviteFormBaseInformation = useCallback(
        data => dispatch({type: FORM_BASE_INVITE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const setUserLanguage = useCallback(data => dispatch({type: SET_LANGUAGE.REQUEST, payload: data}), [dispatch]);

    const onSelectLanguageChange = (event: SelectChangeEvent) => {
        const value = Number(event.target.value);
        if (isFormInviteRegistration) {
            getInviteFormBaseInformation({
                invite: String(hash).replace('#', ''),
                lang: value,
                showAlert,
            });
        } else if (isFormRegistrationPage) {
            getFormBaseInformation({
                url: form,
                lang: value,
            });
        }

        const selectedLanguage = getLanguageById(languageList, value);
        if (selectedLanguage) {
            getOrganizationInfoByLanguage({
                language: selectedLanguage.name,
                showAlert,
                callback: () => {
                    i18n.changeLanguage(selectedLanguage.name);
                    localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE, selectedLanguage.name);
                    if (profile) {
                        setUserLanguage({language: value});
                    }
                    setLanguage(value);
                    setIsInitialLanguageUpdated(true);
                },
            });
        }
    };

    const getCurrentLanguage = (): number => {
        return profile ? profile.languageId : language;
    };

    useEffect(() => {
        const selectedLanguage = getLanguageById(languageList, getCurrentLanguage());
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage.name);
            localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE, selectedLanguage.name);
        }
    }, []);

    useEffect(() => {
        getLanguageList();
    }, []);

    const enabledLanguages = isFormRegistrationPage
        ? get(auth, ['form', 'availableLanguagesList'], [])
        : get(auth, ['organization', 'availableLanguagesList'], []);

    return (
        <Formik
            initialValues={{
                language,
            }}
            enableReinitialize
            onSubmit={() => {
                return;
            }}
        >
            {(formik: FormikProps<SetLanguageFormPropsTypes>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <FormControl style={{padding: 0}}>
                            <Select
                                data-e2e-id="languageSwitcher"
                                name="language"
                                id="name"
                                data-id="dropdown#set-language"
                                onChange={onSelectLanguageChange}
                                style={{width: '65px', height: '38px', color: color}}
                                value={String(language)}
                                className="language-select"
                                variant="standard"
                            >
                                {enabledLanguages &&
                                    enabledLanguages.map(({id, name}: LanguageItemTypes) => (
                                        <MenuItem value={id} key={id} data-id={`dropdown#set-language-${name}`}>
                                            {name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SetLanguage;
